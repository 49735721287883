<template>
  <div class="new-dataset">
    <Stepper
      :current-step="step"
      :steps="steps"
      class="dataset-stepper"
      @step-clicked="goToStep($event)"
    />
    <div class="new-dataset-content">
      <!-- step 1: dataset informations -->
      <div v-if="step === 0" class="dataset-informations">
        <h2 class="dataset-informations-title">Define your dataset</h2>
        <ch-field
          :labelWidth="180"
          :validators="[datasetNameValidation]"
          label="Name"
          label-position="left"
        >
          <ch-text-input
            v-model="dataSetName"
            placeholder="Insert the dataset name"
          />
        </ch-field>
        <ch-field
          :labelWidth="180"
          :validators="[datasetPeriodValidation]"
          label="Period"
          label-position="left"
        >
          <ch-text-input
            v-model="dataSetPeriod"
            placeholder="Insert the dataset period"
          />
        </ch-field>
        <ch-field
          :labelWidth="180"
          :validators="[datasetInformationsValidation]"
          label="Store format"
          label-position="left"
        >
          <ch-text-input
            v-model="dataSetStoreFormat"
            placeholder="Insert the dataset store format"
          />
        </ch-field>
        <ch-field
          :labelWidth="180"
          :validators="[datasetInformationsValidation]"
          label="Location"
          label-position="left"
        >
          <ch-text-input
            v-model="dataSetLocation"
            placeholder="Insert the dataset location"
          />
        </ch-field>
        <ch-field
          :labelWidth="180"
          :validators="[isRequired]"
          class="separator-dropdown"
          label="How do you enter digits?"
          label-position="left"
        >
          <ch-select
            v-model="digitsAndDecimalSeparatorValue"
            :items="digitsAndDecimalSeparators"
            placeholder="-"
            searchable
            size="fill"
          />
        </ch-field>
      </div>
      <!-- step 2: Upload data -->
      <div v-else class="upload-data">
        <h2>{{ step === 1 ? 'Upload your data' : 'Map your data' }}</h2>
        <p
          v-if="step === 1 && !previewShouldBeDisplayed"
          class="upload-data-warning-message"
        >
          All the column in you .csv file must have a header with a name
        </p>
        <ch-field
          v-if="step === 1"
          :validators="[fileValidation]"
          label="Select a .csv file"
        >
          <ch-input-file v-model="fileToImport" accept=".csv" />
        </ch-field>
        <div v-else>
          <p>We need to know what are the data you are importing</p>
          <p style="margin: 16px 0">
            Check each column of your CSV file, and mark the data as:
            <br />
            - EAN. Mandatory in your .CSV file.
            <br />
            - LISTED KPI. It’s required to map at least one market data. The
            system will display the value of this column and use it for
            calculations. It must contain digits only.
            <br />
            - ECONOMICS. The system will display the value of this column and
            perform filters on the data. It must contain digits only.
            <br />
            - CUSTOM. To display additional info in text format.
          </p>
        </div>
        <div
          v-if="step === 1 && previewShouldBeDisplayed"
          class="table-preview-error-message"
        >
          <p v-if="containsMissingHeader">
            One or more columns are missing their Headers: please fix them in
            your .csv file and import it again.
          </p>
          <div v-if="!isDataHeaderContentUnique">
            <p v-for="(header, index) in headerReplicas" :key="index">
              You are using the same header {{ header }} for more than one
              column. Fix your file and import it again.
            </p>
          </div>
        </div>
        <div
          v-if="step === 2 && previewShouldBeDisplayed"
          class="table-preview-error-message"
        >
          <p v-if="eanErrorMessage">
            {{ eanErrorMessage }}
          </p>
          <p v-if="oneValidAnalyticsError">
            {{ oneValidAnalyticsError }}
          </p>
          <div v-if="analyticsColumnsErrors.length">
            <p v-for="(error, index) in analyticsColumnsErrors" :key="index">
              {{ error.headerValue }} is not formatted correctly. You should
              enter digits using the same model you’ve specified in step 1 of
              the data importing process
            </p>
          </div>
          <div v-if="customColumnsErrors.length">
            <p v-for="(error, index) in customColumnsErrors" :key="index">
              {{ error.headerValue }} contains invalid input. Allowed
              characters: letters, numbers, ( ) + - _ space
            </p>
          </div>
        </div>
        <div
          v-if="previewShouldBeDisplayed && isDataHeaderContentUnique"
          :class="tableContainerClasses"
          class="table-container"
        >
          <h3 v-if="step === 1">Preview from your .csv</h3>
          <table class="table-preview">
            <thead v-if="step === 2">
              <tr>
                <th
                  v-for="(headerCell, hcIndex) in dataSetHeader"
                  :key="`${headerCell}${hcIndex}`"
                  :style="`width: ${cellsWidth[hcIndex]}px; text-align: left;`"
                  class="table-preview-select"
                >
                  <ch-select
                    v-model="headerSelectsValue[hcIndex]"
                    :items="availableItems"
                    searchable
                    size="narrow large"
                  />
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th
                  v-for="(headerCell, hcIndex) in dataSetHeader"
                  :key="`${headerCell}${hcIndex}`"
                  :style="`width: ${cellsWidth[hcIndex]}px; ${
                    tablePreviewHeaderErrors[hcIndex] && 'color: #FF4F4B;'
                  }`"
                  class="table-preview-header"
                >
                  {{ headerCell }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in dataSetBodyPreview" :key="rowIndex">
                <td
                  v-for="(cell, index) in row"
                  :key="`${cell}-${index}`"
                  class="table-preview-data-cell"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="new-dataset-actions">
      <ch-button
        size="small"
        type="secondary"
        @click="$emit('cancel-new-dataset')"
      >
        Cancel
      </ch-button>
      <ch-button
        v-if="step"
        size="small"
        type="secondary"
        @click="goToStep(step - 1)"
      >
        Previous
      </ch-button>
      <ch-button
        :disabled="isNextStepDisabled"
        size="small"
        type="primary"
        @click="goToStep(step + 1)"
      >
        Next
      </ch-button>
    </div>
    <ch-loader v-if="loading" fullscreen />
    <ch-modal ref="datasetmodal" />
  </div>
</template>

<script>
import Papa from 'papaparse';
import nanoid from 'nanoid';

import Stepper from '../Stepper.vue';

import {
  kpiImportOptions,
  optionsKeys,
} from '../../../utils/KPI/kpiImportOptions';
import PhysicalPlanogram from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/PhysicalPlanogram';
import { replaceSymbols } from '../../../utils/String';

export default {
  name: 'NewDataSet',
  components: {
    Stepper,
  },
  props: {
    /*
     * Sets the physicalPlanogram
     */
    physicalPlanogram: {
      type: PhysicalPlanogram,
    },
  },
  data() {
    return {
      step: 0,
      steps: ['Define the dataset', 'Upload data', 'Map your data'],
      loading: false,
      // step 1
      dataSetName: '',
      dataSetPeriod: '',
      dataSetStoreFormat: '',
      dataSetLocation: '',
      digitsAndDecimalSeparatorValue: null,
      digitsAndDecimalSeparators: [
        // this regex match pattern like 10 or -20 or 354354645,354 or -1036584000,0 or +47586000,0 etc
        {
          label: 'Without separator, comma for decimals E.g. 10000,00',
          key: 'noSeparatorAndCommaDecimals',
          test: /^[-+]?([0-9]*)(\,[0-9]+){0,1}$/,
          format: value => value.replaceAll(' ', '').replaceAll(',', '.'),
        },
        // this regex match pattern like 10 or -20 or 354354645,354 or -1036584000.0 or +47586000.0 etc
        {
          label: 'Without separator, period for decimals E.g. 10000.00',
          key: 'noSeparatorAndPeriodDecimals',
          test: /^[-+]?([0-9]*)(\.[0-9]+){0,1}$/,
          format: value => value.replaceAll(' ', ''),
        },
        // tested for -1.000.000.000,2345 => italian pattern
        {
          label: 'Period for grouping, comma for decimals E.g. 10.000,00',
          key: 'periodGroupingCommaDecimals',
          test: /^[-+]?(0|[1-9][0-9]{0,2}(?:(\.[0-9]{3})*|[0-9]*))(\,[0-9]+){0,1}$/,
          format: value =>
            value.replaceAll(' ', '').split('.').join('').replaceAll(',', '.'),
        },
        // tested for -1,000,000,000.2345 => american pattern
        {
          label: 'Comma for grouping, period for decimals E.g. 10,000.00',
          key: 'commaGroupingPeriodDecimals',
          test: /^[-+]?(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(\.[0-9]+){0,1}$/,
          format: value => value.replaceAll(' ', '').replaceAll(',', ''),
        },
      ],
      // step 2
      fileToImport: null,
      headerSelectsValue: [],
      dataSetHeader: [],
      dataSetBody: [],
      availableItemsOptions: [...kpiImportOptions],
      availableOptionsKeys: [...optionsKeys],
    };
  },
  methods: {
    normalizeEan(ean) {
      return ean.padStart(13, '0');
    },
    getValueFromAnalytics(numberAsString, key) {
      const targetSeparator =
        this.digitsAndDecimalSeparatorValue &&
        this.digitsAndDecimalSeparators.find(
          separator => separator.key === this.digitsAndDecimalSeparatorValue
        );

      const number =
        this.digitsAndDecimalSeparatorValue &&
        targetSeparator &&
        targetSeparator.format(numberAsString);

      const parsedNumber = number ? parseFloat(number) : 0;

      // after parsing, parsed could be NaN ( falsy value )
      const value = parsedNumber || (parsedNumber === 0 ? 0 : null);

      // This assign is done after the validation, so we can assume every analytics value is already validated.
      // Accetped values are number or null (in case the user don't provide any data)
      return {
        [key]: value,
      };
    },
    goToStep(nextStep) {
      /* Previous step can't be < 0 */
      if (nextStep === -1) return;

      /* Second step */
      if (nextStep === 1 && !this.isFirstStepValid) return;

      /* Third step */
      if (nextStep === 2 && !this.isSecondStepValid) return;

      /* Last step */
      if (nextStep === 3) {
        if (!this.isThirdStepValid) return;

        return this.submitMarketDataPool();
      }

      this.step = nextStep;
    },
    datasetNameValidation(value) {
      if (!value) return 'The value could not be empty';

      if (value.length > 46) return 'Maximum 46 characters';

      if (/[^A-Za-z0-9 \-\_\+\(\)]/.test(value)) {
        return 'Invalid input. Allowed characters: letters, numbers, ( ) + - _ space';
      }

      return null;
    },
    datasetPeriodValidation(value) {
      if (!value) return 'The value could not be empty';

      if (value.length > 46) return 'Maximum 46 characters';

      if (/[^A-Za-z0-9 \-\(\)]/.test(value)) {
        return 'Invalid input. Allowed characters: letters, numbers, ( )  -  space';
      }

      return null;
    },
    datasetInformationsValidation(value) {
      if (!value) return 'The value could not be empty';

      if (value.length > 46) return 'Maximum 46 characters';

      if (/[^A-Za-z0-9 \-\(\)]/.test(value)) {
        return 'Invalid input. Allowed characters: letters, numbers, ( )  -  space';
      }

      return null;
    },
    fileValidation(value) {
      return !value ? "It's necessary to select a file" : null;
    },
    isRequired(value) {
      return !value ? 'This field is required' : null;
    },
    openModal(...args) {
      return this.$refs.datasetmodal.open(...args);
    },
    getHeaderForSubmission() {
      return this.mappedHeader.map(header => {
        const { headerValue, headerAsKey, ...rest } = header;

        return {
          ...rest,
        };
      });
    },
    getValidBody() {
      /*
                            If the imported cell value is needed as a string, falsy values are parsed as null
                            If the imported cell value is needed as a number, falsy values are parsed as 0
                        */
      return this.dataSetBody.reduce((accumulator, currentValue) => {
        const validRow = this.mappedHeader
          .map(header => {
            const isAnalytics = this.isAnalytics(header.key);

            // analytics should be parsed as floats
            if (isAnalytics) {
              return this.getValueFromAnalytics(
                currentValue[header.headerAsKey],
                header.key
              );
            }

            // non analytics values ( records ) should be parsed as strings
            // This assign is done after the validations, so we can assume ean is always parsed as string (it's never a falsy value)
            return {
              [header.key]: currentValue[header.headerAsKey]
                ? currentValue[header.headerAsKey]
                : null,
            };
          })
          .reduce((object, validElement) => {
            const normalizedElement = validElement.ean
              ? { ean: this.normalizeEan(validElement.ean) }
              : validElement;

            return {
              ...object,
              ...normalizedElement,
            };
          }, {});

        accumulator.push(validRow);

        return accumulator;
      }, []);
    },
    isAnalytics(key) {
      return this.mappedHeader
        .filter(header => header.type === 'analytics')
        .map(header => header.key)
        .includes(key);
    },
    isAnalyticsValid(analyticsName) {
      const isValid = analyticsValue =>
        analyticsValue === null ||
        analyticsValue === '' ||
        this.separatorTestPattern.test(analyticsValue);

      return (
        analyticsName &&
        !this.dataSetBody.some(row => !isValid(row[analyticsName]))
      );
    },
    isCustomValid(customName) {
      const isValid = customValue =>
        customValue === null ||
        customValue === '' ||
        !/[^A-Za-z0-9 \-\_\+\(\)]/.test(customValue);

      return (
        customName && !this.dataSetBody.some(row => !isValid(row[customName]))
      );
    },
    isEanValid(ean) {
      return /^\d{3,13}$/.test(ean);
    },
    async submitMarketDataPool() {
      this.loading = true;

      try {
        const info = {
          name: this.dataSetName,
          period: this.dataSetPeriod,
          storeFormat: this.dataSetStoreFormat,
          location: this.dataSetLocation,
        };

        const importedData = {
          header: this.getHeaderForSubmission(),
          body: this.getValidBody(),
        };

        const workGroupId = this.$storage.workGroupId;

        const response = await this.$studio.saveMarketDataPool(
          this.physicalPlanogram,
          info,
          importedData,
          workGroupId
        );

        this.loading = false;

        await this.openModal(
          'Import market data',
          'Market data set has been correctly imported',
          'primary',
          'Ok',
          '',
          'medium',
          false,
          false
        );

        this.$emit('new-market-data-pool', response.marketDataPool);
      } catch (error) {
        this.loading = false;

        this.openModal(
          'Import failed',
          error.response &&
            error.response.status &&
            error.response.status === 503
            ? 'The file you are trying to import exceeds the maximum size (50MB). Please import a smaller file.'
            : 'Something went wrong on our side: it should be a temporary glitch. Please try again later',
          'error',
          'Ok',
          '',
          'medium',
          false,
          false
        );

        console.error({
          error,
          status: error.reponse && error.response.status,
        });
      }
    },
  },
  computed: {
    tablePreviewHeaderErrors() {
      return this.dataSetHeader.map(
        headerCell =>
          (headerCell === this.userColumnEanValue && !this.areEanValid) ||
          this.analyticsColumnsErrors
            .map(el => el.headerValue)
            .includes(headerCell) ||
          headerCell.includes('Missing Header')
      );
    },
    isNameValid() {
      return (
        this.dataSetName &&
        !(this.dataSetName.length > 46) &&
        !/[^A-Za-z0-9 \-\_\+\(\)]/.test(this.dataSetName)
      );
    },
    areInformationsValid() {
      return (
        this.datasetPeriodValidation(this.dataSetPeriod) === null &&
        this.datasetInformationsValidation(this.dataSetStoreFormat) === null &&
        this.datasetInformationsValidation(this.dataSetLocation) === null
      );
    },
    isFirstStepValid() {
      return (
        this.isNameValid &&
        this.areInformationsValid &&
        this.digitsAndDecimalSeparatorValue
      );
    },
    isSecondStepValid() {
      return (
        this.isFirstStepValid &&
        this.dataSetHeader.length &&
        !this.containsMissingHeader &&
        this.isDataHeaderContentUnique
      );
    },
    isThirdStepValid() {
      return (
        this.isSecondStepValid &&
        this.isHeaderMappingValid &&
        this.containsImportedData &&
        this.areAnalyticsValid &&
        this.areCustomValid &&
        this.areEanValid
      );
    },
    containsMissingHeader() {
      return this.dataSetHeader.some(header =>
        header.includes('Missing Header')
      );
    },
    dataSetBodyPreview() {
      if (!this.dataSetBody.length) return [];

      if (this.dataSetBody.length > 5) {
        const [a, b, c, d, e] = this.dataSetBody;

        return [a, b, c, d, e];
      } else {
        return this.dataSetBody;
      }
    },
    isNextStepDisabled() {
      if (!this.step) return !this.isFirstStepValid;

      if (this.step === 1) return !this.isSecondStepValid;

      if (this.step === 2) return !this.isThirdStepValid;
    },
    tableContainerClasses() {
      return {
        isSecondStep: this.step === 1,
        isThirdStep: this.step === 2,
      };
    },
    previewShouldBeDisplayed() {
      return (
        this.dataSetHeader.length &&
        this.dataSetBodyPreview.length &&
        this.cellsWidth.length
      );
    },
    availableItems() {
      const kpiImportOptions = this.availableItemsOptions.map(item => {
        return {
          ...item,
          disabled: this.headerSelectsValue.includes(item.key),
        };
      });

      kpiImportOptions.push(
        {
          label: 'ECONOMICS',
          key: 'economics',
          taxonomy: 'economics',
          type: 'analytics',
        },
        {
          label: 'CUSTOM',
          key: 'custom',
          taxonomy: 'custom',
          type: 'record',
        },
        {
          label: 'NOT TO IMPORT',
          key: 'notToImport',
          taxonomy: 'notToImport',
          type: 'notToImport',
        }
      );

      return kpiImportOptions;
    },
    cellsWidth() {
      if (!this.dataSetBodyPreview.length || !this.dataSetHeader.length) {
        return [];
      }

      return this.dataSetBodyPreview
        .reduce((accumulator, currentValue, index) => {
          if (!index) return Object.values(currentValue);

          return Object.values(currentValue).map((value, i) =>
            value.length > accumulator[i].length ? value : accumulator[i]
          );
        }, [])
        .map((cell, j) => {
          const bigger =
            this.dataSetHeader[j] && this.dataSetHeader[j].length > cell.length
              ? this.dataSetHeader[j]
              : cell;

          return j === this.dataSetHeader.length - 1 &&
            Math.log(bigger.length) * 80 < 260
            ? 260
            : Math.log(bigger.length) * 80;
        });
    },
    userColumnEanKey() {
      const header = this.mappedHeader.find(
        header => header.taxonomy === 'ean'
      );

      return header ? header.headerAsKey : '';
    },
    userColumnEanValue() {
      const assignedEanHeader = this.mappedHeader.find(
        header => header.taxonomy === 'ean'
      );

      return assignedEanHeader ? assignedEanHeader.headerValue : '';
    },
    separatorTestPattern() {
      const chosenSeparator =
        this.digitsAndDecimalSeparatorValue &&
        this.digitsAndDecimalSeparators.find(
          separator => separator.key === this.digitsAndDecimalSeparatorValue
        );

      return chosenSeparator && chosenSeparator.test;
    },
    headerReplicas() {
      return new Set(
        this.dataSetHeader.filter((header, index, array) => {
          if (header !== 'Missing Header') {
            const hasReplica =
              array.filter(element => element === header).length > 1;

            if (hasReplica) return header;
          }
        })
      );
    },
    // VALIDATIONS
    isDataHeaderContentUnique() {
      const validHeaders = this.dataSetHeader.filter(
        header => header !== 'Missing Header'
      );

      return new Set(validHeaders).size === validHeaders.length;
    },
    isHeaderMappingValid() {
      return this.headerSelectsValue.every(header => header);
    },
    containsImportedData() {
      return this.mappedHeader.some(
        header =>
          header.key !== 'ean' && this.availableOptionsKeys.includes(header.key)
      );
    },
    areEansUnique() {
      const eans =
        this.userColumnEanKey &&
        this.dataSetBody.map(row => row[this.userColumnEanKey]);

      return new Set(eans).size === eans.length;
    },
    areEanValid() {
      return (
        this.areEansUnique &&
        this.headerSelectsValue.length &&
        this.userColumnEanKey &&
        !this.dataSetBody.some(
          row => !this.isEanValid(row[this.userColumnEanKey])
        )
      );
    },
    eanErrorMessage() {
      const headerIsAssigned = this.mappedHeader.find(
        header => header.taxonomy === 'ean'
      );

      if (headerIsAssigned && !this.areEansUnique) {
        return 'Imported data contains duplicated EANs';
      }

      if (headerIsAssigned && !this.areEanValid) {
        return 'EAN is not formatted correctly';
      }

      if (
        this.headerSelectsValue.length ===
          this.headerSelectsValue.filter(header => header).length &&
        !headerIsAssigned
      ) {
        return 'EAN is mandatory. One of your file columns should be identified as EAN';
      }

      return '';
    },
    areAnalyticsValid() {
      return !this.analyticsColumns.some(header => header.isValid === false);
    },
    areCustomValid() {
      return !this.customColumns.some(header => header.isValid === false);
    },
    oneValidAnalyticsError() {
      const headerIsAssigned = this.mappedHeader.find(
        header => header.type === 'analytics'
      );

      if (
        this.headerSelectsValue.length ===
          this.headerSelectsValue.filter(header => header).length &&
        !headerIsAssigned
      ) {
        return `It's required to map at least one market data.`;
      }

      return '';
    },
    analyticsColumns() {
      if (!this.mappedHeader.length) return [];

      return this.mappedHeader
        .filter(header => header.type === 'analytics')
        .map(header => {
          return {
            headerValue: header.headerValue,
            headerAsKey: header.headerAsKey,
            isValid: this.isAnalyticsValid(header.headerAsKey),
          };
        });
    },
    customColumns() {
      if (!this.mappedHeader.length) return [];

      return this.mappedHeader
        .filter(header => header.type === 'record')
        .map(header => {
          return {
            headerValue: header.headerValue,
            headerAsKey: header.headerAsKey,
            isValid: this.isCustomValid(header.headerAsKey),
          };
        });
    },
    analyticsColumnsErrors() {
      return this.analyticsColumns.filter(element => element.isValid === false);
    },
    customColumnsErrors() {
      return this.customColumns.filter(element => element.isValid === false);
    },
    mappedHeader() {
      return this.headerSelectsValue.reduce(
        (accumulator, availableOptionKey, index) => {
          if (availableOptionKey && !(availableOptionKey === 'notToImport')) {
            const headerValue = this.dataSetHeader[index];

            const headerAsKey = _.camelCase(replaceSymbols(headerValue));

            const targetOption = this.availableItems.find(
              option => option.key === availableOptionKey
            );

            const economicsAndCustomlabelAndKeyCheck = [
              'CUSTOM',
              'ECONOMICS',
              'custom',
              'economics',
            ];

            accumulator.push({
              headerValue,
              headerAsKey,
              label:
                (targetOption &&
                  !economicsAndCustomlabelAndKeyCheck.includes(
                    targetOption.label
                  ) &&
                  targetOption.label) ||
                headerValue,
              key:
                (targetOption &&
                  !economicsAndCustomlabelAndKeyCheck.includes(
                    targetOption.key
                  ) &&
                  targetOption.key) ||
                headerAsKey,
              taxonomy: (targetOption && targetOption.taxonomy) || 'economics',
              type: (targetOption && targetOption.type) || 'record',
              id: `col_${nanoid(15)}`,
            });
          }

          return accumulator;
        },
        []
      );
    },
  },
  watch: {
    fileToImport(newVal, oldVal) {
      if (newVal !== oldVal) {
        Papa.parse(this.fileToImport, {
          header: false,
          preview: 1,
          complete: results => {
            this.dataSetHeader = results.data[0].map(cell =>
              !cell ? `Missing Header` : cell
            );
            this.headerSelectsValue = this.dataSetHeader.map(el => '');
          },
        });

        Papa.parse(this.fileToImport, {
          header: true,
          complete: results => {
            const strippedEmptyRowsData = results.data.filter(row => {
              const isRowNotEmpty = !!Object.values(row).filter(cell => cell)
                .length;
              return isRowNotEmpty && row;
            });

            const lastRowUndetectableDelimiter =
              results.errors &&
              results.errors.some(
                error => error.code === 'UndetectableDelimiter'
              );

            this.dataSetBody = lastRowUndetectableDelimiter
              ? results.data.slice(0, results.data.length - 1)
              : strippedEmptyRowsData;
          },
          transformHeader: (str, index) =>
            !str ? `missingHeader${index}` : _.camelCase(replaceSymbols(str)),
        });
      }
    },
  },
};
</script>

<style scoped>
.new-dataset {
  height: 100%;
  width: 100%;
}

.dataset-stepper {
  margin: 34px 0 34px -8px;
}

.new-dataset-content {
  height: calc(100% - 240px);
}

.dataset-informations {
  width: 524px;
}

.dataset-informations * {
  margin: 4px 0;
  color: var(--elevation-05);
}

.dataset-informations-title {
  font-weight: 400;
  padding-bottom: 16px;
  color: #ffffff;
}

.new-dataset-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.new-dataset-actions > button:nth-child(2) {
  margin-left: 32px;
}

.new-dataset-actions > button:nth-child(3) {
  margin-left: 16px;
}

.upload-data {
  color: var(--elevation-06);
}

.upload-data > h2 {
  color: #ffffff;
}

.upload-data-warning-message {
  padding: 16px 0;
  color: var(--alert);
}

.table-container {
  width: 100%;
  margin-top: 20px;
  overflow: auto;
}

.isSecondStep {
  max-height: calc(100vh - 480px);
}

.isThirdStep {
  max-height: calc(100vh - 620px);
}

h3 {
  margin-bottom: 8px;
}

table {
  border-collapse: collapse;
}

.table-preview-error-message {
  padding-top: 16px;
  color: #ff4f4b;
}

.table-preview {
  width: 150%;
  height: 150%;
  table-layout: fixed;
}

.table-preview-header {
  text-align: justify;
  padding: 8px;
  border-top: 1px solid var(--elevation-04);
  border-bottom: 2px solid var(--elevation-04);
}

.table-preview-select {
  padding: 8px;
}

.table-preview-data-cell {
  padding: 8px;
}

::v-deep .select.default > .dropdown {
  width: 252px !important;
}

::v-deep .separator-dropdown > .fieldInput > .select.default > .dropdown {
  width: 360px !important;
}
</style>
