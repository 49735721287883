<template>
  <div class="planogram-editor-wrapper">
    <ch-application-layout v-if="repository" v-show="!enableMarketData" editor>
      <left-side-bar
        ref="leftSideBar"
        slot="navigation"
        :repository="repository"
        :selectionManager="selectionManager"
        @dragover="onDragOverOutside"
        @market-data-clicked="toggleMarketData()"
      />
      <div slot="body" fill>
        <action-bar
          :repository="repository"
          :saveEnabled="saveEnabled"
          @save="saveEnabled = false"
          @change-dataset="handleDataSetAction()"
        />
        <div
          ref="planogramEditorFrame"
          class="editor"
          @dragover="onDragOver"
          @drop="onDrop"
          fill
        ></div>
        <ch-modal ref="modal" />
        <login-dialog ref="loginDialog" />
        <create-product-group-dialog
          ref="createGroup"
          type="primary"
          size="fullscreen"
          :repository="repository"
        />
        <ch-loader v-if="loading" fullscreen />
      </div>
      <right-side-bar
        ref="rightSideBar"
        slot="sidebar"
        :repository="repository"
        :selectionManager="selectionManager"
        @deleteGroup="deleteGroup"
        @editGroup="editGroup"
        @change-dataset="handleDataSetAction()"
      />
    </ch-application-layout>
    <market-data-layout
      v-if="repository"
      v-show="enableMarketData"
      :repository="repository"
      @go-back="toggleMarketData()"
      @market-data-pool-added="addMarketDataPool($event)"
    />
    <ch-content-dialog
      ref="marketDataScoreCardTable"
      type="primary"
      size="fullscreen"
    >
      <template #title>
        <div class="marketDataScoreCardTableLabel">
          <label-tag label="BETA" size="small" />
          <h2>Setup market data</h2>
        </div>
      </template>
      <market-data-pool-list-table
        :currentSelectMarketDataPoolId="currentSelectMarketDataPoolId"
        :dataSets="dataSets"
        @handleDataSetSelection="handleDataSetSelection($event)"
      />
      <div class="data-sets-info-footer">
        <ch-button type="secondary" size="small" @click="closeModal">
          Cancel
        </ch-button>
        <ch-button type="primary" size="fit" @click="changeDataSet">
          Use dataset
        </ch-button>
      </div>
    </ch-content-dialog>
  </div>
</template>

<script>
/* Imports */
import Vue from 'vue';
import LeftSideBar from './leftsidebar/LeftSideBar';
import RightSideBar from './rightsidebar/RightSideBar';
import ActionBar from './ActionBar';
import SelectionManager from '../managers/SelectionManager';
import { PlanogramService } from '../services/PlanogramService';
import { ColorService } from '../../layouteditor/services/ColorService';
import HexColor from '../../layouteditor/model/common/color/HexColor';
import Repository from '../services/Repository';
import LoginDialog from '@/login/LoginDialog';
import CreateProductGroupDialog from './trade/createproductgroup/CreateProductGroupDialog';
import {
  categorizeProducts,
  DEFAULT_PRODUCT_DATA_FILTERS,
  updateFilteredGroupsInPlanogram,
} from './trade/createproductgroup/filters/GroupProductFilters';
import { toOption } from '../../utils/Option';
import LabelTag from './LabelTag.vue';
import MarketDataPoolListTable from './MarketDataPoolListTable.vue';
import MarketDataLayout from './marketdata/MarketDataLayout.vue';
import MarketDataPoolSummary from '@/plugins/studioapi/api/trade/model/MarketDataPoolSummary';
import {
  retrieveSelectedMarketDataPool,
  storeSelectedMarketDataPool,
} from '@/spaceallocation/planogrameditor/services/MarketDataPoolStorageService';
import { mapGetters } from 'vuex';

/**/

export default {
  name: 'PlanogramEditor',
  components: {
    LoginDialog,
    ActionBar,
    LeftSideBar,
    RightSideBar,
    CreateProductGroupDialog,
    MarketDataLayout,
    LabelTag,
    MarketDataPoolListTable,
  },
  props: {
    planogramId: String,
  },
  data() {
    return {
      repository: null,
      saveEnabled: false,
      fixtureView: 'Front',
      loading: false,
      selectionManager: new SelectionManager(this.$unityManager),
      enableMarketData: false,
      currentSelectMarketDataPoolId: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      Vue.$studio.findMostRecentOwnCategorization(),
      Vue.$planogram.findPlanogram(to.params.planogramId),
    ])
      .then(([categorizations, findPlanogramResponse]) => {
        const productIds = findPlanogramResponse.planogram.productSet.map(
          p => p.id
        );
        const marketDataPoolsId =
          findPlanogramResponse.planogram.marketDataPools.map(
            p => p.marketDataPoolId
          );
        const defaultMarketDataPoolId = retrieveSelectedMarketDataPool(
          findPlanogramResponse.planogramId,
          marketDataPoolsId[0]
        );
        Promise.all([
          Vue.$trade.listMarketDataPoolsSummary(marketDataPoolsId),
          Vue.$productBank.findProductsById(productIds, false),
          Vue.$trade.findMarketDataPoolById(defaultMarketDataPoolId),
        ]).then(
          ([
            listMarketDataPoolsSummary,
            foundProductsResponse,
            findMarketDataPoolByIdResponse,
          ]) => {
            next(vm => {
              vm.repository = new Repository(
                categorizations.foundCategorization,
                findPlanogramResponse.planogram,
                foundProductsResponse.foundProducts,
                listMarketDataPoolsSummary.marketDataPoolsSummaries,
                toOption(findMarketDataPoolByIdResponse.marketDataPool),
                toOption({
                  daily: toOption(0),
                  weekly: toOption(0),
                  monthly: toOption(0),
                })
              );
              vm.loadPlanogram();
            });
          }
        );
      })
      .catch(err => {
        next(err);
      });
  },
  beforeRouteLeave(to, from, next) {
    const proceed = () => this.$unityManager.UnloadScene().then(() => next());
    if (this.saveEnabled)
      if (to.name === 'login') this.$refs.loginDialog.open().catch(proceed);
      else
        this.$refs.modal
          .open(
            'Unsaved changes',
            'Do you really want to leave? You have unsaved changes!',
            'primary'
          )
          .then(proceed, () => next(false));
    else proceed();
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    document.addEventListener('dragend', this.onDragEnd);
    this.$unityManager.Subscribe('PlanogramChanged', this.planogramChanged);
    this.$unityManager.Subscribe('PlanogramLoaded', this.planogramLoaded);
  },
  beforeDestroy() {
    this.isData && this.$store.dispatch('planogramPerformance/resetState');

    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('dragend', this.onDragEnd);
    this.$unityManager.Unsubscribe('PlanogramChanged', this.planogramChanged);
    this.$unityManager.Unsubscribe('PlanogramLoaded', this.planogramLoaded);
    window.onbeforeunload = null;
  },
  methods: {
    handlePlanogramPerformanceState() {
      this.$store.dispatch('planogramPerformance/resetState', 'data');
      this.$store.dispatch('planogramPerformance/resetState', 'metaData');
      this.$unityManager.Dispatch('HighlightMarketDataBucket', {
        highlightMarketDataBuckets: [],
      });
    },
    addMarketDataPool(marketDataPool) {
      this.repository.addMarketDataPool(
        new MarketDataPoolSummary(marketDataPool.id, marketDataPool.info)
      );
      this.repository.setCurrentMarketDataPool(marketDataPool);
      storeSelectedMarketDataPool(
        this.repository.planogram.id,
        marketDataPool.id
      );
      this.toggleMarketData();
    },
    toggleMarketData() {
      this.enableMarketData = !this.enableMarketData;
    },
    loadPlanogram() {
      this.loading = true;
      window.onbeforeunload = null;
      this.$nextTick(() => {
        this.$unityManager
          .LoadScene(this.$refs.planogramEditorFrame, 'PlanogramEditor')
          .then(() => {
            this.$unityManager.canvas.tabIndex = 1;
            window.onbeforeunload = () =>
              this.saveEnabled ? 'Are you sure?' : null;
            this.$unityManager.Dispatch(
              'LoadPlanogram',
              this.repository.planogram
            );
          });
      });
    },
    planogramLoaded() {
      this.loading = false;
    },
    /**
     * @param {{planogram: Planogram}} event
     */
    planogramChanged(event) {
      event.planogram.productSet.forEach(
        product =>
          (product.type = product.id.startsWith('PROT')
            ? 'Prototype'
            : 'Product')
      );
      this.repository.planogram.productSet = event.planogram.productSet;
      this.repository.planogram.fixtures = event.planogram.fixtures;
      this.repository.planogram.baseHeight = event.planogram.baseHeight;

      this.repository.updateProductsAndBuckets(this.repository.planogram);

      this.handlePlanogramPerformanceState();

      if (!this.loading) {
        this.saveEnabled = true;
      }
    },
    onResize() {
      document.getElementById('#canvas').style.width =
        this.$refs.planogramEditorFrame.offsetWidth + 'px';
      document.getElementById('#canvas').style.height =
        this.$refs.planogramEditorFrame.offsetHeight + 'px';
    },
    /* Drag and drop methods */
    /**
     * @param {DragEvent} event
     */
    onDragOverOutside(event) {
      this.$unityManager.Dispatch('UpdateDrag', { x: -100, y: -100 });
    },
    /**
     * @param {DragEvent} event
     */
    onDragOver(event) {
      event.preventDefault();

      const canvas = this.$unityManager.canvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = rect.bottom - event.clientY;
      const wRatio = canvas.width / rect.width;
      const hRatio = canvas.height / rect.height;

      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      this.$unityManager.Dispatch('UpdateDrag', {
        x: x * wRatio,
        y: y * hRatio,
      });
    },
    /**
     * @param {DragEvent} event
     */
    onDrop(event) {
      event.preventDefault();
      const data = event.dataTransfer.getData('text');
      event.dataTransfer.clearData();
    },
    /**
     * @param {DragEvent} event
     */
    onDragEnd(event) {
      this.$unityManager.Dispatch('EndDrag', {
        x: event.offsetX,
        y: event.target.height - event.offsetY,
      });
    },
    /**
     * @param {string} groupId
     */
    deleteGroup(groupId) {
      PlanogramService.findGroupWithId(
        this.repository.planogram,
        groupId
      ).forEach(group =>
        this.$refs.modal
          .open(
            'Delete group',
            `Are you sure to delete ${group.name}?`,
            'error',
            'Delete',
            'Cancel'
          )
          .then(() => {
            this.$trade
              .deleteGroup(this.repository.planogram.id, groupId)
              .then(() => {
                this.$unityManager.Dispatch('DeleteFilter', { id: groupId });
                this.repository.planogram.productsGroups =
                  this.repository.planogram.productsGroups.filter(
                    group => group.id !== groupId
                  );
                this.selectionManager.currentGroupId = null;
              })
              .catch(code =>
                this.$snotify.error(
                  `Error deleting group ${groupId}. Code: ${code}`,
                  {
                    timeout: 5000,
                    showProgressBar: false,
                  }
                )
              );
          })
          .catch(() => {})
      );
    },
    createGroup() {
      this.$refs.createGroup.open().then(
        group => this.onGroupCreated(group),
        () => console.info('Group creation cancelled')
      );
    },
    editGroup(groupId) {
      PlanogramService.findGroupWithId(
        this.repository.planogram,
        groupId
      ).forEach(group => {
        this.$refs.createGroup.open(group).then(
          g => this.onGroupEdited(g),
          () => console.info('Group editing cancelled')
        );
      });
    },
    onGroupCreated(group) {
      const condition = {
        type: 'IsBucketWithProduct',
        productIds: group.matchedProductsIds,
      };
      const color = ColorService.hexColorToRGBColor(new HexColor(group.color));
      this.$unityManager.Dispatch('CompoundAction', {
        actions: [
          {
            type: 'CreateFilter',
            data: { id: group.id, name: group.name, condition, color },
          },
          { type: 'ActivateFilter', data: { id: group.id } },
        ],
      });
      this.selectionManager.currentGroupId = group.id;
    },
    onGroupEdited(group) {
      this.$unityManager.Dispatch('RenameFilter', {
        id: group.id,
        name: group.name,
      });
      const rgbColor = ColorService.hexColorToRGBColor(
        new HexColor(group.color)
      );
      this.$unityManager.Dispatch('ChangeFilterColor', {
        id: group.id,
        color: rgbColor,
      });
      const condition = {
        type: 'IsBucketWithProduct',
        productIds: group.matchedProductsIds,
      };
      this.$unityManager.Dispatch('ChangeFilterCondition', {
        id: group.id,
        condition,
      });
    },

    onFixturesUpdate(fixtures) {
      const usedProductsIds = (fixtures || []).flatMap(f =>
        f.buckets.map(b => b.productId)
      );
      const usedProducts = this.repository.planogram.productSet.filter(
        ({ id }) => usedProductsIds.includes(id)
      );
      const products = categorizeProducts(
        usedProducts,
        this.repository,
        DEFAULT_PRODUCT_DATA_FILTERS
      );
      updateFilteredGroupsInPlanogram(this.repository.planogram, products);
      this.updateFilters();

      this.repository.updateProductsAndBuckets(this.repository.planogram);
    },
    updateFilters() {
      for (const { id, matchedProductsIds } of this.repository.planogram
        .productsGroups) {
        const condition = {
          type: 'IsBucketWithProduct',
          productIds: matchedProductsIds,
        };
        this.$unityManager.Dispatch('ChangeFilterCondition', { id, condition });
      }
    },
    handleDataSetAction() {
      if (this.currentMarketDataPoolId) this.openModal();
      else this.toggleMarketData();
    },
    openModal() {
      this.currentSelectMarketDataPoolId = this.currentMarketDataPoolId;
      this.$refs.marketDataScoreCardTable.open();
    },
    closeModal() {
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    changeDataSet() {
      this.setCurrentMarketDataPoolId(this.currentSelectMarketDataPoolId);
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    handleDataSetSelection(id) {
      this.currentSelectMarketDataPoolId = id;
    },
    setCurrentMarketDataPoolId(id) {
      this.$loading.await(
        this.$trade.findMarketDataPoolById(id).then(response => {
          this.repository.setCurrentMarketDataPool(response.marketDataPool);
          storeSelectedMarketDataPool(
            this.repository.planogram.id,
            response.marketDataPool.id
          );
        })
      );
    },
  },
  computed: {
    ...mapGetters({
      isData: 'planogramPerformance/isData',
    }),
    currentMarketDataPoolId() {
      if (this.repository && this.repository.currentMarketDataPool.nonEmpty())
        return this.repository.currentMarketDataPool.get().id;
      return '';
    },
    dataSets() {
      return this.repository
        ? this.repository.listMarketDataPoolsSummary.map(summary => {
            return {
              id: summary.id,
              ...summary.info,
            };
          })
        : [];
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.onFixturesUpdate(this.repository.planogram.fixtures);
      }
    },
    'repository.planogram.fixtures': {
      deep: true,
      handler: function (newFixtures) {
        if (newFixtures && !this.loading) {
          this.onFixturesUpdate(newFixtures);
        }
      },
    },
  },
};
</script>

<style scoped>
.planogram-editor-wrapper {
  height: 100%;
}

.editor {
  outline: none;
}

.marketDataScoreCardTableLabel {
  display: flex;
  align-items: center;
}

.marketDataScoreCardTableLabel > h2 {
  margin-left: 12px;
  font-size: 20px;
}

.marketDataPoolList {
  max-height: 732px;
  padding: 32px 32px 64px 32px;
}

.data-sets-info-footer {
  width: calc(100% - 32px);
  height: 40px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 32px;
}

::v-deep .data-sets-info-footer > button {
  margin-left: 24px;
}
</style>
