<template>
    <div class="actions" y-spaced-2>

        <div class="changeView">
            <ch-button @click="changeView" size="narrow" type="primary">Change view</ch-button>
            <img v-if="(viewRotation._1 === 'Front')" class="viewIcon" src="../../../../../assets/FrontFace.png"/>
            <img v-if="(viewRotation._1 === 'Left')" class="viewIcon" src="../../../../../assets/LeftFace.png"/>
            <img v-if="(viewRotation._1 === 'Right')" class="viewIcon" src="../../../../../assets/RightFace.png"/>
            <img v-if="(viewRotation._1 === 'Back')" class="viewIcon" src="../../../../../assets/BackFace.png"/>
            <img v-if="(viewRotation._1 === 'Top')" class="viewIcon" src="../../../../../assets/TopFace.png"/>
            <img v-if="(viewRotation._1 === 'Bottom')" class="viewIcon" src="../../../../../assets/BottomFace.png"/>
            <span>{{viewRotation._1}}</span>
        </div>
        <div class="changeRotation">
            <ch-button @click="rotate" size="narrow" type="primary">Rotate</ch-button>
            <img class="viewIcon" :style="`transform: rotate(${viewRotation._2}deg)`"
                 src="../../../../../assets/RotateFace.png"/>
            <span>{{viewRotation._2}}°</span>
        </div>

      <div class="changePrice">
		  <ch-field :label="`Price ${currentCurrency}`" class="priceField">
			  <ch-editor-input placeholder=" - - " type="number" @input="setPriceToBucket"
							   :value="currentPriceValue"></ch-editor-input>
		  </ch-field>
		  <ch-field label="Price per quantity" class="priceField">
			  <ch-editor-input placeholder=" - - " @input="setPricePerQuantity"
							   :value="currentPricePerQuantityValue"></ch-editor-input>
		  </ch-field>
	  </div>
        <replace-products-button :repository="repository" :productAssetIds="[bucket.productId]"/>
		<remove-elements-button :elementsIds="[bucket.id]"/>
        <ch-modal ref="modal"/>
    </div>
</template>

<script>
    import {BucketService}       from '../../../services/BucketService';

    import Repository            from '../../../services/Repository';
    import SelectionManager      from '../../../managers/SelectionManager';
    import ReplaceProductsDialog      from '../../dialog/ReplaceProductsDialog';
    import {PhysicalPlanogramService} from '../../../services/PhysicalPlanogramService';
    import ReplaceProductsButton      from '../product/ReplaceProductsButton';
	import {View}                from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/View';
	import Bucket                from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Bucket';
	import Price                 from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/product/Price';
	import RemoveElementsButton  from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/RemoveElementsButton';
    import Tuple from "@/spaceallocation/utils/Tuple";

    export default {
        name: 'BucketActions',
        components: {RemoveElementsButton, ReplaceProductsButton, ReplaceProductsDialog},
        props: {
            bucket: Bucket | Object,
            cameraView: String,
            repository: Repository,
            selectionManager: SelectionManager
        },
        /**
         * @return {{availableViews: View[]}}
         */
        data() {
            return {
                availableViews: [View.Front, View.Left, View.Back, View.Right, View.Top, View.Bottom]
            };
        },
        computed: {
        	/**
			 * @return {Option<ProductAsset>}
			 */
        	productAsset() {
        		return PhysicalPlanogramService.findProductAssetById(this.repository.physicalPlanogram, this.bucket.productId)
			},
            /**
             * @return {Tuple<View, number>}
             */
            viewRotation() {
				if (this.cameraView === 'Front') return new Tuple(this.bucket.view, this.bucket.productRotation);
				return BucketService.convertViewToTop(this.bucket.view, this.bucket.productRotation);
			},
			currentPrice() {
				return this.productAsset.map(prd => prd.price).filter(price => price !== null);
			},
            currentCurrency(){
                return this.currentPrice.map(price => {
                  if (price.currency === "USD") return "($)"
                  else if (price.currency === "GBP") return "(£)";
                  else return "(€)"
                }).getOrElse("(€)");
            },
            currentPriceValue(){
                return this.currentPrice.map(price => Math.round(price.value * 100) / 100).get();
            },
            currentPricePerQuantity(){
                return this.productAsset.map(prd => prd.info.pricePerQuantity).filter(pricePerQuantity => pricePerQuantity !== null)
            },
            currentPricePerQuantityValue(){
                return this.currentPricePerQuantity.filter(pricePerQuantity => pricePerQuantity).get();
            }
        },
        methods: {
            /**
             * @param {View} view
             * @return {View}
             */
            nextView(view) {
                return this.availableViews[(this.availableViews.indexOf(view) + 1) % this.availableViews.length];
            },
            changeView() {
                this.applyViewRotationChange(this.nextView, r => r);
            },
            rotate() {
                this.applyViewRotationChange(v => v, r => (r + 90) % 360);
            },
            /**
             * @param {function(view: View): View} viewModifier
             * @param {function(rotation: number): number} rotationModifier
             */
            applyViewRotationChange(viewModifier, rotationModifier) {
                const nextState = this.cameraView === 'Front' ?
                    new Tuple(viewModifier(this.bucket.view), rotationModifier(this.bucket.productRotation)) :
                    (() => {
                        const topView = BucketService.convertViewToTop(this.bucket.view, this.bucket.productRotation);
                        return BucketService.convertViewToFront(viewModifier(topView._1), rotationModifier(topView._2));
                    })();
                this.bucket.view = nextState._1;
                this.bucket.productRotation = nextState._2;
                this.$unityManager.planogramAPI.ReplaceElement(this.bucket);
            },
			/**
			 * @param {number} value
			 */
            setPriceToBucket(value) {
            	const product = this.productAsset.get();
				product.price = new Price(value, 'EUR');
				this.$unityManager.planogramAPI.ReplaceAssets([product]);
			},
			/**
			 * @param {string} value
			 */
            setPricePerQuantity(value){
				const product = this.productAsset.get();
				product.info.pricePerQuantity = value;
				this.$unityManager.planogramAPI.ReplaceAssets([product]);
            }
        }
    };
</script>

<style scoped>

    .actions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        padding: var(--doubleMargin);
		border-top: 1px solid var(--elevation-04);
    }
    .changePrice{
      display: flex;
      justify-content: space-between;
      align-items: normal;
    }
    .priceField{
      width: 130px;
    }
    .changeView, .changeRotation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .changeView > *:last-child, .changeRotation > *:last-child {
        width: 50px;
    }

    .viewIcon {
        width: auto;
        height: 32px;
    }

</style>
