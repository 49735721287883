<template>
    <ch-application-layout>
        <div class="header" slot="header" fill>
            <ch-filters>
                <div x-spaced-1>
                    <label>Items per page</label>
                    <ch-select size="narrow small" :items="[10, 25, 50]" v-model="query.limit"/>
                    <label class="productsCount">{{totalItems}} planograms</label>
                </div>
                <div>
                    <ch-pagination v-model="query.page" :max="totalPages"/>
                </div>
                <div>
                    <ch-radio-group v-model="query.visible" size="narrow small" type="primary">
                        <ch-radio-button :label="true">Visible</ch-radio-button>
                        <ch-radio-button :label="false">Hidden</ch-radio-button>
                    </ch-radio-group>
                </div>
            </ch-filters>
            <ch-filters>
                <div x-spaced-2 v-if="!selectable">
                    <ch-button @click="createPlanogram" type="primary" justified>
                        New planogram
                        <ch-icon icon="add_circle_outline"/>
                    </ch-button>
                    <ch-button v-if="query.type === 'logical'" @click="importPlanogram" type="primary" justified>
                        Import planogram
                        <ch-icon icon="system_update_alt"/>
                    </ch-button>
	                <ch-button @click="copyPlanogramFrom" type="primary" justified>
		                Copy from...
		                <ch-icon icon="far fa-copy"/>
	                </ch-button>
                </div>
                <div></div>
                <div>
                    <div>
                        <ch-search-bar v-model="query.matching" size="narrow"/>
                    </div>
                </div>
            </ch-filters>
        </div>
        <template slot="main">
            <div v-if="loading" fill-center> Loading planograms, please wait...</div>
            <ch-empty-content v-else-if="totalMatches === 0 && query.matching"
                              firstMessage="No matches found"/>
            <ch-empty-content v-else-if="totalMatches === 0 && !query.matching" :firstMessage="'You haven\'t created any planogram yet'"/>
            <ch-table v-else :items="planograms" :selectable="selectable ? p => p.id : null" :selected-rows="selected"
                      class="planogramsTable" @rowClicked="row => row.type === 'Planogram' ? goToPlanogram(row) : goToPhysicalPlanogram(row)" :allow-selection-on-entire-row="selectable"
                      row-height="narrow" :row-disabled="r => disabled.some(p => p.id === r.id)"
                      @rowContextMenu="(e, row) => !selectable ? showContextMenu(e, row) : null">
                <template slot="header">
                    <ch-th name="Planogram"/>
                    <ch-th name="Last update"/>
                    <ch-th name="Number of products"/>
                    <ch-th name="Width (cm)"/>
                    <ch-th></ch-th>
                </template>
                <template slot-scope="{ row }">
                    <ch-td x-spaced-1 class="planogramCellName">
						<span v-if="row.type === 'Planogram'" class="logicalIndicator"></span>
						<span>{{row.name}}</span>
					</ch-td>
                    <ch-td>{{formattedDataFromISO(row.lastUpdateTime)}}</ch-td>
                    <ch-td>{{row.productsCount}}</ch-td>
                    <ch-td>{{(row.type === 'Planogram' ? row.volume.width : row.volume.w) / 10}}</ch-td>
                    <ch-td action x-spaced-1>
                        <ch-icon v-if="!row.visible" icon="visibility_off"/>
                        <ch-context-menu :voices="buildContextMenu(row)" v-if="!selectable">
                            <ch-button type="flat" icon>
                                <ch-icon icon="more_horiz"/>
                            </ch-button>
                        </ch-context-menu>
                    </ch-td>
                </template>
            </ch-table>
            <create-planogram-dialog ref="createPlanogramDialog"/>
            <import-planogram-dialog ref="importPlanogramDialog"/>
            <import-planogram-report-dialog ref="importPlanogramReportDialog"/>
	        <copy-planogram-to-dialog ref="copyPlanogramToDialog"/>
	        <copy-planogram-from-dialog ref="copyPlanogramFromDialog"/>
			<create-physical-planogram-dialog ref="createPhysicalPlanogramDialog"/>
			<copy-physical-planogram-to-dialog ref="copyPhysicalPlanogramToDialog"/>
			<copy-physical-planogram-from-dialog ref="copyPhysicalPlanogramFromDialog"/>
            <ch-context-menu ref="contextMenu"/>
            <ch-input-dialog ref="inputDialog"/>
        </template>
    </ch-application-layout>
</template>

<script>

    import ContentCard                   from '../placeholders/ContentCard';
    import StringUtils                     from '../../utils/StringUtils';
    import ImportPlanogramDialog           from '../../spaceallocation/planogrameditor/ui/dialog/ImportPlanogramDialog';
    import CreatePlanogramDialog           from '../../spaceallocation/planogrameditor/ui/dialog/CreatePlanogramDialog';
    import ImportPlanogramReportDialog
										   from '../../spaceallocation/planogrameditor/ui/dialog/ImportPlanogramReportDialog';
    import CopyPlanogramToDialog           from '@/spaceallocation/planogrameditor/ui/dialog/CopyPlanogramToDialog';
    import CopyPlanogramFromDialog         from '@/spaceallocation/planogrameditor/ui/dialog/CopyPlanogramFromDialog';
	import CreatePhysicalPlanogramDialog
										   from '@/spaceallocation/physicalplanogrameditor/ui/dialog/CreatePhysicalPlanogramDialog';
	import CopyPhysicalPlanogramToDialog
										   from '@/spaceallocation/physicalplanogrameditor/ui/dialog/CopyPhysicalPlanogramToDialog';
	import CopyPhysicalPlanogramFromDialog
										   from '@/spaceallocation/physicalplanogrameditor/ui/dialog/CopyPhysicalPlanogramFromDialog';

    export default {
        name: 'PlanogramsCatalog',
        components: {
			CopyPhysicalPlanogramFromDialog,
			CopyPhysicalPlanogramToDialog,
			CreatePhysicalPlanogramDialog,
	        CopyPlanogramFromDialog,
	        CopyPlanogramToDialog,
            ImportPlanogramReportDialog,
            CreatePlanogramDialog,
            ImportPlanogramDialog,
            ContentCard
        },
        props: {
            query: {
                type: Object,
                default: () => ({
                    limit: 50,
                    page: 0,
                    matching: null,
                    visible: true,
					type: 'logical'
                })
            },
            selectable: Boolean,
            value: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                totalItems: 0,
                planograms: [],
                loading: false,
                selected: this.value
            };
        },
        mounted() {
            this.loadPlanograms(false);
        },
        computed: {
            totalPages() {
                return Math.max(Math.ceil(this.totalItems / this.query.limit), 1);
            },
            totalMatches(){
                return this.totalItems;
            }
        },
        watch: {
			'query.type'() {
				this.loadPlanograms(true);
			},
            'query.limit'() {
                this.query.page = Math.min(this.query.page, this.totalPages);
                this.loadPlanograms(false);
            },
            'query.page'() {
                this.loadPlanograms(false);
            },
            'query.matching'() {
                this.loadPlanograms(true);
            },
            'query.visible'() {
                this.loadPlanograms(true);
            },
            value() {
                this.selected = this.value;
            },
            selected() {
                this.$emit('input', this.selected);
            }
        },
        methods: {
            loadPlanograms(resetPageCount = true) {
                if (resetPageCount) {
                    this.totalItems = 0;
                    this.query.page = 0;
                }
                const {limit, page, matching, visible, type} = this.query;
				let request;
				if(type === 'logical')
					request =
						this.$planogram.listPlanograms(limit, page * limit, matching, visible, this.$storage.accountId)
							.then(response => {
								this.planograms = response.planogramList;
								this.planograms.forEach(p => p.type = 'Planogram');
								this.totalItems = response.totalMatchesCount;
							})
				else
					request =
						this.$physicalPlanogram.listPhysicalPlanograms(limit, page * limit, matching, visible, this.$storage.accountId)
							.then(response => {
								this.planograms = response.physicalPlanogramList;
								this.planograms.forEach(p => p.type = 'PhysicalPlanogram');
								this.totalItems = response.totalMatchesCount;
							})
				this.$loading.await(request.catch(error => console.log(error)));
            },
            goToPlanogram(planogram) {
                this.$router.push({
                    name: 'planogram',
                    params: {planogramId: planogram.id}
                });
            },
            goToPhysicalPlanogram(physicalPlanogram) {
                this.$router.push({
                    name: 'physicalPlanogram',
                    params: {physicalPlanogramId: physicalPlanogram.id}
                });
            },
            createPlanogram() {
				this.query.type === 'logical' ?
					this.$refs.createPlanogramDialog.open()
						.then(response => this.goToPlanogram(response.planogram)) :
					this.$refs.createPhysicalPlanogramDialog.open()
						.then(response => this.goToPhysicalPlanogram(response.physicalPlanogram));
            },
            formattedDataFromISO(isoDate) {
                return StringUtils.formattedDateTimeFromISO(isoDate);
            },
            importPlanogram() {
                this.$refs.importPlanogramDialog.open()
                    .then(planogram => this.$refs.importPlanogramReportDialog.open(planogram))
                    .catch(e => console.error(e));
            },
	        copyPlanogramFrom() {
            	this.$refs.copyPlanogramFromDialog.open()
		            .then(() => this.loadPlanograms())
	        },
	        copyPhysicalPlanogramFrom() {
            	this.$refs.copyPhysicalPlanogramFromDialog.open()
		            .then(() => this.loadPlanograms())
	        },
            nameValidator(name) {
                if (name === null || !/^(\w|\d|_|-|\.|\s){1,30}$/.test(name))
                    return 'This name is invalid';
                return null;
            },
            showContextMenu(event, row) {
                this.$refs.contextMenu.showMenu(event, this.buildContextMenu(row));
            },
            buildContextMenu(row) {
	            return [{
		            text: 'Duplicate',
		            icon: 'add_to_photos',
		            action: () => {
						const onConfirm =
							row.type === 'logical' ?
								name => this.$planogram.clonePlanogram(row.id, name, this.$storage.workgroupId)
									.then(response => this.goToPlanogram(response.planogram)) :
								name => this.$physicalPlanogram.clonePhysicalPlanogram(row.id, name, this.$storage.workGroupId)
									.then(response => this.goToPhysicalPlanogram(response.physicalPlanogram));
			            this.$refs.inputDialog.open(
				            'Duplicate planogram',
				            'text',
				            [this.nameValidator],
				            'primary',
				            'Planogram name',
				            'Planogram name',
				            null, null, row.name + ' copy'
			            ).then(onConfirm)
		            }
	            }, (row.visible ? {
		            text: 'Hide',
		            icon: 'visibility_off',
		            action: () =>
						row.type === 'logical' ?
							this.changePlanogramsVisibility([row.id], false) :
							this.changePhysicalPlanogramsVisibility([row.id], false)
	            } : {
		            text: 'Show',
		            icon: 'remove_red_eye',
					action: () =>
						row.type === 'logical' ?
							this.changePlanogramsVisibility([row.id], true) :
							this.changePhysicalPlanogramsVisibility([row.id], true)
	            }),
	            {
		            text: 'Copy',
		            icon: 'far fa-copy',
		            action: () =>
						row.type === 'logical' ?
						this.$refs.copyPlanogramToDialog.open(row) :
						this.$refs.copyPhysicalPlanogramToDialog.open(row)
	            }
	            ];
            },
            /**
             * @param {string[]} planogramsIds
             * @param {boolean} visible
             */
            changePlanogramsVisibility(planogramsIds, visible) {
                this.$planogram.changePlanogramsVisibility(planogramsIds, visible)
                    .then(() => {
                        const page = Math.min(
                            Math.floor((this.totalItems - planogramsIds.length - 1) / this.query.limit),
                            this.query.page
                        );
                        //Directly call the update of the page if the page has not changed otherwise the view will not be updated
                        if (this.query.page === page)
                            this.loadPlanograms(false);
                        else this.query.page = page;
                    });
            },
            /**
             * @param {string[]} physicalPlanogramsIds
             * @param {boolean} visible
             */
            changePhysicalPlanogramsVisibility(physicalPlanogramsIds, visible) {
                this.$physicalPlanogram.changePhysicalPlanogramsVisibility(physicalPlanogramsIds, visible)
                    .then(() => {
                        const page = Math.min(
                            Math.floor((this.totalItems - physicalPlanogramsIds.length - 1) / this.query.limit),
                            this.query.page
                        );
                        //Directly call the update of the page if the page has not changed otherwise the view will not be updated
                        if (this.query.page === page)
                            this.loadPlanograms(false);
                        else this.query.page = page;
                    });
            }
        }
    };
</script>

<style scoped>

    .header {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .planogramsTable {
        box-sizing: border-box;
        padding: 0 var(--quadrupleMargin) var(--quadrupleMargin) var(--quadrupleMargin);
    }

	.logicalIndicator {
		display: inline-block;
		width: 14px;
		height: 14px;
		background-color: #3966ff;
		border-radius: 2px;
	}

</style>
