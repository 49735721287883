<template>
    <div y-spaced-2>
        <planogram-reference-info :controller="controller" :asset="asset"/>
        <h2 primary-text>Dimensions (cm)</h2>
        <ch-dl label-width="45%">
            <ch-dd label="Planogram">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <span>{{asset.volume.height / 10}}</span>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <span>{{asset.volume.width / 10}}</span>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <span>{{asset.volume.depth / 10}}</span>
                </div>
            </ch-dd>
            <ch-dd label="In layout">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <b>{{Math.round(element.materializedVolume.height * 10) / 100}}</b>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <b>{{Math.round(element.materializedVolume.width * 10) / 100}}</b>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <b>{{Math.round(element.materializedVolume.depth * 10) / 100}}</b>
                </div>
            </ch-dd>
        </ch-dl>
        <transform :controller="controller" :element="element" :disabled="!!container"/>
        <materializer :controller="controller" :element="element" :disabled="!!container"/>
        <delete-selection-button text="Remove element"/>
    </div>
</template>

<script>

    import LayoutController       from '../services/LayoutController';
    import Transform              from './Transform';
    import Materializer           from './Materializer';
    import RGBColor               from '../model/common/color/RGBColor';
    import DeleteSelectionButton  from './DeleteSelectionButton';
    import PlanogramReferenceInfo from './PlanogramReferenceInfo';

    export default {
        name: 'PlanogramInstanceInfo',
        components: {PlanogramReferenceInfo, DeleteSelectionButton, Materializer, Transform},
        props: {
            controller: LayoutController,
            element: Object
        },
        computed: {
            asset() {
                return this.controller.findAsset(this.element.assetId);
            },
            /**
             * @returns {IElement[]}
             */
            path() {
                return this.controller.extractBranch(this.element.id);
            },
            container() {
                return this.path.find(e => e.type === 'PlanogramsContainer');
            },
            color() {
                const color = this.asset.color;
                return new RGBColor(color.r, color.g, color.b).toString();
            }
        }
    };
</script>

<style scoped>

    .planogramName {
        word-break: break-all;
    }

</style>