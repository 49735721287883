<template>
	<div class="propCard" :type="type" :disabled="disabled">
		<div class="body">
			<ch-image :src="prop.imageURL"/>
		</div>
		<div class="footer">
			<div ellipsis>{{ prop.name }}</div>
			<div ellipsis><size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/></div>
		</div>
	</div>
</template>

<script>
import Size from '@/components/Size';
export default {
	name: 'PropCard',
	components: {Size},
	props: {
		prop: Object,
		type: {
			type: String,
			default: 'default',
			validator(type) {
				return ['default', 'primary'].indexOf(type) !== -1;
			}
		},
		disabled: Boolean
	}
};
</script>

<style scoped>

.propCard {
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.body {
	flex: 1 1 auto;
	padding: var(--doubleMargin);
	display: flex;
	overflow: hidden;
}

.footer {
	flex: 0 0 auto;
	padding: var(--halfMargin);
}

.propCard:not([disabled]) {
	background-color: var(--elevation-03);
}

.propCard[disabled] {
	pointer-events: none;
	background-color: var(--disabled);
	color: var(--on-disabled);
}

.propCard[disabled] > .footer {
	pointer-events: none;
	background-color: var(--disabled);
	color: var(--on-disabled);
}

.propCard:not([disabled]) {
	cursor: pointer;
}

.propCard[type=default]:not([disabled]) .footer {
	background-color: var(--elevation-04) !important;
	color: var(--on-elevation-04) !important;
}

.propCard[type=primary]:not([disabled]) {
	box-shadow: 0 0 0 2px var(--primary);
}

.propCard[type=primary]:not([disabled]) .footer {
	background-color: var(--primary) !important;
	color: var(--on-primary) !important;
}

</style>