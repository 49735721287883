<template>
	<div class="relatedElements" y-spaced-2>
		<div class="title">
			<h2 primary-text>Related elements</h2>
			<div>
				<slot name="titleAppend"/>
			</div>
		</div>
		<div v-if="loading">Loading related elements...</div>
		<ch-empty-content v-else-if="relatedPropIds.length === 0" firstMessage="No related element found"/>
		<div v-else class="relatedPropsGrid">
			<ch-grid-layout :width="140" :height="200" :gap="32">
				<slot name="propCard" v-for="prop in relatedProps" :prop="prop">
					<prop-card :key="prop.id" :prop="prop" @click.native="$emit('propClicked', prop)"/>
				</slot>
			</ch-grid-layout>
		</div>
	</div>
</template>

<script>
import PropCard from '@/content/assets/components/PropCard';
import Prop     from '@/plugins/studioapi/api/propstore/model/Prop';

export default {
	name: 'PropRelatedElements',
	components: {PropCard},
	props: {
		prop: Prop | Object,
		props: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			loading: true
			// relatedPropIds: []
		};
	},
	computed: {
		relatedPropIds() {
			return this.props.slice(0, 5).map(p => p.id);
		},
		relatedProps() {
			return this.relatedPropIds.map(id => this.props.find(prop => prop.id === id));
		}
	},
	watch: {
		prop: {
			handler() {
				this.loading = true;
				this.$propStore.listRelatedProps(this.prop.id)
					.then(response => {
						this.loading = false;
						this.relatedPropIds = response.propIds;
					});
			},
			immediate: true
		}
	}

};
</script>

<style scoped>

.relatedElements {
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 var(--doubleMargin);
}

.relatedPropsGrid {
	overflow-y: auto;
	padding: var(--doubleMargin);
}

</style>