<template>
	<div class="transform" y-spaced-2>
		<h2 primary-text>Transform</h2>
		<ch-field label="x position (cm)" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="r" type="primary" :value="Math.trunc(element.position.r) / 10" @input="setR"/>
		</ch-field>
		<ch-field label="y position (cm)" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="f" type="primary" :value="Math.trunc(element.position.f) / 10" @input="setF"/>
		</ch-field>
		<ch-field label="z position (cm)" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="u" type="primary" :value="Math.trunc(element.position.u) / 10" @input="setU"/>
		</ch-field>
		<h2 primary-text>Rotation</h2>
		<ch-field label="x rotation" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="pitch" type="primary" :value="element.rotation.pitch" @input="setPitch"/>
		</ch-field>
		<ch-field label="y rotation" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="roll" type="primary" :value="element.rotation.roll" @input="setRoll"/>
		</ch-field>
		<ch-field label="z rotation" :validators="[numberValidator]" label-position="left" :label-width="180">
			<ch-number-input ref="yaw" type="primary" :value="element.rotation.yaw" @input="setYaw"/>
		</ch-field>
	</div>
</template>

<script>

import Position from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Position';
import Rotation from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Rotation';
import IElement from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/IElement';

export default {
	name: 'BucketTransform',
	props: {
		element: IElement | Object
	},
	data() {
		return {};
	},
	methods: {
		/**
		 * @param {string|null} n
		 * @return {string|null}
		 */
		numberValidator(n) {
			if (n === null || !/^-?\d+(\.\d+)?(e[-+]\d+)?$/.test(n))
				return 'The value is not a valid number';
			return null;
		},
		/**
		 * @param {number} f
		 * @param {number} r
		 * @param {number} u
		 */
		updatePosition(f, r, u) {
			this.$unityManager.planogramAPI.MoveElement(this.element.id, new Position(f, r, u));
		},
		/**
		 * @param {number} pitch
		 * @param {number} yaw
		 * @param {number} roll
		 */
		updateRotation(pitch, yaw, roll) {
			this.$unityManager.planogramAPI.RotateElement(this.element.id, new Rotation(pitch, yaw, roll));
		},
		/**
		 * @param {string} value
		 */
		setF(value) {
			const f = Number.parseFloat(value) * 10;
			this.$refs.f.cancel();
			if(Math.trunc(this.element.position.f) !== f)
				this.updatePosition(f, this.element.position.r, this.element.position.u);
		},
		/**
		 * @param {string} value
		 */
		setR(value) {
			const r = Number.parseFloat(value) * 10;
			this.$refs.r.cancel();
			if(Math.trunc(this.element.position.r) !== r)
				this.updatePosition(this.element.position.f, r, this.element.position.u);
		},
		/**
		 * @param {string} value
		 */
		setU(value) {
			const u = Number.parseFloat(value) * 10;
			this.$refs.u.cancel();
			if(Math.trunc(this.element.position.u) !== u)
				this.updatePosition(this.element.position.f, this.element.position.r, u);
		},
		/**
		 * @param {string} value
		 */
		setPitch(value) {
			const pitch = Number.parseFloat(value);
			this.updateRotation(pitch, this.element.rotation.yaw, this.element.rotation.roll);
			this.$refs.pitch.cancel();
		},
		/**
		 * @param {string} value
		 */
		setYaw(value) {
			const yaw = Number.parseFloat(value);
			this.updateRotation(this.element.rotation.pitch, yaw, this.element.rotation.roll);
			this.$refs.yaw.cancel();
		},
		/**
		 * @param {string} value
		 */
		setRoll(value) {
			const roll = Number.parseFloat(value);
			this.updateRotation(this.element.rotation.pitch, this.element.rotation.yaw, roll);
			this.$refs.roll.cancel();
		}
	}

};
</script>

<style scoped>

</style>