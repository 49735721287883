<template>
  <div class="market-data-table">
    <div class="market-data-table-header">
      <h1 v-if="totalProducts">
        {{ currentProducts }} / {{ totalProducts }} products
      </h1>
      <ch-pagination
        v-if="totalPages"
        v-model="currentPage"
        :max="totalPages"
      />
    </div>
    <table class="table-page">
      <thead>
        <tr>
          <th
            v-for="(header, headerIndex) in orderedHeader"
            :key="`${header}-${headerIndex}`"
            class="table-page-header"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in bodyRows" :key="rowIndex">
          <td
            v-for="(cell, cellIndex) in row"
            :key="`${cell}-${cellIndex}`"
            class="table-page-data-cell"
          >
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { kpiImportOptions } from '../../../utils/KPI/kpiImportOptions';

export default {
  name: 'MarketDataTable',
  props: {
    /*
     * Sets the data to display.
     */
    marketData: {
      type: Object,
      default: () => {},
    },
    /*
     * Sets number of entries displayed in every page.
     */
    entryPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 0,
    };
  },
  computed: {
    totalProducts() {
      return this.marketData.body ? this.marketData.body.length : 0;
    },
    currentProducts() {
      if (!this.totalProducts) return this.totalProducts;

      const products = (this.currentPage + 1) * this.entryPerPage;

      return products < this.totalProducts ? products : this.totalProducts;
    },
    totalPages() {
      return this.totalProducts
        ? Math.ceil(this.totalProducts / this.entryPerPage)
        : 0;
    },
    pages() {
      return this.totalProducts
        ? this.marketData.body.reduce((accumulator, currentValue, index) => {
            const subarrayIndex = Math.floor(index / this.entryPerPage);
            const page =
              accumulator[subarrayIndex] || (accumulator[subarrayIndex] = []);

            page.push(currentValue);

            return accumulator;
          }, [])
        : [];
    },
    currentPageItems() {
      return this.pages[this.currentPage] || [];
    },
    currentPageItemsKeys() {
      return this.orderedHeader.map(header => header.key);
    },
    bodyRows() {
      if (!this.orderedHeader || !this.orderedHeader.length) return [];

      const header = this.orderedHeader.map(header => header.key);

      return this.currentPageItems.reduce((acc, val) => {
        acc.push(header.map(key => val[key]));

        return acc;
      }, []);
    },
    orderedHeader() {
      if (!this.totalProducts) return [];

      const requiredOrAnalytics = kpiImportOptions.reduce(
        (accumulator, currentValue) => {
          const option = this.marketData.header.find(
            header => header.key === currentValue.key
          );

          option && accumulator.push(option);

          return accumulator;
        },
        []
      );

      const records = this.marketData.header.filter(
        header => header.type === 'record'
      );

      return [...requiredOrAnalytics, ...records];
    },
  },
};
</script>

<style scoped>
.market-data-table-header {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.market-data-table-header > * {
  margin: 0 16px;
}

::v-deep .pagination > .position > .inputNumber {
  width: 56px;
}

.table-page {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

.table-page-header {
  width: 160px;
  text-align: justify;
  padding: 8px;
  border-top: 1px solid var(--elevation-04);
  border-bottom: 1px solid var(--elevation-04);
}

.table-page-data-cell {
  width: 160px;
  padding: 8px;
  border-top: 1px solid var(--elevation-04);
  border-bottom: 1px solid var(--elevation-04);
}
</style>
