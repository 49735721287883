<template>
	<div class="propDetails" y-spaced-2>
		<div class="title">
			<h2>{{ prop.name }}</h2>
			<div><slot name="titleAppend"/></div>
		</div>
		<div class="details" x-spaced-2>
			<div>
				<ch-image :src="prop.imageURL"/>
			</div>
			<div>
				<ch-dl label-position="top">
					<ch-dd label="Dimensions">
						<size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/>
					</ch-dd>
					<ch-dd label="Category">{{ prop.archetype.type | formatArchetype }}</ch-dd>
				</ch-dl>
			</div>
		</div>
	</div>
</template>
<script>
import Size            from '@/components/Size';
import {ArchetypeType} from '@/plugins/studioapi/api/propstore/model/ArchetypeType';
import Prop            from '@/plugins/studioapi/api/propstore/model/Prop';

export default {
	name: 'prop-details',
	components: {Size},
	props: {
		prop: Prop | Object
	},
	filters: {
		/**
		 * @param {ArchetypeType} archetypeType
		 * @return {string}
		 */
		formatArchetype(archetypeType) {
			switch (archetypeType) {
				case ArchetypeType.Basket:
					return 'Basket';
				case ArchetypeType.Chest:
					return 'Chest';
				case ArchetypeType.ChestFridge:
					return 'Chest Fridge';
				case ArchetypeType.Decoration:
					return 'Decoration';
				case ArchetypeType.Fridge:
					return 'Fridge';
				case ArchetypeType.Hook:
					return 'Hook';
				case ArchetypeType.Pallet:
					return 'Pallet';
				case ArchetypeType.Pegboard:
					return 'Pegboard';
				case ArchetypeType.Shelf:
					return 'Shelf';
				case ArchetypeType.ShelfBack:
					return 'Back';
				case ArchetypeType.WolfMouthFridge:
					return 'Wolf\'s Mouth Fridge';
			}
		}
	}
};
</script>
<style scoped>

.propDetails {
	padding: var(--doubleMargin);
}

.details {
	display: flex;
	flex-direction: row;
}

.details > div {
	overflow: hidden;
	flex: 1 1 0;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

</style>