<template>
    <ch-form-dialog ref="dialog" title="Export planogram" type="primary" @cancel="cancel" @submit="exportPhysicalPlanogram">
        <ch-field label="Choose your format to download">
            <ch-radio-group v-model="format">
                <ch-radio v-for="allowedFormat of allowedFormats" :key="allowedFormat" :label="allowedFormat.value">{{allowedFormat.label}}</ch-radio>
            </ch-radio-group>
        </ch-field>
    </ch-form-dialog>
</template>

<script>

import {download, MediaType} from '@/utils/DownloadService';

    export default {
        name: 'ExportPhysicalPlanogramDialog',
        data() {
            return {
                planogram: null,
                allowedFormats: [
                    {
                        value: 'ShelfZoneStudio',
                        extension: '.json',
                        label: 'ShelfZone Studio (.json)'
                    }
                ],
                format: 'ShelfZoneStudio'
            }
        },
        methods: {
            /**
             * @param {PhysicalPlanogram} physicalPlanogram
             */
            open(physicalPlanogram) {
                this.physicalPlanogram = physicalPlanogram;
                this.$refs.dialog.open();
            },
            cancel() {
                this.format = 'studio';
            },
            exportPhysicalPlanogram() {
				const mediaType = MediaType['application/json'];
				download(this.physicalPlanogram, `${this.physicalPlanogram.info.name.trim()}${extension}`, mediaType);
			}
        }
    }
</script>

<style scoped>

</style>
