<template>
	<accordion title="Transform" class="voice">
		<template slot="titleAppend"><ch-checkbox :value="element.freePositioning" @input="setFreePositioning">Free position</ch-checkbox></template>
		<ch-dl label-width="50%">
			<ch-dd label="x position"><ch-number-input ref="r" type="primary" :disabled="!element.freePositioning" :value="Math.trunc(element.position.r) / 10" @input="setR"/></ch-dd>
			<ch-dd label="y position"><ch-number-input ref="f" type="primary" :disabled="!element.freePositioning" :value="Math.trunc(element.position.f) / 10" @input="setF"/></ch-dd>
			<ch-dd label="z position"><ch-number-input ref="u" type="primary" :disabled="!element.freePositioning" :value="Math.trunc(element.position.u) / 10" @input="setU"/></ch-dd>
			<ch-dd label="x rotation"><ch-number-input ref="pitch" type="primary" :disabled="!element.freePositioning" :value="element.rotation.pitch" @input="setPitch"/></ch-dd>
			<ch-dd label="y rotation"><ch-number-input ref="roll" type="primary" :disabled="!element.freePositioning" :value="element.rotation.roll" @input="setRoll"/></ch-dd>
			<ch-dd label="z rotation"><ch-number-input ref="yaw" type="primary" :disabled="!element.freePositioning" :value="element.rotation.yaw" @input="setYaw"/></ch-dd>
		</ch-dl>
	</accordion>
</template>

<script>

import Position  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Position';
import Rotation  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Rotation';
import Accordion from '@/components/Accordion';
import Bucket    from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Bucket';

export default {
	name: 'Transform',
	components: {Accordion},
	props: {
		element: Bucket | Object
	},
	data() {
		return {};
	},
	methods: {
		/**
		 * @param {number} f
		 * @param {number} r
		 * @param {number} u
		 */
		updatePosition(f, r, u) {
			this.$unityManager.planogramAPI.MoveElement(this.element.id, new Position(f, r, u));
		},
		/**
		 * @param {number} pitch
		 * @param {number} yaw
		 * @param {number} roll
		 */
		updateRotation(pitch, yaw, roll) {
			this.$unityManager.planogramAPI.RotateElement(this.element.id, new Rotation(pitch, yaw, roll));
		},
		/**
		 * @param {number} value
		 */
		setF(value) {
			this.updatePosition(value * 10, this.element.position.r, this.element.position.u);
			this.$refs.f.cancel();
		},
		/**
		 * @param {number} value
		 */
		setR(value) {
			this.updatePosition(this.element.position.f, value * 10, this.element.position.u);
			this.$refs.r.cancel();
		},
		/**
		 * @param {number} value
		 */
		setU(value) {
			this.updatePosition(this.element.position.f, this.element.position.r, value * 10);
			this.$refs.u.cancel();
		},
		/**
		 * @param {number} value
		 */
		setPitch(value) {
			this.updateRotation(value, this.element.rotation.yaw, this.element.rotation.roll);
			this.$refs.pitch.cancel();
		},
		/**
		 * @param {number} value
		 */
		setYaw(value) {
			this.updateRotation(this.element.rotation.pitch, value, this.element.rotation.roll);
			this.$refs.yaw.cancel();
		},
		/**
		 * @param {number} value
		 */
		setRoll(value) {
			this.updateRotation(this.element.rotation.pitch, this.element.rotation.yaw, value);
			this.$refs.roll.cancel();
		},
		/**
		 * @param {boolean} value
		 */
		setFreePositioning(value) {
			this.element.freePositioning = value;
			this.$unityManager.planogramAPI.ReplaceElement(this.element);
			this.$refs.roll.cancel();
		}
	}

};
</script>

<style scoped>

.voice {
	padding: var(--doubleMargin);
}

</style>