<template>
    <div
        v-if="repository.physicalPlanogram"
        class="leftSideBar"
        fill
        @dragover="$emit('dragover')"
    >
        <ch-tabs v-model="selectedTab">
            <template slot="voices">
                <ch-tab-voice key="products" label="Products"/>
                <ch-tab-voice key="assets" label="Assets"/>
            </template>
            <template slot="tabs">
                <ch-tab-content key="products">
                    <products-library
                        :repository="repository"
                        :selectionManager="selectionManager"
                        :cameraView="cameraView"
                        @market-data-clicked="$emit('market-data-clicked')"
                    />
                </ch-tab-content>
                <ch-tab-content key="assets">
                    <props-library :repository="repository"/>
                </ch-tab-content>
            </template>
        </ch-tabs>
    </div>
</template>

<script>

    import ProductsLibrary    from './ProductsLibrary';
    import SelectionManager   from '../../managers/SelectionManager';
    import Repository                 from '../../services/Repository';
	import PropsLibrary       from '@/spaceallocation/physicalplanogrameditor/ui/leftsidebar/PropsLibrary';

    export default {
        name: 'LeftSideBar',
        components: {PropsLibrary, ProductsLibrary},
        props: {
            repository: Repository,
            selectionManager: SelectionManager
        },
        data() {
            return {
                selectedTab: 'products',
                cameraView: 'Front'
            };
        },
        computed: {
            editor() {
                return this.$parent.$parent;
            }
        },
        mounted() {
            this.$unityManager.planogramAPI.SubscribeToCameraChanged(this.cameraViewChanged);
        },
        methods: {
            /**
             * @param {{view: string}} event
             */
            cameraViewChanged(event) {
                this.cameraView = event.view;
            }
        }
    };
</script>

<style scoped>

    .leftSideBar {
        display: flex;
        flex-direction: column;
        outline: none;
    }

</style>