<template>
	<ch-dialog ref="dialog" :title="title" type="primary" size="fullscreen">
		<ch-application-layout slot="main" header-size="small">
			<template slot="navigation">
				<ch-tree v-model="path" :nodes="nodes"/>
			</template>
			<div class="mainContent" slot="body">
				<div class="propList">
					<ch-filters class="propFilters" slot="header">
						<div></div>
						<div>
							<ch-pagination :max="pageCount" v-model="page"/>
						</div>
						<div>
							<ch-search-bar v-model="matching" real-time size="small narrow"/>
						</div>
					</ch-filters>
					<div v-if="propsInPage.length > 0" class="propGrid">
						<ch-grid-layout :width="140" :height="200" :gap="32">
							<prop-card v-for="prop of propsInPage" :key="prop.id" :prop="prop" :disabled="isExcluded(prop)"
									   :type="currentProp === prop ? 'primary' : 'default'"
									   @click.native="currentProp = prop"/>
						</ch-grid-layout>
					</div>
					<ch-empty-content v-else firstMessage="No asset found"/>
				</div>
				<div v-if="currentProp" class="currentProp" y-spaced-2>
					<prop-details :prop="currentProp">
						<ch-button slot="titleAppend" type="primary" size="small" :disabled="isSelected(currentProp)"
								   @click="addToSelection([currentProp])">
							{{ isSelected(currentProp) ? 'Added' : 'Add' }}
						</ch-button>
					</prop-details>
					<prop-related-elements :prop="currentProp" :props="props">
						<ch-button slot="titleAppend" type="primary" :disabled="areSelected(currentRelatedProps)"
								   @click="addToSelection(currentRelatedProps); clearCurrentRelatedProps()">Add selected
						</ch-button>
						<template slot="propCard" slot-scope="{ prop }">
							<prop-card :key="prop.id" :prop="prop" :disabled="isExcluded(prop)"
									   :type="isInCurrentRelatedProps(prop) ? 'primary' : 'default'"
									   @click.native="toggleCurrentRelatedProp(prop)"/>
						</template>
					</prop-related-elements>
				</div>
			</div>
			<div slot="sidebar" class="sidebar" y-spaced-2>
				<div y-spaced-2>
					<h2 primary-text>Adding {{ selectedProps.length }} elements</h2>
					<ch-button @click="selectedProps = []" type="secondary" size="small narrow">Clear</ch-button>
					<ch-list size="narrow" removable>
						<ch-list-item v-for="prop of selectedProps" :key="prop.id"
									  @remove="removeFromSelection([prop])">
							{{ prop.name }}
						</ch-list-item>
					</ch-list>
				</div>
				<div class="actions" x-spaced-2>
					<ch-button @click="cancel" type="secondary" size="small">Cancel</ch-button>
					<ch-button @click="confirm" type="primary" size="fill">{{ confirmButtonText }}</ch-button>
				</div>
			</div>
		</ch-application-layout>
	</ch-dialog>
</template>

<script>
import {ArchetypeType}     from '@/plugins/studioapi/api/propstore/model/ArchetypeType';
import PropCard            from '@/content/assets/components/PropCard';
import PropDetails         from '@/content/assets/components/PropDetails';
import PropRelatedElements from '@/content/assets/components/PropRelatedElements';

export default {
	name: 'PropFinder',
	components: {PropRelatedElements, PropDetails, PropCard},
	props: {
		title: String,
		confirmButtonText: String
	},
	data() {
		return {
			path: [],
			limit: 50,
			page: 0,
			matching: null,
			props: [],
			archetypeTypes: [
				{id: ArchetypeType.ShelfBack, text: 'Back'},
				{id: ArchetypeType.Basket, text: 'Basket'},
				{id: ArchetypeType.Chest, text: 'Chest'},
				{id: ArchetypeType.ChestFridge, text: 'Chest Fridge'},
				{id: ArchetypeType.Decoration, text: 'Decoration'},
				{id: ArchetypeType.Fridge, text: 'Fridge'},
				{id: ArchetypeType.Hook, text: 'Hook'},
				{id: ArchetypeType.Pallet, text: 'Pallet'},
				{id: ArchetypeType.Pegboard, text: 'Pegboard'},
				{id: ArchetypeType.Shelf, text: 'Shelf'},
				{id: ArchetypeType.WolfMouthFridge, text: 'Wolf\'s Mouth Fridge'}
			],
			currentProp: null,
			currentRelatedProps: [],
			selectedProps: [],
			reject: null,
			resolve: null
		};
	},
	computed: {
		nodes() {
			return this.archetypeTypes.map(n => ({
				id: n.id,
				text: n.text,
				append: this.props.filter(p => p.archetype.type === n.id).length
			}));
		},
		selectedArchetypeType() {
			return this.path.length > 0 ? this.path[0].id : null;
		},
		filteredProps() {
			return this.props
				.filter(p => this.selectedArchetypeType ? p.archetype.type === this.selectedArchetypeType : true)
				.filter(p => this.matching ? p.name.include(this.matching) : true);
		},
		pageCount() {
			return Math.max(1, Math.ceil(this.filteredProps.length / this.limit));
		},
		propsInPage() {
			return this.filteredProps
				.filter((p, i) => this.page * this.limit <= i && i < (this.page + 1) * this.limit);
		}
	},
	watch: {
		pageCount() {
			this.page = 0;
		},
		currentProp() {
			this.currentRelatedProps = [];
		}
	},
	methods: {
		/**
		 * @param {string[]} excluded
		 * @return {Promise<Prop[]>}
		 */
		open(excluded = []) {
			this.excluded = excluded;
			this.$refs.dialog.open();
			this.$loading.await(this.$propStore.listProps())
				.then(response => {
					this.props = response.props;
				});
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		reset() {
			this.path = [];
			this.page = 0;
			this.matching = null;
			this.currentProp = null;
			this.currentRelatedProps = [];
			this.selectedProps = [];
			this.$refs.dialog.close();
		},
		confirm() {
			this.resolve(this.selectedProps);
			this.reset();
		},
		cancel() {
			this.reject();
			this.reset();
		},
		/**
		 * @param {Prop} prop
		 */
		isExcluded(prop) {
			return this.excluded.some(excludedId => prop.id === excludedId);
		},
		/**
		 * @param {Prop} prop
		 */
		isInCurrentRelatedProps(prop) {
			return this.currentRelatedProps.some(relatedProp => relatedProp.id === prop.id);
		},
		/**
		 * @param {Prop} prop
		 */
		toggleCurrentRelatedProp(prop) {
			if (this.isInCurrentRelatedProps(prop))
				this.currentRelatedProps = this.currentRelatedProps.filter(relatedProp => relatedProp.id !== prop.id);
			else
				this.currentRelatedProps.push(prop);
		},
		clearCurrentRelatedProps() {
			this.currentRelatedProps = [];
		},
		/**
		 * @param {Prop} prop
		 * @return {boolean}
		 */
		isSelected(prop) {
			return this.selectedProps.some(p => p.id === prop.id);
		},
		/**
		 * @param {Prop[]} props
		 */
		areSelected(props) {
			return props.every(this.isSelected);
		},
		/**
		 * @param {Prop[]} props
		 */
		removeFromSelection(props) {
			this.selectedProps = this.selectedProps.filter(selectedProp => !props.some(prop => prop.id === selectedProp.id));
		},
		/**
		 * @param {Prop[]} props
		 */
		addToSelection(props) {
			this.removeFromSelection(props);
			this.selectedProps = [...this.selectedProps, ...props];
		}
	}
};
</script>

<style scoped>

.propList {
	max-height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	border-right: 1px solid var(--elevation-04);
}

.propFilters {
	padding: var(--doubleMargin);
}

.propGrid {
	max-height: 100%;
	overflow-y: auto;
	padding: var(--doubleMargin);
}

.currentProp {
	max-height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.currentProp > div {
	flex: 1 1 auto;
}

.currentProp > div:first-child {
	border-bottom: 2px solid var(--elevation-03);
}

.mainContent {
	display: flex;
	flex-direction: row;
}

.mainContent > div {
	flex: 1 1 0;
}

.sidebar {
	padding: var(--doubleMargin);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	box-sizing: border-box;
}

.sidebar .actions {
	display: flex;
	justify-content: space-between;
}

</style>