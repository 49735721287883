<template>
	<div class="multipleElementsInfo">
		<div class="info">
			<div class="section">
				<h1 primary-text>Multiple selection</h1>
				<ch-dl label-width="50%">
					<ch-dd label="Multiple elements"></ch-dd>
				</ch-dl>
			</div>
		</div>
		<div class="actions" y-spaced-2>
			<isolate-elements-button :elements-ids="elementsIds"/>
			<remove-elements-button :elements-ids="elementsIds"/>
		</div>
	</div>
</template>

<script>
import RemoveElementsButton
							 from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/RemoveElementsButton';
import IsolateElementsButton
							 from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/IsolateElementsButton';
export default {
	name: 'MultipleElementsInfo',
	components: {IsolateElementsButton, RemoveElementsButton},
	props: {
		elementsIds: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		isolateElements() {
		},
		deleteElements() {
			this.$unityManager.planogramAPI.DeleteElements(this.elementsIds);
		}
	}
};
</script>

<style scoped>

.multipleElementsInfo {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-content: stretch;
	overflow: auto;
}

.info {
	flex: 1 1 auto;
	border-bottom: 1px solid var(--elevation-04);
}

.actions {
	padding: var(--doubleMargin);
}

.section {
	padding: var(--doubleMargin);
}

.section:not(:last-child) {
	border-bottom: 1px solid var(--elevation-04);
}

</style>