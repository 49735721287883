<template>
    <div class="actionBar" v-if="repository.physicalPlanogram">
        <div class="buttons" x-spaced-1>
            <ch-button type="overlay" icon @click="goBack">
                <ch-icon icon="arrow_back"/>
            </ch-button>
            <ch-context-menu type="overlay" :voices="voices">
                <ch-button type="overlay" class="contextButton" icon>
                    <ch-icon icon="more_horiz"/>
                </ch-button>
            </ch-context-menu>
            <ch-button type="overlay" icon @click="save" :disabled="!saveEnabled">
                <ch-icon icon="save"/>
            </ch-button>
            <ch-button type="overlay" icon @click="undo" :disabled="!couldUndo">
                <ch-icon icon="undo"/>
            </ch-button>
			<ch-button type="overlay" icon @click="redo" :disabled="!couldRedo">
				<ch-icon icon="redo"/>
			</ch-button>
			<ch-button type="overlay" icon @click="toggleSnap">
				<ch-icon icon="fas fa-magnet" :primary-text="snapEnabled"/>
			</ch-button>
			<ch-button type="overlay" icon @click="toggleFreePosition">
				<ch-icon icon="fas fa-arrows-alt" :primary-text="freePositionEnabled"/>
			</ch-button>
        </div>
        <div class="planogramName" x-spaced-1>
            <ch-field :validators="[nameValidator]">
                <ch-editable size="narrow" :value="repository.physicalPlanogram.info.name" @input="renamePlanogram"/>
            </ch-field>
            <span class="saveInfo" v-if="saveMessage">{{saveMessage}}</span>
        </div>
		<div class="otherButtons" x-spaced-2>
			<ch-select :items="['Front', 'Top', 'Left', 'Right', 'Back']" :value="cameraView"
					   @input="changeCameraView" type="overlay"/>
			<ch-button type="overlay" icon @click="toggle3DCamera">
				<span :primary-text="camera3D">3D</span>
			</ch-button>
			<ch-overlay-panel
				title=""
				type="overlay"
				has-main-trigger-slot
				:main-trigger-button-props="{ type: 'overlay', icon: true }"
				:class="performanceOverlayPanelClasses"
			>
				<template slot="mainTrigger">
					<!-- TODO: ask designers for the right icon -->
					<ch-icon icon="insert_chart_outlined" />
				</template>
				<template slot="content">
					<planogram-performance
						:data-set-name="dataSetName"
						:repository="repository"
						:key="$store.state.planogramPerformance.isHighlight"
						@change-dataset="$emit('change-dataset')"
					/>
				</template>
			</ch-overlay-panel>
		</div>
		<div class="dialogs">
			<ch-input-dialog ref="inputDialog"/>
			<ch-modal ref="modal"/>
		</div>
    </div>
</template>

<script>
import ExportPhysicalPlanogramDialog from './dialog/ExportPhysicalPlanogramDialog';
import {PhysicalPlanogramService}    from '../services/PhysicalPlanogramService';
import Repository                    from '../services/Repository';
import B64ToBlob                     from '@/utils/ImageUtils';
import PlanogramPerformance			 from '@/spaceallocation/physicalplanogrameditor/ui/planogramperformance/PlanogramPerformance';

    export default {
        name: 'ActionBar',
		components: {
			ExportPhysicalPlanogramDialog,
			PlanogramPerformance,
		},
        props: {
            repository: Repository,
            saveEnabled: Boolean
        },
        data() {
            return {
            	camera3D: false,
                cameraView: 'Front',
                couldUndo: false,
                couldRedo: false,
				snapEnabled: true,
				freePositionEnabled: false,
                saveMessage: null
            };
        },
        computed: {
			performanceOverlayPanelClasses() {
				return {
					highlight: this.isHighlight,
				};
			},
            voices() {
                return [
                    {
                        text: 'Save (ctrl + s)',
                        icon: 'save',
                        disabled: !this.saveEnabled,
                        action: () => this.save()
                    },
                    {
                        text: 'Empty',
                        icon: 'delete',
                        disabled: this.cameraView === 'Top',
                        action: () => this.emptyPlanogram()
                    }
                ];
            },
			dataSetName() {
				return (
					(this.repository &&
						this.repository.currentMarketDataPool &&
						this.repository.currentMarketDataPool.nonEmpty() &&
						this.repository.currentMarketDataPool.get().info &&
						this.repository.currentMarketDataPool.get().info.name) ||
					'Choose a dataset'
				);
			}
        },
        watch: {
            'repository.planogram.name'() {
                document.title = `${this.repository.physicalPlanogram.info.name} | ShelfZone Studio`;
            }
        },
        mounted() {
            document.title = 'Planogram editor | ShelfZone Studio';
            this.$unityManager.Subscribe('HistoryChanged', this.historyChanged);
            this.$unityManager.Subscribe('CameraViewChanged', this.cameraViewChanged);
			this.$unityManager.planogramAPI.SubscribeToThumbnailsTaken(this.thumbnailsTaken);
            window.addEventListener('keydown', this.onKeyDown);
        },
        beforeDestroy() {
            this.$unityManager.Unsubscribe('HistoryChanged', this.historyChanged);
            this.$unityManager.Unsubscribe('CameraViewChanged', this.cameraViewChanged);
            window.removeEventListener('keydown', this.onKeyDown);
        },
        methods: {
            /**
             * @param name
             * @return {string|null}
             */
            nameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,50}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            goBack() {
                if (this.cameraView === 'Front')
                    this.$router.go(-1);
                else
                    this.$unityManager.Dispatch('ChangeCameraView', {view: 'Front'});
            },
            /**
             * @param {KeyboardEvent} event
             */
            onKeyDown(event) {
                if (event.code === 'KeyS' && event.ctrlKey) {
                    this.save();
                    event.preventDefault();
                }
            },
            /**
             * @param {string} name
             */
            renamePlanogram(name) {
                this.$unityManager.planogramAPI.RenamePlanogram(name);
            },
            emptyPlanogram() {
                this.$refs.modal.open(
                    'Empty Planogram',
                    'All placed products will be removed from the shelf',
                    'primary'
                ).then(() => {
                    const bucketIds = PhysicalPlanogramService.getAllBuckets(this.repository.physicalPlanogram).map(bucket => bucket.id);
                    this.$unityManager.planogramAPI.DeleteElements(bucketIds);
                });
            },
            save() {
                if (!this.saveEnabled) return;
                this.$unityManager.lastSave = null;
                this.saveMessage = 'Saving...';
				this.$unityManager.planogramAPI.TakeScreenshots();
            },
			/**
			 * @param rawThumbnails {{front: string, back: string, left: string, right: string, top: string, bottom: string}}
			 */
			thumbnailsTaken(rawThumbnails) {
				const thumbnails = JSON.parse(rawThumbnails);
				const physicalPlanogramId = this.repository.physicalPlanogram.id;
				this.$physicalPlanogram
					.updatePhysicalPlanogram(physicalPlanogramId, this.repository.physicalPlanogram)
					.then(() => this.saveMessage = 'Taking screenshots...')
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'front', B64ToBlob(thumbnails.front, 'image/png')))
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'back', B64ToBlob(thumbnails.back, 'image/png')))
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'left', B64ToBlob(thumbnails.left, 'image/png')))
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'right', B64ToBlob(thumbnails.right, 'image/png')))
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'top', B64ToBlob(thumbnails.top, 'image/png')))
					.then(() => this.$physicalPlanogram.storePhysicalPlanogramThumbnail(physicalPlanogramId, 'bottom', B64ToBlob(thumbnails.bottom, 'image/png')))
					.then(() => {
						const time = new Date();
						this.saveMessage = 'Saved at ' + time.getHours() + ':' + String(time.getMinutes()).padStart(2, '0	');
						window.onbeforeunload = null;
						this.$emit('save');
					});
			},
            exportPlanogram() {
                this.$refs.exportPlanogramDialog.open(this.repository.physicalPlanogram);
            },
            undo() {
                this.$unityManager.planogramAPI.Undo();
            },
            redo() {
				this.$unityManager.planogramAPI.Redo();
            },
			toggleSnap() {
				this.snapEnabled = !this.snapEnabled;
				this.$unityManager.planogramAPI.ToggleSnap(this.snapEnabled);
			},
			toggleFreePosition() {
				this.freePositionEnabled = !this.freePositionEnabled;
				this.$unityManager.planogramAPI.ToggleFreePosition(this.freePositionEnabled);
			},
			toggle3DCamera() {
				this.camera3D = !this.camera3D;
				this.$unityManager.planogramAPI.ChangeCameraMode(!this.camera3D);
			},
			/**
			 * @param {string} view
			 */
			changeCameraView(view) {
            	this.cameraView = view;
            	this.$unityManager.planogramAPI.ChangeCameraOrthographicType(this.cameraView);
			},
            /**
             * @param {{view: string}} event
             */
            cameraViewChanged(event) {
                this.cameraView = event.view;
            },
            /**
             * @param {{currentSnapshot: number, totalSnapshots: number}} event
             */
            historyChanged(event) {
            	const json = JSON.parse(event);
                this.couldUndo = json.currentSnapshot > 0;
                this.couldRedo = json.currentSnapshot < json.totalSnapshots - 1;
            },

        }
    };
</script>

<style scoped>

    .actionBar {
        padding: var(--doubleMargin);
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        z-index: 10;
        pointer-events: none;
    }

    .actionBar > * {
        flex: 1 1 0;
    }

    .actionBar .buttons > * {
        pointer-events: all;
    }

	.actionBar .dialogs {
		position: fixed;
		pointer-events: all;
	}

    .actionBar > .planogramName {
        pointer-events: all;
        flex: 0 0 auto;
        color: var(--on-overlay);
        background-color: var(--overlay);
        border: var(--overlay-border);
        border-radius: 4px;
        padding: 2px var(--singleMargin);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .saveInfo {
        color: var(--elevation-05);
    }

    .actionBar > .otherButtons {
        display: flex;
        justify-content: flex-end;
    }

    .actionBar > .otherButtons > * {
        pointer-events: all;
    }

    .contextButton{
        margin-right: 8px;
    }

::v-deep .overlayPanel.highlight button.mainTrigger.overlay {
  border: 1.4px solid var(--primary);
  margin-left: 12px;
}
</style>
