<template>
  <svg
    class="performance-histogram"
    :id="histogramId"
    :width="width"
    :height="height"
    :delta="delta"
    :isNegative="isDeltaNegative"
  >
    <!-- background -->
    <rect :width="width" :height="height" :fill="backgroundColor" />
    <!-- histogram -->
    <line
      v-if="rightValue || leftValue"
      :x1="xOne"
      :x2="xTwo"
      :y1="height / 2"
      :y2="height / 2"
      :stroke="histogramColor"
      :stroke-width="histogramWidth"
      :stroke-linecap="histogramLinecap"
      :containerLength="histogramMaxLength"
    />
    <!-- vertical divider -->
    <line
      stroke-width="1"
      y1="0"
      :y2="height"
      :x1="width / 2"
      :x2="width / 2"
      :stroke="dividerColor"
      :stroke-linecap="dividerLinecap"
    />
  </svg>
</template>

<script>
export default {
  name: 'PerformanceHistogram',
  props: {
    /*
     * The leftValue for the histogram
     */
    leftValue: {
      type: Number,
      default: 0,
    },
    /*
     * The rightValue for the histogram
     */
    rightValue: {
      type: Number,
      default: 0,
    },
    /*
     * Sets the svg width in pixels
     */
    width: {
      type: Number,
      default: 180,
    },
    /*
     * Sets the svg height in pixels
     */
    height: {
      type: Number,
      default: 15,
    },
    /*
     * Sets the background color
     */
    backgroundColor: {
      type: String,
      default: '#2A333A',
    },
    /*
     * Sets the histogram default color
     */
    histogramColor: {
      type: String,
      default: 'rgb(255, 255, 255)',
    },
    /*
     * Sets the full scale percentage
     */
    fullScalePercentage: {
      type: Number,
      default: 0.3,
    },
    /*
     * Sets the histogram width
     */
    histogramWidth: {
      type: Number,
      default: 6,
    },
    /*
     * Sets the histogram stroke-linecap
     */
    histogramLinecap: {
      type: String,
      default: 'square',
    },
    /*
     * Sets the divider color
     */
    dividerColor: {
      type: String,
      default: '#AAB1B5',
    },
    /*
     * Sets the divider stroke-linecap
     */
    dividerLinecap: {
      type: String,
      default: 'square',
    },
    /*
     * Sets the component id
     */
    histogramId: {
      type: String,
      required: true,
    },
  },
  computed: {
    delta() {
      return this.rightValue - this.leftValue;
    },
    normalizedRange() {
      return Math.max(-1, Math.min(1, this.delta / this.fullScalePercentage));
    },
    isDeltaNegative() {
      return this.delta < 0;
    },
    histogramMaxLength() {
      return this.width / 2;
    },
    xOne() {
      if (this.isDeltaNegative)
        return (
          this.histogramMaxLength +
          this.normalizedRange * this.histogramMaxLength
        );

      return this.histogramMaxLength + 3;
    },
    xTwo() {
      if (!this.delta || !this.isDeltaNegative)
        return (
          this.histogramMaxLength +
          this.normalizedRange * this.histogramMaxLength
        );

      return this.histogramMaxLength - 3;
    },
  },
};
</script>
