<template>
  <div class="market-data-layout">
    <div class="layout-header">
      <ch-icon icon="arrow_back" @click="goBack()" />
      <span class="layout-title">{{ contentTitle }}</span>
      <label-tag label="BETA" />
    </div>
    <div class="layout-divider" />
    <div class="layout-content">
      <new-data-set
        v-if="isNewDataSet"
        :physical-planogram="physicalPlanogram"
        @new-market-data-pool="newDatasetInserted"
        @cancel-new-dataset="goBack"
      />
      <market-data-sets
        v-else
        :data-sets="dataSets"
        :planogram-id="planogramId"
        @add-data-set="insertNewDataSet"
        @market-data-pool-deleted="removeMarketDataPool"
      />
    </div>
  </div>
</template>

<script>
import MarketDataSets from './MarketDataSets.vue';
import NewDataSet from './NewDataSet.vue';
import Repository from '@/spaceallocation/physicalplanogrameditor/services/Repository';
import LabelTag from '../LabelTag.vue';
import Vue from 'vue';
import { removeSelectedMarketDataPool } from '@/spaceallocation/planogrameditor/services/MarketDataPoolStorageService';

export default {
  name: 'MarketDataLayout',
  components: {
    NewDataSet,
    MarketDataSets,
    LabelTag,
  },
  props: {
    /*
     * Sets the repository
     */
    repository: {
      type: Repository,
      default: () => {},
    },
  },
  data() {
    return {
      contentTitle: 'Market data',
      isNewDataSet: false,
    };
  },
  computed: {
    planogramId() {
      return this.repository.physicalPlanogram.id;
    },
    physicalPlanogram() {
      return this.repository.physicalPlanogram;
    },
    dataSets() {
      return this.repository.listMarketDataPoolsSummary.map(summary => {
        return {
          id: summary.id,
          ...summary.info,
        };
      });
    },
  },
  methods: {
    insertNewDataSet() {
      this.contentTitle = 'Create new dataset';
      this.isNewDataSet = true;
    },
    newDatasetInserted(marketDataPool) {
      this.contentTitle = 'Market data';
      this.isNewDataSet = false;

      this.$emit('market-data-pool-added', marketDataPool);
    },
    removeMarketDataPool(id) {
      const currentMarketDataPoolId =
        this.repository.currentMarketDataPool.get().id;

      this.repository.removeMarketDataPool(id);

      if (this.repository.listMarketDataPoolsSummary.length) {
        const targetDataPoolSummary =
          this.repository.listMarketDataPoolsSummary.find(
            summary => summary.id === currentMarketDataPoolId
          );
        const targetId = targetDataPoolSummary
          ? targetDataPoolSummary.id
          : this.repository.listMarketDataPoolsSummary[0].id;
        const targetInfo = targetDataPoolSummary
          ? targetDataPoolSummary.info
          : this.repository.listMarketDataPoolsSummary[0].info;

        Vue.$studio.findMarketDataPoolImportedData(targetId).then(res => {
          const marketDataPool = {
            id: targetId,
            info: targetInfo,
            importedData: res.importedData,
          };
          this.repository.setCurrentMarketDataPool(marketDataPool);
        });
      } else {
        this.repository.unsetCurrentMarketDataPool();
        removeSelectedMarketDataPool(this.planogramId);
      }
    },
    goBack() {
      this.contentTitle = 'Market data';
      this.isNewDataSet = false;
      this.$emit('go-back');
    },
  },
};
</script>

<style scoped>
.market-data-layout {
  width: 100%;
  height: calc(100vh - 64px);
  background-color: var(--elevation-02);
}

.layout-header {
  display: flex;
  align-items: center;
  padding: 16px;
  color: white;
}

.layout-title {
  margin-left: 16px;
  margin-right: 24px;
  font-size: 22px;
}

.layout-divider {
  width: 100%;
  height: 2px;
  border-bottom: 1px solid var(--elevation-05);
}

.layout-content {
  width: calc(100% - 100px);
  height: 100%;
  padding-left: 60px;
  padding-right: 40px;
}
</style>
