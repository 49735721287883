<template>
    <ch-application-layout editor>
        <left-side-bar slot="navigation" :controller="controller" :selection="selection"/>
        <div slot="body" fill>
            <action-bar v-if="controller" :controller="controller"
                        @saveEnabled="enabled => this.hasUnsavedChanges = enabled"/>
            <div class="unityFrame" ref="unityFrame" fill></div>
            <ch-modal ref="modal"/>
	        <login-dialog ref="loginDialog"/>
            <ch-loader v-if="isLoading" fullscreen/>
        </div>
        <right-side-bar slot="sidebar" ref="RightSideBarElement" :controller="controller" :selection="selection"/>
    </ch-application-layout>
</template>

<script>
    import LeftSideBar         from './leftsidebar/LeftSidebar';
    import RightSideBar        from './rightsidebar/RightSideBar';
    import StudioRequest       from '../../plugins/studioapi/requests/StudioRequest';
    import ActionBar           from './ActionBar';
    import {AssetService}      from './services/AssetService';
    import LayoutController    from './services/LayoutController';
    import SelectionController from './services/SelectionController';
    import LayoutService       from './services/LayoutService';
    import Login from '@/login/Login';
    import LoginDialog from '@/login/LoginDialog';

    export default {
        name: 'LayoutEditor',
        components: {
	        LoginDialog,
	        Login,
            ActionBar,
            LeftSideBar,
            RightSideBar
        },
        props: {
            configurationId: String,
            singleSelection: {default: true, type: Boolean}
        },
        data() {
            return {
                controller: null,
                selection: null,
                hasUnsavedChanges: false,
                isLoading: false
            };
        },
        mounted() {
            document.title = 'Layout | ShelfZone Studio';
            window.addEventListener('resize', this.onResize);
            if (this.configurationId) {
                this.isLoading = true;
                this.loadConfiguration(this.configurationId);
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
            window.onbeforeunload = null;
        },
        beforeRouteUpdate(to, from, next) {
            const loadConfiguration = () => {
                this.loadConfiguration(to.params.configurationId)
                    .finally(() => next()).catch((err) => next(err));
            };
            if (this.hasUnsavedChanges) {
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
                if (answer)
                    loadConfiguration();
                else
                    next(false);
            } else
                loadConfiguration();
        },
        beforeRouteLeave(to, from, next) {
            const proceed = () => Promise.all([
              this.$unityManager.layoutAPI.UnloadLayout(),
              this.$unityManager.UnloadScene()
            ]).then(() => next()).catch((err) => next(err));
            if (this.hasUnsavedChanges)
              if (to.name === 'login')
                this.$refs.loginDialog.open().catch(proceed);
              else
                this.$refs.modal.open('Unsaved changes', 'Do you really want to leave? You have unsaved changes!', 'primary')
                    .then(proceed);
            else
              proceed();
        },
        methods: {
            loadConfiguration(configurationId) {
                this.hasUnsavedChanges = false;
                window.onbeforeunload = null;
                return Promise.all([
                    this.$unityManager.LoadLayoutEditor(this.$refs.unityFrame),
                    this.$studio.loadConfiguration(configurationId)
                ]).then(([, loadConfigurationResponse]) => {
                    this.$unityManager.canvas.tabIndex = 1;
                    window.onbeforeunload = () => this.hasUnsavedChanges ? 'Are you sure?' : null;
                    let configuration = loadConfigurationResponse.configuration;
                    const planograms = loadConfigurationResponse.planograms;
					const physicalPlanograms = loadConfigurationResponse.physicalPlanograms;
                    const placeholders = loadConfigurationResponse.placeholders;
                    AssetService.EnrichEnvironment(configuration.environment, loadConfigurationResponse.environment);
                    AssetService.EnrichAssets(configuration.library, planograms, physicalPlanograms, placeholders);
                    configuration = LayoutService.ensureSingleStartingPoint(configuration, loadConfigurationResponse.environment);
                    this.controller = new LayoutController(
                        configuration,
                        loadConfigurationResponse.environment,
                        planograms,
						physicalPlanograms,
                        placeholders,
                        loadConfigurationResponse.props,
                        this.$unityManager
                    );
                    this.selection = new SelectionController(this.$unityManager, this.controller);
                    this.$unityManager.layoutAPI.LoadLayout(configuration);
                })
					.catch(err => this.$router.replace({name: 'pageNotFound'}))
					.finally(() => this.isLoading = false);
            },
            onResize() {
                const canvas = document.getElementById('#canvas');
                canvas.style.width = this.$refs.unityFrame.offsetWidth + 'px';
                canvas.style.height = this.$refs.unityFrame.offsetHeight + 'px';
            }
        }
    };
</script>

<style scoped>


    .unityFrame {
        outline: none;
    }

</style>


