<template>
	<ch-application-layout>
		<div class="header" slot="header" fill>
			<ch-filters>
				<div x-spaced-1>
					<ch-icon v-if="query.view === 'grid'" @click="query.view = 'table'" icon="list" size="18"/>
					<ch-icon v-else @click="query.view = 'grid'" icon="view_module" size="18"/>
					<label>Items per page</label>
					<ch-select size="narrow small" :items="[10, 25, 50]" v-model="query.limit"/>
					<label>{{ matchedProps.length }} assets</label>
				</div>
				<div>
					<ch-pagination :value="query.page" @input="goToPage" :max="totalPages"/>
				</div>
				<div>
					<ch-radio-group v-model="query.deprecated" size="narrow small" type="primary">
						<ch-radio-button :label="false">Current</ch-radio-button>
						<ch-radio-button :label="true">Deprecated</ch-radio-button>
					</ch-radio-group>
				</div>
			</ch-filters>
			<ch-filters>
				<div></div>
				<div>
					<ch-search-bar v-model="query.matching" size="narrow"/>
				</div>
			</ch-filters>
		</div>
		<template slot="main">
			<div v-if="loading" fill-center>Loading assets, please wait...</div>
			<ch-empty-content v-else-if="propsInPage.length === 0" firstMessage="No asset found"/>
			<ch-table v-if="query.view === 'table'" :items="propsInPage" class="propsTable"
					  @rowClicked="openPropDetailsDialog">
				<template slot="header">
					<ch-th name="Asset" defaultOrder="ascending" sortable id="name"/>
					<ch-th name="Dimensions (cm)"/>
					<ch-th name="Type"/>
				</template>
				<template slot-scope="{ row }">
					<ch-td>{{ row.name }}</ch-td>
					<ch-td>{{ row.size | formatSize }}</ch-td>
					<ch-td>{{ row.archetype.type | formatArchetypeType }}</ch-td>
				</template>
			</ch-table>
			<ch-grid-layout v-else-if="query.view === 'grid'" :width="180" :height="240" :gap="32" class="propsGrid">
				<prop-card v-for="prop in propsInPage" :key="prop.id" :prop="prop"
						   @click.native="openPropDetailsDialog(prop)"/>
			</ch-grid-layout>
			<prop-details-dialog ref="propDetailsDialog" :props="props"/>
		</template>
	</ch-application-layout>
</template>

<script>

import {ArchetypeType}   from '@/plugins/studioapi/api/propstore/model/ArchetypeType';
import PropCard          from '@/content/assets/components/PropCard';
import PropDetailsDialog     from '@/content/assets/PropDetailsDialog';
import {formatArchetypeType} from '@/content/assets/Utils';

export default {
	name: 'AssetsCatalog',
	components: {PropDetailsDialog, PropCard},
	props: {
		query: {
			type: Object,
			default: () => ({
				limit: 50,
				page: 0,
				matching: 'hello',
				deprecated: false,
				type: null,
				view: 'table'
			})
		}
	},
	data() {
		return {
			props: [],
			loading: true
		};
	},
	computed: {
		totalPages() {
			return Math.max(Math.ceil(this.matchedProps.length / this.query.limit), 1);
		},
		propsInPage() {
			const offset = this.query.page * this.query.limit;
			return this.matchedProps.slice(offset, offset + this.query.limit);
		},
		matchedProps() {
			return this.props
				.filter(p => !this.query.matching || p.name.match(new RegExp(this.query.matching, 'i')))
				.filter(p => p.deprecated === this.query.deprecated)
				.filter(p => this.query.type ? p.archetype.type === this.query.type : true);
		}
	},
	watch: {
		'query.limit'() {
			this.query.page = Math.min(this.totalPages - 1, this.query.page);
		},
		'query.type'() {
			this.query.page = 0;
		},
		'query.deprecated'() {
			this.query.page = 0;
		},
		'query.matching'() {
			this.query.page = 0;
		}
	},
	mounted() {
		this.$propStore.listProps()
			.then(response => {
				this.props = response.props;
				this.loading = false;
			});
	},
	filters: {
		/**
		 * @param {Volume} size
		 * @return {string}
		 */
		formatSize(size) {
			const width = Math.trunc(size.w) / 10;
			const height = Math.trunc(size.h) / 10;
			const depth = Math.trunc(size.d) / 10;

			return height + ' x ' + width + ' x ' + depth;
		},
		/**
		 * @param {ArchetypeType} archetypeType
		 * @return {string}
		 */
		formatArchetypeType(archetypeType) { return formatArchetypeType(archetypeType); }
	},
	methods: {
		goToPage(page) {
			if (!this.loading)
				this.query.page = page;
		},
		openPropDetailsDialog(prop) {
			this.$refs.propDetailsDialog.open(prop);
		}
	}
};
</script>

<style scoped>

.header {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
}

.propsTable {
	box-sizing: border-box;
	padding: 0 var(--quadrupleMargin) var(--quadrupleMargin) var(--quadrupleMargin);
}

.propsGrid {
	box-sizing: border-box;
	padding: var(--quadrupleMargin);
}

</style>
