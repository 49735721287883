<template>
  <accordion title="Anagraphics">
    <ch-dl label-width="50%">
      <ch-dd label="Format">
        {{ (product.info.format || 'Not available').capitalize() }}
      </ch-dd>
      <ch-dd label="Manufacturer">
        {{ (product.info.producer || 'Not available').capitalize() }}
      </ch-dd>
      <ch-dd label="Brand">
        {{ (product.info.brand || 'Not available').capitalize() }}
      </ch-dd>
      <ch-dd
        v-for="(level, index) of category"
        :key="index"
        :label="level.label"
        >{{ (level.value || 'Not available').capitalize() }}
      </ch-dd>
      <ch-dd label="Catalog Dimensions (cm)">
        <size
          :depth="originalSize.d"
          :height="originalSize.h"
          :width="originalSize.w"
        />
      </ch-dd>
    </ch-dl>
  </accordion>
</template>

<script>
import Repository from '../../../services/Repository';
import ProductDetails from '../../../../../skucatalog/ProductDetails';
import { ProductService } from '@/spaceallocation/physicalplanogrameditor/services/ProductService';
import { CategorizationService } from '@/skucatalog/services/CategorizationService';
import ProductAsset from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/product/ProductAsset';
import Size from '@/components/Size';
import Accordion from '@/components/Accordion';

export default {
  name: 'ProductDetailedInfo',
  components: {
    Accordion,
    Size,
    ProductDetails,
  },
  props: {
    repository: Repository,
    product: ProductAsset | Object,
  },
  computed: {
    /**
     * @return {CategorizationService.LabelledCategory[]}
     */
    category() {
      return CategorizationService.ExtractCategory(
        this.repository.categorization,
        this.product.categories.map(
          ProductService.translateCategoryToProductBank
        )
      );
    },
    /**
     * @return {Volume}
     */
    originalSize() {
      return this.product.originalSize;
    },
  },
};
</script>

<style scoped>
.voice {
  padding: var(--doubleMargin);
}
</style>
