<template>
  <div class="bucketInfo" fill>
    <div class="bucketDescription" y-spaced-2>
      <product-info
        v-if="product.nonEmpty()"
        :product="product.get()"
        :repository="repository"
      >
        <ch-button
          slot="titleAction"
          justified
          size="narrow"
          type="primary"
          @click="updateProduct"
          >Update
          <ch-icon icon="sync" />
        </ch-button>
      </product-info>
      <stock-info v-if="stockInfo.nonEmpty()" :stockInfo="stockInfo.get()" />
      <market-data-score-card
        :data-set-name="dataSetName"
        :economics="economics"
        :kpis="kpis"
        :records="records"
        @change-dataset="$emit('change-dataset')"
      />
    </div>
    <bucket-actions
      v-if="bucket.nonEmpty()"
      :bucket="bucket.get()"
      :cameraView="cameraView"
      :repository="repository"
      :selectionManager="selectionManager"
      @createGroup="$emit('createGroup')"
    />
    <ch-modal ref="modal" />
  </div>
</template>
<script>
import { PlanogramService } from '../../../services/PlanogramService';
import BucketActions from './BucketActions';
import ProductInfo from '../product/ProductInfo';
import StockInfo from './StockInfo';
import Repository from '../../../services/Repository';
import SelectionManager from '../../../managers/SelectionManager';
import { ProductService } from '../../../services/ProductService';
import MarketDataScoreCard from './MarketDataScoreCard.vue';
import KPI from '../../../../utils/KPI/index';
import { storeSelectedMarketDataPool } from '@/spaceallocation/planogrameditor/services/MarketDataPoolStorageService';
import { kpiImportOptions } from '@/spaceallocation/utils/KPI/kpiImportOptions';
import { defaultDaysValueLists } from '../../../../utils/KPI/config';
import { productsKpis } from '@/spaceallocation/utils/KPI/config';
import Mathematics from '@/spaceallocation/utils/KPI/helpers/mathematics';

import {
  retrieveWeekDaysValueFromKpi,
  retrieveMonthDaysValueFromKpi,
} from '@/spaceallocation/utils/KPI/helpers/chrono';

const { productKpiCalc } = Mathematics;

export default {
  name: 'BucketInfo',
  components: {
    StockInfo,
    ProductInfo,
    BucketActions,
    MarketDataScoreCard,
  },
  props: {
    repository: Repository,
    selectionManager: SelectionManager,
    bucketId: String,
    cameraView: String,
  },
  computed: {
    importedData() {
      return this.repository.currentMarketDataPool.nonEmpty()
        ? this.repository.currentMarketDataPool.get().importedData
        : {};
    },
    dataSetName() {
      if (this.repository.currentMarketDataPool.nonEmpty()) {
        return this.repository.currentMarketDataPool.get().info.name;
      }
      return 'Choose a dataset';
    },
    weekDays() {
      return (
        this.retrieveDaysNumericalValue('weekly') ||
        defaultDaysValueLists.weekly.value
      );
    },
    monthDays() {
      return (
        this.retrieveDaysNumericalValue('monthly') ||
        defaultDaysValueLists.monthly.value
      );
    },
    kpis() {
      const kpis = [];
      if (
        this.repository.currentMarketDataPool.nonEmpty() &&
        this.repository.currentMarketDataPool.get().importedData &&
        this.product.get()
      ) {
        const importedDataAndRepository = [
          this.repository.currentMarketDataPool.get().importedData,
          this.product.get(),
          this.repository,
        ];
        const additionalArgs = {
          [KPI.common.keys.unitShareInUnits]: [false],
          [KPI.common.keys.unitShareInPercent]: [true],
          [KPI.common.keys.dailyAverageSalesRate]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateFiveDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateSixDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateSevenDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.monthlyAverageSalesRate]: [
            this.weekDays,
            this.monthDays,
          ],
        };
        productsKpis.forEach(kpiKey => {
          const result = productKpiCalc(
            kpiKey,
            importedDataAndRepository,
            additionalArgs
          );
          result && kpis.push(result);
        });
      } else {
        kpis.push(
          {
            label: KPI.common.labels.categoryValueShare,
            value: null,
          },
          {
            label: KPI.common.labels.categoryUnitShare,
            value: null,
          },
          {
            label: KPI.common.labels.valueMargin,
            value: null,
          },
          {
            label: KPI.common.labels.categoryMarginShare,
            value: null,
          },
          {
            label: KPI.common.labels.percOfStoresSelling,
            value: null,
          },
          {
            label: KPI.common.labels.weightedDistribution,
            value: null,
          },
          {
            label: KPI.common.labels.volumeSalesPerPtWdDistribution,
            value: null,
          }
        );
      }
      return kpis;
    },
    economics() {
      if (this.repository.currentMarketDataPool.isEmpty()) return [];
      const productMarketData = this.repository.currentMarketDataPool
        .get()
        .importedData.body.find(row => row['ean'] === this.product.get().ean);
      if (!productMarketData) return [];
      const importOptions = kpiImportOptions
        .filter(item => item.taxonomy === 'economics')
        .map(option => option.key);
      const economicsFromKpi = this.kpis.filter(kpi =>
        importOptions.includes(kpi.key)
      );
      const economics = this.repository.currentMarketDataPool
        .get()
        .importedData.header.filter(
          column =>
            !economicsFromKpi.map(kpi => kpi.key).includes(column.key) &&
            column.taxonomy === 'economics'
        )
        .map(column => {
          return (
            productMarketData && {
              label: column.label,
              value: productMarketData[column.key],
              key: column.key,
            }
          );
        });
      return economics
        .map(economic => {
          const containsKpisLabels = this.kpis
            .map(kpi => kpi.key)
            .includes(economic.key);
          if (!containsKpisLabels) {
            return economic;
          }
        })
        .filter(economic => economic);
    },
    records() {
      if (this.repository.currentMarketDataPool.isEmpty()) return [];
      const productMarketData = this.repository.currentMarketDataPool
        .get()
        .importedData.body.find(row => row['ean'] === this.product.get().ean);
      if (!productMarketData) return [];
      return this.repository.currentMarketDataPool
        .get()
        .importedData.header.filter(column => column.type === 'record')
        .map(column => {
          return (
            productMarketData && {
              label: column.label,
              value: productMarketData[column.key],
            }
          );
        });
    },
    /**
     * @return {Option<StockInfo>}
     */
    stockInfo() {
      return PlanogramService.computeStockInfo(this.repository, this.bucketId);
    },
    /**
     * @return {Option<Bucket>}
     */
    bucket() {
      return this.stockInfo.map(info => info.bucket);
    },
    /**
     * @return {Option<Product>}
     */
    product() {
      return this.stockInfo.map(info => info.product);
    },
  },
  methods: {
    retrieveDaysNumericalValue(daysType) {
      const header = this.importedData && this.importedData.header;
      const kpiKey =
        header &&
        header.find(kpi => {
          if (daysType === 'weekly') return this.isWeeklyDayKey(kpi);
          if (daysType === 'monthly') return this.isMonthlyDayKey(kpi);
        });
      const retrieveDayValue = () => {
        if (daysType === 'weekly')
          return retrieveWeekDaysValueFromKpi(kpiKey && kpiKey.key);
        if (daysType === 'monthly')
          return retrieveMonthDaysValueFromKpi(kpiKey && kpiKey.key);
      };
      return kpiKey ? retrieveDayValue() : undefined;
    },
    isWeeklyDayKey(kpi) {
      switch (kpi.key) {
        case KPI.common.keys.weeklyAverageSalesRateFiveDays:
        case KPI.common.keys.weeklyAverageSalesRateSixDays:
        case KPI.common.keys.weeklyAverageSalesRateSevenDays:
          return true;
        default:
          return false;
      }
    },
    isMonthlyDayKey(kpi) {
      switch (kpi.key) {
        case KPI.common.keys.monthlyAverageSalesRate:
          return true;
        default:
          return false;
      }
    },
    updateProduct() {
      this.$refs.modal
        .open(
          'Update product',
          'Update the product could change its size and facing, planogram could then become invalid, please control the result.',
          'alert'
        )
        .then(() => {
          this.$productBank
            .findProductById(this.bucket.get().productId, false)
            .then(response => {
              this.repository.addAdditionalProductInfo(response.product);
              const product = ProductService.translateProductFromProductBank(
                response.product
              );
              this.$unityManager.Dispatch('AddProducts', {
                products: [product],
              });
            })
            .catch(() =>
              this.$snotify.error(`Error updating product`, {
                timeout: 5000,
                showProgressBar: false,
              })
            );
        })
        .catch(() => console.log('Product updated failed'));
    },
  },
};
</script>
<style scoped>
.bucketInfo {
  display: flex;
  flex-direction: column;
}

.bucketDescription {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid var(--elevation-04);
  box-sizing: border-box;
  padding: var(--doubleMargin);
}

.bucketDescription > *:not(:last-child) {
  border-bottom: 1px solid var(--elevation-04);
}

.bucketDescription > *:not(:last-child) {
  padding-bottom: var(--doubleMargin);
}
</style>
