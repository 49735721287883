<template>
  <div class="productsLibrary">
    <div class="filters" y-spaced-2>
      <div class="actions" x-spaced-2>
        <ch-button
          justified
          size="fill narrow"
          type="secondary"
          @click="$refs.productFinder.open()"
          >Add products
          <ch-icon icon="fas fa-plus-circle" size="12" />
        </ch-button>
      </div>
      <ch-button
        class="marketDataBtnLabel"
        justified
        size="fill narrow"
        type="secondary"
        @click="$emit('market-data-clicked')"
      >
        <label-tag label="BETA" size="small" />
        <span>Marked data</span>
        <ch-icon icon="far fa-chart-bar" />
      </ch-button>
      <div>
        <ch-search-bar v-model="searchValue" realTime size="fill narrow" />
      </div>
      <div>
        <div v-if="!searchValue">
          {{ products.length }} products ({{ usedProducts.size }} used)
        </div>
        <div v-else>
          {{ filteredProducts.length }} products found on
          {{ products.length }} total ({{ usedProducts.size }} used)
        </div>
      </div>
      <ch-radio-group v-model="productFilter" size="narrow fill" type="primary">
        <ch-radio-button :label="0">All</ch-radio-button>
        <ch-radio-button :label="1">Used</ch-radio-button>
        <ch-radio-button :label="2">Not used</ch-radio-button>
      </ch-radio-group>
    </div>
    <div class="products">
      <ch-grid-layout
        v-if="filteredProducts.length"
        :gap="16"
        :height="160"
        :width="130"
      >
        <div
          v-for="(product, index) in filteredProducts"
          :id="product.id"
          :key="product.id + index"
          class="product"
          draggable="true"
          @dragstart="onDragStart(product, $event)"
          @mouseenter="onProductHovered(product.id)"
          @mouseleave="onProductLeave(product.id)"
        >
          <div
            :title="product.name"
            :used="usedProducts.has(product.id)"
            class="productImage"
          >
            <ch-image
              v-if="product.productId"
              :id="product.productId + '_img'"
              :src="`${baseURL}/products/${product.productId}/photos/thumbnail_front.png`"
            />
            <div
              v-else
              :style="'background: ' + colorFromId(product.id)"
              class="productNotInCatalog"
            ></div>
          </div>
          <div v-if="!product.productId" class="productLabel" ellipsis>
            {{ product.name.capitalize() }}
          </div>
          <div
            v-else
            class="productLabel productInCatalog"
            ellipsis
            @click="showProductDetails(product)"
          >
            {{ product.name.capitalize() }}
          </div>
        </div>
      </ch-grid-layout>
      <ch-empty-content
        v-else-if="products.length === 0"
        first-message="No products added to the planogram"
      />
      <ch-empty-content
        v-else
        first-message="No products matching the given query"
      />
    </div>
    <product-finder
      ref="productFinder"
      :disabledProducts="products"
      @addProducts="addProducts"
    />
    <product-details ref="productDetails" />
    <ch-button
      :disabled="filteredProducts.length === 0"
      class="downloadProducts"
      fab
      type="primary"
      @click="downloadProducts"
    >
      <ch-icon icon="file_download" />
    </ch-button>
  </div>
</template>

<script>
import ProductDetails from '../../../../skucatalog/ProductDetails';
import ProductFinder from '../../../../skucatalog/ProductFinder';
import SelectionManager from '../../managers/SelectionManager';
import { PhysicalPlanogramService } from '../../services/PhysicalPlanogramService';
import { ProductService } from '../../services/ProductService';
import Repository from '../../services/Repository';
import { CSVConverter } from '../../services/CSVConverter';
import { ColorService } from '../../../layouteditor/services/ColorService';
import { View } from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/View';
import LabelTag from '@/spaceallocation/planogrameditor/ui/LabelTag';

export default {
  name: 'ProductsLibrary',
  components: {
    ProductFinder,
    ProductDetails,
    LabelTag,
  },
  props: {
    repository: Repository,
    selectionManager: SelectionManager,
    cameraView: String,
  },
  data() {
    return {
      searchValue: '',
      productFilter: 0,
      transparentImage: (() => {
        const img = new Image();
        img.src =
          'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        return img;
      })(),
    };
  },
  computed: {
    /**
     * @return {string}
     */
    baseURL() {
      return Config.RESTAddress;
    },
    editor() {
      return this.$parent.$parent.$parent.$parent.$parent;
    },
    /**
     * @return {ProductAsset[]}
     */
    products() {
      return PhysicalPlanogramService.listProductAssets(
        this.repository.physicalPlanogram
      );
    },
    /**
     * @return {Set<string>}
     */
    usedProducts() {
      return new Set(
        PhysicalPlanogramService.getAllBuckets(
          this.repository.physicalPlanogram
        ).map(b => b.productId)
      );
    },
    /**
     * @return {ProductAsset[]}
     */
    filteredProducts() {
      let results = this.products;
      if (this.searchValue) {
        const s = this.searchValue.toLowerCase();
        results = results.filter(
          product =>
            product.name.toLowerCase().includes(s) || product.ean.includes(s)
        );
      }
      if (this.productFilter === 1) {
        results = results.filter(p => this.usedProducts.has(p.id));
      } else if (this.productFilter === 2) {
        results = results.filter(p => !this.usedProducts.has(p.id));
      }
      return results.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
  },
  methods: {
    /**
     * @param {ProductAsset} product
     * @param {DragEvent} event
     */
    onDragStart(product, event) {
      event.dataTransfer.setDragImage(this.transparentImage, 0, 0);
      event.dataTransfer.setData('text/plain', product);
      const view = this.cameraView === 'Front' ? View.Front : View.Bottom;
      this.$unityManager.planogramAPI.StartDragProductAsset(product.id);
      this.$unityManager.canvas.focus();
      event.preventDefault();
      //TODO Remember to use the correct view of the product
      /*Dispatch("DragProduct", {
                      productId: product.id,
                      view,
                      });*/
    },
    /**
     * @param {ProductAsset} product
     */
    showProductDetails(product) {
      this.$productBank
        .findProductById(product.productId)
        .then(r =>
          this.$refs.productDetails.open(
            r.product,
            this.repository.categorization
          )
        );
    },
    /**
     * @param {string} productId
     */
    onProductHovered(productId) {
      const matchedBuckets = PhysicalPlanogramService.findBucketsWithProduct(
        this.repository.physicalPlanogram,
        productId
      ).map(b => b.id);
      this.$unityManager.planogramAPI.HighlightElements(matchedBuckets);
    },
    /**
     * @param {string} productId
     */
    onProductLeave(productId) {
      const matchedBuckets = PhysicalPlanogramService.findBucketsWithProduct(
        this.repository.physicalPlanogram,
        productId
      ).map(b => b.id);
      this.$unityManager.planogramAPI.RemoveElementsHighlight(matchedBuckets);
    },
    /**
     * @param {string[]} productIds
     */
    addProducts(productIds) {
      if (productIds.length === 0) return;
      this.$productBank.findProductsById(productIds, false).then(response => {
        const products = response.foundProducts.map(p =>
          ProductService.translateProductFromProductBank('None', p)
        );
        this.$unityManager.planogramAPI.AddAssets(products);
      });
    },
    /**
     * @param {string} id
     * @return {string}
     */
    colorFromId(id) {
      return ColorService.colorFromString(id).toString();
    },
    downloadProducts() {
      const csv = CSVConverter.convertProducts(
        this.filteredProducts,
        this.repository.categorization
      );
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.toString())
      );
      element.setAttribute(
        'download',
        `${this.repository.physicalPlanogram.info.name.trim()} - Products.csv`
      );
      element.click();
    },
  },
};
</script>

<style scoped>
.productsLibrary {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.productsLibrary > * {
  padding: 0 var(--doubleMargin);
  flex: 0 0 auto;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: var(--doubleMargin);
}

.actions {
  display: flex;
  /* flex-wrap: no-wrap; */
}

.products {
  flex: 1 1 auto;
  padding-top: var(--doubleMargin);
  border-top: 1px solid var(--elevation-06);
  box-sizing: border-box;
  overflow: auto;
}

.product {
  display: flex;
  flex-direction: column;
  background-color: unset !important;
  padding: 0 !important;
  outline: none;
}

.productImage {
  flex: 0 0 auto;
  width: 130px;
  height: 130px;
  background-color: var(--elevation-04);
  border-radius: 4px;
  padding: var(--singleMargin);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage[used] {
  background-color: var(--elevation-05);
}

.productImage:hover {
  background-color: var(--primary);
}

.productNotInCatalog {
  width: 80px;
  height: 80px;
}

.productLabel {
  flex: 0 0 auto;
  font-size: 11px;
  margin-top: var(--singleMargin);
  text-transform: capitalize;
  color: var(--on-elevation-02);
}

.productInCatalog {
  cursor: pointer;
  text-decoration: underline;
}

.productInCatalog:hover {
  color: var(--primary);
}

.downloadProducts {
  bottom: 16px !important;
  right: 16px !important;
}

.marketDataBtnLabel {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 136px;
}
</style>
