<template>
    <ch-form-dialog ref="dialog" :title="title" type="primary" @submit="issueBuild" @reset="reset" :class="{ researchColors: hasField }">
        <template v-if="visible">
            <div v-html="description"/>
            <ch-field>
                <ch-checkbox v-model="preview">Build with preview quality</ch-checkbox>
            </ch-field>
            <ch-field>
                <ch-checkbox v-model="legacyBuild">Legacy version (for VR research)</ch-checkbox>
            </ch-field>
        </template>
    </ch-form-dialog>
</template>

<script>
    export default {
        name: 'CreatePackage',
        data() {
            return {
                project: null,
                update: false,
                preview: false,
                legacyBuild: false,
                target: 'default',
                allowedTargets: [{
                    key: 'default',
                    label: 'Default'
                }, {
                    key: 'varjo',
                    label: 'Varjo'
                }],
                visible: false
            };
        },
        computed: {
            title() {
                return this.update ? 'Update package' : 'Create package';
            },
            description() {
                if (!this.project) return null;
                return `You are going to ${ this.update ? 'update' : 'generate'} a VR package for ${this.project.name}.<br/>Do you want to proceed?`;
            },
            hasField() {
                return this.project && this.project.hasField && this.project.fieldId;
            }
        },
        methods: {
            open(project, update) {
                this.visible = true;
                this.project = project;
                this.update = update;
                this.$refs.dialog.open();
            },
            reset() {
                this.preview = false;
                this.target = 'default';
                this.legacyBuild = false;
            },
            issueBuild() {
                const lightQuality = this.preview ? 'none' : 'maximum';
                const experimentalSettings = !this.legacyBuild ? { pixelStreaming: this.legacyBuild } : null;
                const hasField = this.project.hasField && this.project.fieldId;
                if (hasField) {
                    this.autosync().then(() => this.project.issueBuild(lightQuality, this.target, experimentalSettings))
                                   .then(() => this.$emit('confirm', {project: this.project}))
                                   .catch(error => console.error(error));
                } else {
                    this.project.issueBuild(lightQuality, this.target, experimentalSettings)
                        .then(() => this.$emit('confirm', {project: this.project}))
                        .catch(error => console.error(error));
                }
            },

            autosync() {
                return this.project.updateAllFields(this.project.fieldId)
                    .then(() => {
                        window["studioApi"].storage.findFieldById(this.project.fieldId).then(studio =>{
                            this.$snotify.success('Field synced', {timeout: 5000, showProgressBar: false})
                        }).catch(err =>{
                            console.log("Projects::syncField fatal error",err);
                        });
                    })
                    .catch(() => this.$snotify.error('Field failed to sync', {timeout: 5000, showProgressBar: false}))
            }
        }
    };
</script>