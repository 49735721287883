<template>
	<div class="propsLibrary">
		<div class="filters" y-spaced-2>
			<div class="actions" x-spaced-2>
				<ch-button @click="addProps" type="primary" size="fill narrow" justified>
					Add elements
					<ch-icon icon="fas fa-plus-circle" size="12"/>
				</ch-button>
			</div>
			<div>
				<ch-search-bar v-model="searchValue" realTime size="fill narrow"/>
			</div>
			<div>
				<span>{{ props.length }} assets</span>
			</div>
			<ch-radio-group type="primary" size="narrow fill" v-model="propFilter">
				<ch-radio-button :label="0">Modules ({{ modulesCount }})</ch-radio-button>
				<ch-radio-button :label="1">Fixtures ({{ fixturesCount }})</ch-radio-button>
				<ch-radio-button :label="2">Static ({{ staticCount }})</ch-radio-button>
			</ch-radio-group>
		</div>
		<div class="props">
			<ch-grid-layout v-if="filteredProps.length" :width="130" :height="160" :gap="16">
				<div
					class="prop"
					v-for="prop in filteredProps"
					:key="prop.id"
					draggable="true"
					@dragstart="onDragStart(prop, $event)"
					:id="prop.id"
				>
					<div class="propImage" :title="prop.name">
						<ch-image :src="getPropImage(prop)" :id="prop.id + '_img'"/>
					</div>
					<div class="propLabel" ellipsis>{{ prop.name.capitalize() }}</div>
				</div>
			</ch-grid-layout>
			<ch-empty-content
				v-else-if="props.length === 0"
				first-message="No products added to the planogram"
			/>
			<ch-empty-content
				v-else
				first-message="No products matching the given query"
			/>
			<prop-finder ref="propFinder" title="Import elements" confirm-button-text="Import in library"/>
		</div>
	</div>
</template>

<script>
import SelectionManager           from '../../managers/SelectionManager';
import {PhysicalPlanogramService} from '../../services/PhysicalPlanogramService';
import Repository                 from '../../services/Repository';
import {View}          from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/View';
import {ArchetypeType} from '@/plugins/studioapi/api/propstore/model/ArchetypeType';
import PropFinder         from '@/content/assets/PropFinder';
import {PropService}      from '../../services/PropService';

export default {
	name: 'PropsLibrary',
	components: {PropFinder},
	props: {
		repository: Repository,
		selectionManager: SelectionManager,
		cameraView: String
	},
	data() {
		return {
			view: 'grid',
			searchValue: '',
			propFilter: 0,
			transparentImage: (() => {
				const img = new Image();
				img.src =
					'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
				return img;
			})()
		};
	},
	computed: {
		/**
		 * @return {string}
		 */
		baseURL() {
			return Config.RESTAddress;
		},
		editor() {
			return this.$parent.$parent.$parent.$parent.$parent;
		},
		/**
		 * return {ArchetypeType[]}
		 */
		moduleTypes() {
			return [ArchetypeType.Basket, ArchetypeType.Chest, ArchetypeType.ChestFridge, ArchetypeType.Fridge, ArchetypeType.Pallet, ArchetypeType.Pegboard, ArchetypeType.ShelfBack, ArchetypeType.WolfMouthFridge];
		},
		/**
		 * return {ArchetypeType[]}
		 */
		fixtureTypes() {
			return [ArchetypeType.Basket, ArchetypeType.Chest, ArchetypeType.Hook, ArchetypeType.Pallet, ArchetypeType.Pegboard, ArchetypeType.Shelf];
		},
		/**
		 * return {ArchetypeType[]}
		 */
		staticTypes() {
			return [ArchetypeType.Decoration];
		},
		/**
		 * @return {PropAsset[]}
		 */
		props() {
			return PhysicalPlanogramService.listPropAssets(this.repository.physicalPlanogram);
		},
		/**
		 * @return {PropAsset[]}
		 */
		filteredProps() {
			let results = this.props;
			if (this.searchValue) {
				const s = this.searchValue.toLowerCase();
				results = results.filter(prop => prop.name.toLowerCase().includes(s));
			}
			if (this.propFilter === 0)
				results = results.filter(prop => this.isModule(prop));
			else if (this.propFilter === 1)
				results = results.filter(prop => this.isFixture(prop));
			else if (this.propFilter === 2)
				results = results.filter(prop => this.isStatic(prop));

			return results.sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			});
		},
		modulesCount() {
			return this.props.filter(prop => this.isModule(prop)).length;
		},
		fixturesCount() {
			return this.props.filter(prop => this.isFixture(prop)).length;
		},
		staticCount() {
			return this.props.filter(prop => this.isStatic(prop)).length;
		}
	},
	methods: {
		/**
		 * @param {PropAsset} prop
		 */
		isModule(prop) {
			return this.moduleTypes.includes(prop.archetypeType);
		},
		/**
		 * @param {PropAsset} prop
		 */
		isFixture(prop) {
			return this.fixtureTypes.includes(prop.archetypeType);
		},
		/**
		 * @param {PropAsset} prop
		 */
		isStatic(prop) {
			return this.staticTypes.includes(prop.archetypeType);
		},
		/**
		 * @param {PropAsset} prop
		 */
		getPropImage(prop) {
			return this.repository.props.find(p => p.id === prop.propId).imageURL;
		},
		/**
		 * @param {PropAsset} prop
		 * @param {DragEvent} event
		 */
		onDragStart(prop, event) {
			event.dataTransfer.setDragImage(this.transparentImage, 0, 0);
			event.dataTransfer.setData('text/plain', prop);
			const view = this.cameraView === 'Front' ? View.Front : View.Bottom;
			this.$unityManager.planogramAPI.StartDragPropAsset(prop.id);
			this.$unityManager.canvas.focus();
			event.preventDefault();
		},
		addProps() {
			this.$refs.propFinder.open(this.props.map(prop => prop.propId))
				.then(props => {
					const propAssets = props.map(prop => PropService.translatePropFromPropStore('None', prop));
					this.$unityManager.planogramAPI.AddAssets(propAssets);
				});
		}
	}
};
</script>

<style scoped>
.propsLibrary {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow-y: auto;
	height: 100%;
}

.propsLibrary > * {
	padding: 0 var(--doubleMargin);
	flex: 0 0 auto;
}

.filters {
	display: flex;
	flex-direction: column;
	padding: var(--doubleMargin);
}

.actions {
	display: flex;
	/* flex-wrap: no-wrap; */
}

.props {
	flex: 1 1 auto;
	padding-top: var(--doubleMargin);
	border-top: 1px solid var(--elevation-06);
	box-sizing: border-box;
	overflow: auto;
}

.prop {
	display: flex;
	flex-direction: column;
	background-color: unset !important;
	padding: 0 !important;
	outline: none;
}

.propImage {
	flex: 0 0 auto;
	width: 130px;
	height: 130px;
	background-color: var(--elevation-04);
	border-radius: 4px;
	padding: var(--singleMargin);
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.propImage:hover {
	background-color: var(--primary);
}

.propLabel {
	flex: 0 0 auto;
	font-size: 11px;
	margin-top: var(--singleMargin);
	text-transform: capitalize;
	color: var(--on-elevation-02);
}

</style>