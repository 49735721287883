<template>
  <div class="rightSideBar" fill>
    <bucket-info
      v-if="selectedBucketsIds && selectedBucketsIds.length === 1"
      :bucketId="selectedBucketsIds[0]"
      :cameraView="cameraView"
      :repository="repository"
      :selectionManager="selectionManager"
      @change-dataset="$emit('change-dataset')"
    />
    <multiple-buckets-info
      v-else-if="selectedBucketsIds && selectedBucketsIds.length > 1"
      :bucketsIds="selectedBucketsIds"
      :repository="repository"
      :selectionManager="selectionManager"
    />
    <fixture-info
      v-else-if="selectedFixturesIds.length === 1"
      :fixtureId="selectedFixturesIds[0]"
      :repository="repository"
    />
    <multiple-fixtures-info
      v-else-if="selectedFixturesIds.length > 1"
      :fixturesIds="selectedFixturesIds"
      :repository="repository"
    />
    <decoration-info
      v-else-if="selectedDecorationsIds.length === 1"
      :decorationId="selectedDecorationsIds[0]"
      :repository="repository"
    />
    <group-info
      v-else-if="selectedGroupId"
      :groupId="selectedGroupId"
      :repository="repository"
      @deleteGroup="deleteGroup"
      @editGroup="editGroup"
    />
    <multiple-elements-info
      v-else-if="selectedElementsIds.length > 0"
      :elementsIds="selectedElementsIds"
    />
    <div v-else class="noSelectionMessage">
      <h1 primary-text>No items selected</h1>
      <p elevation-05-text>Select an item to view info</p>
    </div>
  </div>
</template>

<script>
import BucketInfo from './bucket/BucketInfo';
import FixtureInfo from './fixture/FixtureInfo';
import MultipleBucketsInfo from './bucket/MultipleBucketsInfo';
import SelectionManager from '../../managers/SelectionManager';
import Repository from '../../services/Repository';
import Bucket from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Bucket';
import Module from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Module';
import Fixture from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Fixture';
import ProductsGroupAsset from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/ProductsGroupAsset';
import MultipleFixturesInfo from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/fixture/MultipleFixturesInfo';
import MultipleElementsInfo from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/MultipleElementsInfo';
import DecorationInfo from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/decoration/DecorationInfo';
import StaticDecoration from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/StaticDecoration';

export default {
  name: 'RightSideBar',
  components: {
    DecorationInfo,
    MultipleElementsInfo,
    MultipleFixturesInfo,
    MultipleBucketsInfo,
    FixtureInfo,
    BucketInfo,
  },
  props: {
    repository: Repository,
    selectionManager: SelectionManager,
  },
  data() {
    return {
      cameraView: 'Front',
    };
  },
  computed: {
    /**
     * @return {ISelectable[]}
     */
    selection() {
      return SelectionManager.RetrieveSelection(
        this.repository.physicalPlanogram,
        this.selectionManager
      );
    },
    /**
     * @return {string[]}
     */
    selectedBucketsIds() {
      if (this.selection.some(i => i.type !== Bucket.staticType)) return [];
      return this.selection.map(b => b.id);
    },
    /**
     * @return {string[]}
     */
    selectedFixturesIds() {
      const selection = this.selection;
      if (
        selection.every(item =>
          [Fixture.staticType, Module.staticType].includes(item.type)
        )
      ) {
        return selection.map(item => item.id);
      }
      return [];
    },
    /**
     * @return {string[]}
     */
    selectedDecorationsIds() {
      const selection = this.selection;
      if (
        selection.every(item =>
          [StaticDecoration.staticType].includes(item.type)
        )
      ) {
        return selection.map(item => item.id);
      }
      return [];
    },
    /**
     * @return {string}
     */
    selectedGroupId() {
      return this.selection.length === 1 &&
        this.selection[0].type === ProductsGroupAsset.staticType
        ? this.selection[0].id
        : null;
    },
    /**
     * @return {string[]}
     */
    selectedElementsIds() {
      return this.selection.map(item => item.id);
    },
  },
  mounted() {
    this.$unityManager.Subscribe('CameraViewChanged', this.cameraViewChanged);
  },
  beforeDestroy() {
    this.$unityManager.Unsubscribe('CameraViewChanged', this.cameraViewChanged);
  },
  methods: {
    /**
     * @param {{view: string}} event
     */
    cameraViewChanged(event) {
      this.cameraView = event.view;
    },
    /**
     * @param {{any}} event
     */
    deleteGroup(event) {
      this.$emit('deleteGroup', event);
    },
    editGroup(groupId) {
      this.$emit('editGroup', groupId);
    },
  },
};
</script>

<style scoped>
.rightSideBar {
  display: flex;
  flex-direction: column;
  outline: none;
  max-height: 100%;
  overflow: hidden;
}

.noSelectionMessage {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: var(--doubleMargin);
  border-bottom: 1px solid var(--elevation-04);
}
</style>
