<template>
    <div y-spaced-2>
        <h2 primary-text>Planogram</h2>
        <div class="planogramName" x-spaced-1>
            <ch-indicator :color="color"/>
            <span>{{asset.name}}</span>
        </div>
        <ch-dl label-width="45%">
            <ch-dd label="Products">{{productsCount}}</ch-dd>
        </ch-dl>
        <ch-button size="fill narrow" @click="openPhysicalPlanogram" justified>Edit
            <ch-icon icon="edit" size="12"/>
        </ch-button>
    </div>
</template>

<script>

    import LayoutController      from '../services/LayoutController';
    import Transform             from './Transform';
    import Materializer          from './Materializer';
    import RGBColor              from '../model/common/color/RGBColor';
    import DeleteSelectionButton from './DeleteSelectionButton';
	import {
		PhysicalPlanogramService
	}                            from '@/spaceallocation/physicalplanogrameditor/services/PhysicalPlanogramService';

    export default {
        name: 'PhysicalPlanogramReferenceInfo',
        components: {DeleteSelectionButton, Materializer, Transform},
        props: {
            controller: LayoutController,
            asset: Object
        },
        computed: {
            color() {
                const color = this.asset.color;
                return new RGBColor(color.r, color.g, color.b).toString();
            },
            /**
             * @return {PhysicalPlanogram}
             */
            physicalPlanogram() {
                return this.controller.findPhysicalPlanogram(this.asset.physicalPlanogramId);
            },
			/**
			 * @return {number}
			 */
			productsCount() {
				return PhysicalPlanogramService.listPropAssets(this.physicalPlanogram).length;
			}
        },
        methods: {
            openPhysicalPlanogram() {
                this.$router.push({
                    name: 'physicalPlanogram',
                    params: {physicalPlanogramId: this.asset.physicalPlanogramId}
                });
            }
        }
    };
</script>

<style scoped>

    .planogramName {
        word-break: break-all;
    }

</style>