<template>
  <ch-form-dialog
    ref="dialog"
    type="primary"
    title="Download data"
    @submit="downloadData"
  >
    <template>
      <div class="settings primaryColors">
        <section class="global">
          <ch-field y-spaced-2>
            <ch-radio-group v-model="globalSettings.value">
              <ch-radio label="all">Download all field data</ch-radio>
              <ch-radio label="selectedCell"
                >Download only current cell data ({{ cellName }})</ch-radio
              >
            </ch-radio-group>
          </ch-field>
          <div class="subjectsSelect option" y-spaced-1>
            <ch-field
              ref="subjectsField"
              label="Respondents"
              :validators="[
                v =>
                  this.isAllSelected || v.length > 0
                    ? null
                    : 'Select at least one subject',
              ]"
            >
              <ch-select
                size="narrow"
                multiple
                :items="subjectsSelect"
                v-model="globalSettings.subjects"
                :disabled="isAllSelected"
              />
            </ch-field>
          </div>
        </section>
        <section class="cameras" :disabled="!isCamerasEnabled">
          <h1
            class="sectionHeader settingsHeader"
            primary-text
            :disabled="this.isAllSelected"
          >
            Cameras<ch-toggle
              type="primary"
              v-model="camerasSettings.enabled"
              :disabled="isAllSelected"
            />
          </h1>
          <div class="camerasSelect option" y-spaced-1>
            <ch-field
              ref="camerasField"
              label="Cameras"
              :validators="[
                v =>
                  this.isAllSelected ||
                  !this.camerasSettings.enabled ||
                  v.length > 0
                    ? null
                    : 'Select at least one camera',
              ]"
            >
              <ch-select
                size="narrow"
                multiple
                :items="camerasSelect"
                v-model="camerasSettings.cameras"
                :disabled="!isCamerasEnabled || isCamerasSelectedAreaActive"
              />
            </ch-field>
          </div>
          <ch-checkbox
            class="option"
            v-model="camerasSettings.raw"
            :disabled="!isCamerasEnabled"
            >Raw data</ch-checkbox
          >
          <ch-checkbox
            class="option"
            v-model="camerasSettings.area"
            :disabled="
              !isCamerasEnabled ||
                !cameraHasArea ||
                camerasSettings.cameras.length > 1
            "
            >Selected Area</ch-checkbox
          >
          <div class="settingsHeader option">
            <span
              >Selected time range
              <span class="timeRange">({{ timeRange }})</span></span
            >
            <ch-toggle
              v-model="camerasSettings.timerange"
              :disabled="!isCamerasEnabled"
            />
          </div>
          <div class="settingsHeader option">
            Heatmap image<ch-toggle
              v-model="camerasSettings.heatmapImage"
              :disabled="!isCamerasEnabled"
            />
          </div>
          <div class="settingsHeader option">
            Gaze plot image<ch-toggle
              v-model="camerasSettings.gazePlotImage"
              :disabled="!isCamerasEnabled"
            />
          </div>
        </section>
        <section class="maps" :disabled="!isMapEnabled">
          <h1
            class="sectionHeader settingsHeader"
            primary-text
            :disabled="this.isAllSelected"
          >
            Map<ch-toggle
              type="primary"
              v-model="mapSettings.enabled"
              :disabled="isAllSelected"
            />
          </h1>
          <ch-checkbox
            class="option"
            v-model="mapSettings.raw"
            :disabled="!isMapEnabled"
            >Raw data</ch-checkbox
          >
          <ch-checkbox
            class="option"
            v-model="mapSettings.area"
            :disabled="!isMapEnabled || !mapHasArea"
            >Selected Area</ch-checkbox
          >
          <div class="settingsHeader option">
            Heatmap image<ch-toggle
              v-model="mapSettings.heatmapImage"
              :disabled="!isMapEnabled"
            />
          </div>
          <div class="settingsHeader option">
            Path plot image<ch-toggle
              v-model="mapSettings.pathPlotImage"
              :disabled="!isMapEnabled"
            />
          </div>
        </section>
        <section class="video" :disabled="!isVideoEnabled">
          <h1 class="sectionHeader settingsHeader" primary-text>
            Video<ch-toggle type="primary" v-model="videoSettings.enabled" />
          </h1>
        </section>
        <section class="product-metrics" :disabled="!isProductMetricsEnabled">
          <h1 class="sectionHeader settingsHeader" primary-text>
            <div class="productMetricsContainer">
              <div class="productMetricsLabel">
                <span>Product metrics</span
                ><ch-toggle
                  type="primary"
                  v-model="productMestricsSettings.enabled"
                />
              </div>
              <div
                v-show="productMestricsSettings.enabled"
                class="productMetricsAlert"
              >
                <ch-icon
                  icon="error"
                  style="color: var(--error); margin: 5px"
                />
                <span
                  >There could be respondents with no product data, no CSV will
                  be downloaded for them</span
                >
              </div>
            </div>
          </h1>
        </section>
      </div>
    </template>
    <template slot="actions">
      <ch-button cancel size="small" type="secondary">Cancel</ch-button>
      <ch-button submit size="small" type="primary">Download</ch-button>
    </template>
  </ch-form-dialog>
</template>

<script>
import Vue from 'vue';
import {
  isImageValid,
  loadImage,
  aggregatePoints,
  yes,
  isPointInsideArea,
  drawPath,
  heatmapMaxValue,
  toBlob,
  heatmapGradient,
  drawHeatMap,
  createRecordingsStyles,
  getScaledImgSizes,
  groupBy,
  loadImageAnonymously,
} from '../analysis-helpers';
import Papa from 'papaparse';
import JSZip from 'jszip';
import Heatmap from '../../utils/HeatMap';
import StrUtils from '../../utils/StringUtils';

export default {
  name: 'DownloadDataDialog',
  props: {
    field: Object,
    selectedCellId: String,
    selectedRecordings: Array,
    recordings: Array,
    selectedCameraId: String,
    cameras: Array,
    timerange: Object,
    areas: Object,
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    cellName() {
      return (
        this.field.getCellWithId(this.selectedCellId).name ||
        this.selectedCellId
      );
    },
    timeRange() {
      const { start, end } = this.timerange;
      return `from ${StrUtils.formatTimeMilliseconds(
        start * 1000
      )} to ${StrUtils.formatTimeMilliseconds(end * 1000)}`;
    },
    isAllSelected() {
      return this.globalSettings.value === 'all';
    },
    camerasSelect() {
      return this.cameras.map(({ id, name }) => ({ key: id, label: name }));
    },
    subjectsSelect() {
      return this.recordings
        .map(rId => this.field.recordings.get(rId))
        .map(({ id, subject }) => ({ key: id, label: subject }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    isCamerasEnabled() {
      return this.settingActive('camerasSettings');
    },
    isMapEnabled() {
      return this.settingActive('mapSettings');
    },
    isVideoEnabled() {
      return this.videoSettings.enabled;
    },
    isProductMetricsEnabled() {
      return this.productMestricsSettings.enabled;
    },
    isCamerasSelectedAreaActive() {
      return this.camerasSettings.area;
    },
    mapHasArea() {
      return !!this.mapArea;
    },
    mapArea() {
      return this.areas[this.selectedCellId].Map.area;
    },
    cameraHasArea() {
      return !!this.cameraArea;
    },
    cameraArea() {
      const cameraId = this.camerasSettings.cameras[0];
      return (
        this.camerasSettings.cameras.length === 1 &&
        !!cameraId &&
        this.areas[this.selectedCellId][cameraId] &&
        this.areas[this.selectedCellId][cameraId].area
      );
    },
    validation() {
      return [
        this.isAllSelected,
        this.camerasSettings.enabled,
        this.camerasSettings.cameras,
        this.globalSettings.subjects,
      ];
    },
  },
  methods: {
    getDefaultData() {
      return {
        globalSettings: {
          value: 'all',
          subjects: [],
        },
        camerasSettings: {
          enabled: true,
          raw: false,
          cameras: [],
          area: false,
          timerange: false,
          heatmapImage: false,
          gazePlotImage: false,
        },
        mapSettings: {
          enabled: true,
          area: false,
          raw: false,
          heatmapImage: false,
          pathPlotImage: false,
        },
        videoSettings: {
          enabled: true,
        },
        productMestricsSettings: {
          enabled: true,
        },
      };
    },
    resetOptions() {
      Object.assign(this.$data, this.getDefaultData());
    },
    open() {
      this.$refs.dialog.open();
      this.globalSettings.subjects = [...this.selectedRecordings];
      this.camerasSettings.cameras = [this.selectedCameraId];
    },
    settingActive(settingType) {
      return !this.isAllSelected && this[settingType].enabled;
    },
    settingOptionActive(settingType, option) {
      return this.settingActive(settingType) && this[settingType][option];
    },
    downloadData() {
      this.$loading.await(this.exportData());
    },
    async exportData() {
      try {
        const zip = new JSZip();
        const cells = this.isAllSelected
          ? this.field.cells
          : [this.field.getCellWithId(this.selectedCellId)];

        zip.file(
          'Respondents.csv',
          this.createCSVContent(
            ['RespondentID', 'RespondentName'],
            this.field.interviews.map(({ id, name }) => [id, name])
          )
        );

        zip.file(
          'Cells.csv',
          this.createCSVContent(
            ['CellID', 'CellName'],
            cells.map(({ id, name }) => [id, name])
          )
        );

        await Promise.all(
          cells.map(async cell => await this.generateCellData(zip, cell))
        );

        const blob = await zip.generateAsync({ type: 'blob' });

        this.$downloadManager.addItem(
          `${this.field.name}_${this.field.id}.zip`,
          'Collected data',
          window.URL.createObjectURL(blob)
        );

        if (
          this.videoSettings.enabled &&
          this.selectedRecordings &&
          this.selectedRecordings.length
        ) {
          const recordings = this.globalSettings.subjects.map(id =>
            this.field.getRecordingWithId(id)
          );

          recordings.forEach(recording => {
            recording.videoURL &&
              this.$downloadManager.addItem(
                `${this.cellName}_${recording.subject}_${recording.id}.mp4`,
                'Videos',
                recording.videoURL
              );
          });
        }
      } catch (e) {
        console.error({
          e,
        });
      }
    },

    createCSVContent(headers, items) {
      return Papa.unparse({
        fields: headers,
        data: items,
      });
    },

    async generateCellData(zip, cellObj) {
      try {
        const canvas = document.createElement('canvas');
        const cellFolder = zip.folder(`${cellObj.id}`);
        await cellObj.getCellLocations();
        const { recordings: recordingsIds } = cellObj;
        const recordings = (this.isAllSelected
          ? recordingsIds
          : this.globalSettings.subjects
        ).map(id => this.field.getRecordingWithId(id));
        const recordingStyles = createRecordingsStyles(
          recordings.sort((r1, r2) =>
            r1.subject.toLowerCase().localeCompare(r2.subject.toLowerCase())
          )
        );
        cellFolder.file(
          'Recordings.csv',
          this.createCSVContent(
            ['RecordingID', 'RespondentID', 'Tags'],
            recordings.map(({ id, interviewId, tags }) => [
              id,
              interviewId,
              tags,
            ])
          )
        );
        if (this.isAllSelected || this.isCamerasEnabled) {
          const cameras = this.isAllSelected
            ? cellObj.cameras
            : this.camerasSettings.cameras.map(id =>
                cellObj.getCameraWithId(id)
              );
          const camerasFolder = cellFolder.folder('Cameras');
          camerasFolder.file(
            'Cameras.csv',
            this.createCSVContent(
              ['CameraId', 'CameraName'],
              cameras.map(c => [c.id, c.name])
            )
          );
          await Promise.all(
            cameras.map(
              async c =>
                await this.generateCameraData(
                  camerasFolder,
                  cellObj,
                  c,
                  recordings,
                  recordingStyles
                )
            )
          );
        }
        if (this.isAllSelected || this.isMapEnabled) {
          const mapFolder = cellFolder.folder('Map');
          await this.generateMapData(
            mapFolder,
            cellObj,
            recordings,
            recordingStyles
          );
        }
        if (this.isProductMetricsEnabled) {
          await this.generateProductMetricsData(cellFolder, recordings);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async generateMapData(zip, cell, recordings, styles) {
      try {
        const points = {};
        const rawData = {};
        const img = await loadImageAnonymously(cell.perspectiveImageURL);
        const filterFunc = !(
          this.mapHasArea && this.settingOptionActive('mapSettings', 'area')
        )
          ? yes
          : isPointInsideArea(this.mapArea, img);
        for (const rec of recordings) {
          const id = rec.id;
          points[id] = aggregatePoints(cell.locations[id] || []).filter(
            filterFunc
          );
          rawData[id] = (cell.locations[id] || []).filter(filterFunc);
          zip.file(
            `${id}.csv`,
            this.createCSVContent(
              ['x', 'y', 'timestamp', 'duration'],
              points[id].map(({ x, y, time, duration }) => [
                x,
                y,
                time,
                duration,
              ])
            )
          );
        }
        (this.isAllSelected ||
          this.settingOptionActive('mapSettings', 'raw')) &&
          this.generateRawData(zip, rawData);
        if (img) {
          const background = this.createBackground(img);
          await this.addImageToExport('MapImage', zip, background);
          (this.isAllSelected ||
            this.settingOptionActive('mapSettings', 'heatmapImage')) &&
            (await this.generateHeatmap('Heatmap', zip, background, points));
          (this.isAllSelected ||
            this.settingOptionActive('mapSettings', 'pathPlotImage')) &&
            (await this.generatePathPlot(
              'Pathplot',
              zip,
              background,
              points,
              styles
            ));
        }
      } catch (e) {
        console.error(e);
      }
    },
    async generateCameraData(zip, cell, camera, recordings, styles) {
      try {
        const cameraFolder = zip.folder(`${camera.id}`);
        const img = await loadImageAnonymously(camera.imageURL);
        const totalPoints = {};
        const inTimerangeFunc = timeframe => (point, _, points) => {
          const startTime = timeframe.start * 1000000000;
          const endTime = timeframe.end * 1000000000;
          const timeOffset = points[0].time;
          const begin = startTime + timeOffset;
          const end = endTime + timeOffset;
          return (
            point.time < end && point.time + point.duration * 1000000 > begin
          );
        };
        const timerangeFilterFunc = !this.settingOptionActive(
          'camerasSettings',
          'timerange'
        )
          ? yes
          : inTimerangeFunc(this.timerange);
        const selectedAreaFilterFunc = !(
          this.cameraHasArea &&
          this.settingOptionActive('camerasSettings', 'area')
        )
          ? yes
          : isPointInsideArea(this.cameraArea, img);
        const fixationFolder = cameraFolder.folder('Fixations');
        await camera.getFixations().catch(console.error);
        for (const rec of recordings) {
          const id = rec.id;
          const points = camera.viewpoints[id];
          const filteredPoints = (points || [])
            .filter(timerangeFilterFunc)
            .filter(selectedAreaFilterFunc);
          fixationFolder.file(
            `${id}.csv`,
            this.createCSVContent(
              ['x', 'y', 'timestamp', 'duration'],
              filteredPoints.map(({ x, y, time, duration }) => [
                x,
                y,
                time,
                duration,
              ])
            )
          );
          totalPoints[id] = filteredPoints;
        }
        (this.isAllSelected ||
          this.settingOptionActive('camerasSettings', 'raw')) &&
          this.generateRawData(cameraFolder, totalPoints);
        if (img) {
          const background = this.createBackground(img);
          await this.addImageToExport('CameraImage', cameraFolder, background);
          (this.isAllSelected ||
            this.settingOptionActive('camerasSettings', 'heatmapImage')) &&
            (await this.generateHeatmap(
              'Heatmap',
              cameraFolder,
              background,
              totalPoints
            ));
          (this.isAllSelected ||
            this.settingOptionActive('camerasSettings', 'gazePlotImage')) &&
            (await this.generatePathPlot(
              'Gazeplot',
              cameraFolder,
              background,
              totalPoints,
              styles
            ));
        }
      } catch (e) {
        console.error(e);
      }
    },
    generateRawData(zip, rawData) {
      const rawDataFolder = zip.folder('RawData');
      for (const recId in rawData) {
        const points = rawData[recId].map(({ x, y, time }) => [x, y, time]);
        rawDataFolder.file(
          `${recId}.csv`,
          this.createCSVContent(['x', 'y', 'timestamp'], points)
        );
      }
    },
    async generateProductMetricsData(zip, recordings) {
      try {
        const productMetricsFolder = zip.folder('ProductMetrics');
        const products = [];
        for (const r of recordings) {
          if (r.hasEvents()) {
            const events = (await r.getEvents().catch(() => [])).map(
              ({ timestamp, product, event }) => {
                product && products.push(product);
                return [timestamp, product ? product.id : '', event];
              }
            );
            productMetricsFolder.file(
              `${r.id}.csv`,
              this.createCSVContent(['Timestamp', 'ProductID', 'Event'], events)
            );
          }
        }
        const groupedProducts = groupBy(products, p => `${p.ean}_${p.id}`);
        const uniqueProducts = [];
        for (const key of groupedProducts.keys()) {
          uniqueProducts.push(groupedProducts.get(key)[0]);
        }
        productMetricsFolder.file(
          'Products.csv',
          this.createCSVContent(
            ['ProductID', 'EAN', 'Name'],
            uniqueProducts.map(({ id, ean, properties: { name } }) => [
              id,
              ean,
              name,
            ])
          )
        );
      } catch (e) {
        console.error(e);
      }
    },

    async generateHeatmap(filename, zip, background, points) {
      const heatmap = new Heatmap();
      heatmap.createPalette();
      heatmap.gradient = heatmapGradient;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const { width, height } = background;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(background, 0, 0, width, height);
      ctx.globalAlpha = 0.75;
      const fPoints = this.toFixationPoints(points, canvas);
      drawHeatMap(heatmap, canvas, ctx, fPoints, heatmapMaxValue());
      await this.addImageToExport(filename, zip, canvas);
    },

    async generatePathPlot(filename, zip, background, points, styles) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const { width, height } = background;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(background, 0, 0, background.width, background.height);
      ctx.globalAlpha = 0.75;
      const fPoints = this.toFixationPoints(points, canvas);
      drawPath(ctx, fPoints, heatmapMaxValue(), styles);
      await this.addImageToExport(filename, zip, canvas);
    },

    async addImageToExport(filename, zip, canvas) {
      const blob = await toBlob(canvas);
      zip.file(`${filename}.png`, blob, { type: 'binary' });
    },
    createBackground(image) {
      const background = document.createElement('canvas');
      const ctx = background.getContext('2d');
      const { width, height } = image;
      background.width = width;
      background.height = height;
      if (isImageValid(image)) {
        ctx.drawImage(image, 0, 0, width, height);
      }
      return background;
    },
    toFixationPoints(points, canvas) {
      return Object.keys(points).reduce((acc, key) => {
        return {
          ...acc,
          [key]: points[key].map(fixation => {
            const x1 = fixation.time;
            const x2 = fixation.time + fixation.duration * 1000000;
            const v = (x2 - x1) / 1000000;
            return {
              x: fixation.x * canvas.width,
              y: fixation.y * canvas.height,
              value: v,
            };
          }),
        };
      }, {});
    },
    validate() {
      if (this.$refs.subjectsField) {
        this.$refs.subjectsField.edited = true;
        this.$refs.subjectsField.validate();
      }
      if (this.$refs.camerasField) {
        this.$refs.camerasField.edited = true;
        this.$refs.camerasField.validate();
      }
      if (this.$refs.dialog && this.$refs.dialog.$refs.form) {
        this.$refs.dialog.$refs.form.validate();
      }
    },
  },
  watch: {
    selectedRecordings: {
      immediate: true,
      handler(n, o) {
        this.globalSettings.subjects = [...this.selectedRecordings];
      },
    },
    selectedCameraId: {
      immediate: true,
      handler(n, o) {
        this.camerasSettings.cameras = [this.selectedCameraId];
      },
    },
    cameraHasArea() {
      !this.cameraHasArea && (this.camerasSettings.area = false);
    },
    mapHasArea() {
      !this.mapHasArea && (this.mapSettings.area = false);
    },
    validation() {
      Vue.nextTick(() => this.validate());
    },
  },
};
</script>

<style scoped>
.settingsHeader {
  border-bottom: 2px solid var(--elevation-03);
  margin: var(--singleMargin) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option {
  margin: 0;
  margin-bottom: var(--singleMargin);
}
.settingsHeader:last-child,
.option {
  border-bottom: none;
}
.timeRange {
  color: var(--elevation-04);
}
.cameras,
.maps {
  display: flex;
  flex-direction: column;
}

section[disabled] > *:not(.sectionHeader) {
  --primary: var(--elevation-04);
  --default: var(--elevation-04);
  --switch-on: var(--disabled);
  color: var(--disabled);
}

.sectionHeader[disabled] {
  --primary: var(--elevation-04);
  --default: var(--elevation-04);
  --switch-on: var(--disabled);
  color: var(--disabled);
}

.productMetricsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.productMetricsLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productMetricsAlert {
  color: white;
  font-size: 12px;
  display: flex;
  line-height: 14px;
  margin: var(--singleMargin) 0;
}
</style>
