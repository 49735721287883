<template>
    <ch-accordion-menu :active="activeVoice">
        <ch-accordion-voice key="planograms" label="Planograms" @click="goTo('planograms')">
			<ch-tree v-model="planogramsPath" :nodes="planogramsNodes"/>
		</ch-accordion-voice>
        <ch-accordion-voice key="placeholders" label="Placeholders" @click="goTo('placeholders')"/>
        <ch-accordion-voice key="assets" label="Assets" @click="goTo('assets')">
            <ch-tree v-model="assetsPath" :nodes="assetsNodes"/>
        </ch-accordion-voice>
    </ch-accordion-menu>
</template>

<script>

	export default {

        data() {
            return {
                props: [],
                propTypes: [
					{id: 'ShelfBack', text: 'Back'},
                    {id: 'Basket', text: 'Basket'},
                    {id: 'Chest', text: 'Chest'},
                    {id: 'ChestFridge', text: 'Chest Fridge'},
                    {id: 'Decoration', text: 'Decoration'},
                    {id: 'Fridge', text: 'Fridge'},
                    {id: 'Hook', text: 'Hook'},
                    {id: 'Pallet', text: 'Pallet'},
                    {id: 'Pegboard', text: 'Pegboard'},
                    {id: 'Shelf', text: 'Shelf'},
                    {id: 'WolfMouthFridge', text: 'Wolf\'s Mouth Fridge'}
                ]
            }
        },
        mounted() {
            this.$propStore.listProps().then(result => {
                this.props = result.props;
            })
        },
        computed: {
            activeVoice() {
                return this.$route.name
            },
            assetsPath: {
                get() {
                    if (this.$route.query.type)
                        return this.assetsNodes.filter(n => n.id === this.$route.query.type);
                    else
                        return [];
                },
                set(path) {
                    this.$emit('currentPath', path[0].id)
                }
            },
            assetsNodes() {
                return this.propTypes.map(n => ({
                    id: n.id,
                    text: n.text,
                    append: this.props.filter(p => p.archetype.type === n.id).length
                }))
            },
            planogramsPath: {
                get() {
                    if (this.$route.query.type)
                        return this.planogramsNodes.filter(n => n.id === this.$route.query.type);
                    else
                        return [];
                },
                set(path) {
                    this.$emit('currentPath', path[0].id)
                }
            },
            planogramsNodes() {
				return [
					{
						id: 'physical',
						text: 'Physical'
					},
					{
						id: 'logical',
						text: 'Logical'
					}
				];
            }
        },
        methods: {
            goTo(path) {
                this.$router.push({name: path});
                this.$emit('currentPath', null);
            },
        }
    };
</script>

<style scoped>
</style>