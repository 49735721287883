<template>
	<ch-button type="primary" v-if="couldBeCombined" @click="combineFixtures" size="fill" justified>Combine<ch-icon icon="fas fa-object-ungroup"/></ch-button>
	<ch-button type="error" v-else-if="couldBeBroken" @click="breakCombinedFixtures" size="fill" justified>Break combined allocation spaces<ch-icon icon="cancel"/></ch-button>
</template>

<script>
import HorizontalProductAllocationSpace
	from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/HorizontalProductAllocationSpace';
import FreeProductAllocationSpace
	from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/FreeProductAllocationSpace';
import HungProductAllocationSpace
	from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/HungProductAllocationSpace';

export default {
	name: 'CombineElementsButton',
	props: {
		fixtures: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			allocationSpaceTypes: [HorizontalProductAllocationSpace.staticType, FreeProductAllocationSpace.staticType, HungProductAllocationSpace.staticType]
		}
	},
	computed: {
		/**
		 * @return {IProductAllocationSpace[]}
		 */
		allocationSpaces() {
			return this.fixtures
				.flatMap(f => f.children)
				.filter(child => this.allocationSpaceTypes.includes(child.type))
		},
		/**
		 * @return {string[]}
		 */
		uniqueCombineIds() {
			return [...new Set(this.allocationSpaces.map(allocationSpace => allocationSpace.combineId))];
		},
		/**
		 * @return {boolean}
		 */
		couldBeCombined() {
			return this.fixtures.length > 1 && !(this.uniqueCombineIds.length === 1 && this.uniqueCombineIds[0]);
		},
		/**
		 * @return {boolean}
		 */
		couldBeBroken() {
			return this.uniqueCombineIds.length === 1 && this.uniqueCombineIds[0];
		}
	},
	methods: {
		combineFixtures() {
			const allocationSpacesIds = this.allocationSpaces.map(allocationSpace => allocationSpace.id);
			console.log(this.fixtures);
			this.$unityManager.planogramAPI.CombineProductAllocationSpaces(allocationSpacesIds);
		},
		breakCombinedFixtures() {
			const allocationSpacesIds = this.allocationSpaces.map(allocationSpace => allocationSpace.id);
			this.$unityManager.planogramAPI.BreakCombinedProductAllocationSpaces(allocationSpacesIds);
		}
	}
};
</script>

<style scoped>

</style>