<template>
  <ch-table
    class="marketDataPoolList"
    ref="data-sets-info"
    :items="dataSets"
    @rowClicked="handleDataSetSelection"
  >
    <template #header>
      <ch-th
        v-for="(headerCell, headerIndex) in header"
        :key="headerIndex"
        :id="headerCell"
        :name="headerCell"
        :width="columnWidth[headerIndex]"
      />
    </template>
    <template slot-scope="{ row }">
      <ch-td>
        <ch-checkbox
          :value="row.id === currentSelectMarketDataPoolId"
          @input="$emit('handleDataSetSelection', row.id)"
        />
      </ch-td>
      <ch-td>
        {{ row.name }}
      </ch-td>
      <ch-td>
        {{ row.period }}
      </ch-td>
      <ch-td>
        {{ row.storeFormat }}
      </ch-td>
      <ch-td>
        {{ row.location }}
      </ch-td>
      <ch-td>
        {{ row.creationDate }}
      </ch-td>
    </template>
  </ch-table>
</template>

<script>
export default {
  name: 'MarketDataPoolListTable',
  props: {
    /*
     * Sets the current select market data pool id
     */
    currentSelectMarketDataPoolId: {
      type: String,
      default: '',
    },
    /*
     * Sets the table header
     */
    header: {
      type: Array,
      default: () => [
        '',
        'Name',
        'Period',
        'Format',
        'Location',
        'Creation date',
      ],
    },
    /*
     * Sets the table column width
     */
    columnWidth: {
      type: Array,
      default: () => ['40px', '200px', '200px', '200px', '200px', '200px'],
    },
    /*
     * Sets the available data sets
     */
    dataSets: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleDataSetSelection(event) {
      event && event.id && this.$emit('handleDataSetSelection', event.id);
    },
  },
};
</script>

<style></style>
