<template>
  <div class="score-card">
    <div class="score-card-header">
      <ch-icon
        v-if="!isExpanded"
        class="header-icon"
        size="32"
        icon="arrow_right"
        @click="isExpanded = !isExpanded"
      />
      <ch-icon
        v-else
        class="header-icon"
        size="32"
        icon="arrow_drop_down"
        @click="isExpanded = !isExpanded"
      />
      <h1 primary-text style="margin-right: 8px">Market data</h1>
      <label-tag label="BETA" size="small" />
      <div v-if="dataSetName" v-tooltip.top="dataSetName" class="data-set-name">
        <span>{{ dataSetName }}</span>
      </div>
      <ch-icon
        class="settings-icon"
        size="16"
        icon="settings"
        @click="$emit('change-dataset')"
      />
    </div>
    <div v-if="isExpanded" style="font-size: 12px">
      <template v-if="kpis.length">
        <ch-dl
          v-for="(kpi, index) in kpis"
          :key="`${index}-${kpi.value}`"
          label-width="50%"
        >
          <ch-dd
            v-if="kpi.label"
            :label="kpi.label"
            :class="{ disabled: !kpi.value || kpi.value < 0 }"
            v-tooltip.top.default="
              !kpi.value
                ? 'There are no data in the dataset for this KPI'
                : null
            "
          >
            <span
              v-if="kpisDisplayedAsString.includes(kpi.key)"
              class="kpi-value"
            >
              {{ getKpiDisplayedAsString(kpi.value) }}
            </span>
            <span
              v-else-if="kpisDisplayedAsValues.includes(kpi.key)"
              class="kpi-value"
            >
              {{ getKpiDispalyedAsValue(kpi.value) }}
            </span>
            <template v-else>
              <span class="kpi-percentage" :class="{ disabled: !kpi.value }">
                {{ getKpiDisplayedAsPercentage(kpi.value) }}
              </span>
              <ch-progress-bar :value="kpi.value" :max-value="1" />
            </template>
          </ch-dd>
        </ch-dl>
      </template>
      <template v-if="economics.length">
        <ch-dl
          v-for="(economic, index) in economics"
          :key="index"
          label-width="50%"
        >
          <ch-dd :label="economic.label">
            <span style="margin-left: 12px">
              {{ economic.value }}
            </span>
          </ch-dd>
        </ch-dl>
      </template>
      <!--
        FIXME: records rendering are temporary disabled:
        a refactor of importedData body mapping is required.
      -->
      <!-- <template v-if="records.length">
        <ch-dl
          v-for="(record, index) in records"
          :key="index"
          label-width="50%"
        >
          <ch-dd :label="record.label">
            {{ record.value }}
          </ch-dd>
        </ch-dl>
      </template> -->
    </div>
  </div>
</template>

<script>
import LabelTag from '../../LabelTag.vue';
import KPI from '../../../../utils/KPI/index';

export default {
  name: 'MarketDataScoreCard',
  components: {
    LabelTag,
  },
  props: {
    /*
     * Sets the kpis to display.
     */
    kpis: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the economics to display.
     */
    economics: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the records to display.
     */
    records: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the dataset name to display.
     */
    dataSetName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: true,
      kpisDisplayedAsValues: KPI.config.kpisDisplayedAsValues,
      kpisDisplayedAsString: KPI.config.kpisDisplayedAsString,
    };
  },
  methods: {
    getKpiDisplayedAsString(kpiValue) {
      return kpiValue ? kpiValue : '-';
    },
    getKpiDispalyedAsValue(kpiValue) {
      return kpiValue && kpiValue > 0 ? kpiValue.toFixed(2) : '-';
    },
    getKpiDisplayedAsPercentage(kpiValue) {
      if (!kpiValue || kpiValue < 0) {
        return '-';
      }

      const rawPercentage = kpiValue * 100;
      const ceil = Math.ceil(rawPercentage);
      const floor = Math.floor(rawPercentage);
      const delta = ceil - rawPercentage;

      return `${delta <= 0.5 ? ceil : floor}%`;
    },
  },
};
</script>

<style scoped>
.score-card {
  border-bottom: 1px solid var(--elevation-04);
}

.score-card-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.score-card-header h1 {
  font-size: 16px;
}

.data-set-name {
  max-width: 96px;
  margin: 0 8px;
}

.data-set-name > span {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

.header-icon {
  margin-left: -8px;
  color: var(--primary);
}

::v-deep .icon.settings-icon {
  position: absolute;
  right: 0;
  color: var(--primary);
}

::v-deep .definitionList {
  padding-bottom: 12px;
}

::v-deep .definitionItem > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

::v-deep .definitionItem > span > .progressBar {
  width: calc(100% - 32px);
  height: 10px;
  border: none;
  margin-left: 8px;
  border-radius: 2px;
  /* FIXME: #095544 should be setted as variable. :root || .theme || v-deep seems not working */
  background-color: #095544;
}

::v-deep .definitionItem > span > .progressBar > .meter {
  border-radius: 2px;
  background-color: var(--primary);
}

.kpi-value {
  margin-left: 40px;
}

.kpi-percentage {
  width: 32px;
  text-align: right;
}

.kpi-percentage.disabled {
  color: var(--elevation-04);
}

::v-deep .definitionItem.disabled > label {
  color: gray;
}

::v-deep .definitionItem.disabled > span > .progressBar {
  background-color: #0c2b2a;
}

::v-deep .definitionItem.disabled span {
  color: gray;
}

::v-deep .definitionItem > .tooltip {
  z-index: -10 !important;
}

::v-deep .definitionItem.disabled > .tooltip {
  z-index: 10 !important;
  left: -50px;
}

::v-deep .svg-inline--fa.fa-check-circle.fa-w-16 {
  color: var(--elevation-06);
}
</style>
