<template>
    <ch-form-dialog ref="dialog" type="primary" title="Create planogram" @reset="onReset"
                    :close-on-submit="false" @submit="submit">
        <ch-field label="Planogram name" :validators="[nameValidator]">
            <ch-text-input v-model="name" placeholder="Planogram name"/>
        </ch-field>
        <template slot="actions">
            <ch-button cancel size="small" type="secondary">Cancel</ch-button>
            <ch-button submit size="small" type="primary">Create</ch-button>
        </template>
    </ch-form-dialog>
</template>

<script>

    import PhysicalPlanogram from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/PhysicalPlanogram';
	import Info              from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/Info';
	import Null      from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Null';
	import Position  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Position';
	import Rotation  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Rotation';

	export default {
        name: 'CreatePhysicalPlanogramDialog',
        data() {
            return {
                name: null,
                resolve: null,
                reject: null
            };
        },
        methods: {
            /**
             * @param {string|null} name
             * @return {string|null}
             */
            nameValidator(name) {
                if (name === null || !/^(\w|-|\.|\s){1,50}$/.test(name))
                    return 'The name is invalid';
                return null;
            },
            /**
             * @return {Promise<CreatePhysicalPlanogramResponse>}
             */
            open() {
                this.$refs.dialog.open();
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                }).finally(() => this.close());
            },
            close() {
                this.$refs.dialog.close();
            },
            onReset() {
                this.name = null;
                this.width = null;
                this.reject();
            },
            submit() {
				this.$loading.await(
					this.$physicalPlanogram.createPhysicalPlanogram(new PhysicalPlanogram(
						'empty',
						new Info(this.name),
						[],
						new Null('EL0000', new Position(0, 0, 0), new Rotation(0, 0, 0), [])
					), this.$storage.workGroupId)
					.then(r => Promise.awaitTimeout(1000).then(() => r))
				)
					.then(this.resolve.bind(this))
					.catch(this.reject.bind(this));
            }
        }
    };
</script>

<style scoped>

</style>
