import Vue from 'vue';

import store from './store/store.js';
import router from './router.js';

import App from './App';
import { StudioAPI } from './plugins/studioapi/StudioAPI';
import ProjectDownloadManager from './plugins/studioapi/ProjectDownloadManager';
import DownloadManager from './plugins/downloadmanager/DownloadManager';
import UnityManager    from './plugins/unitymanager/UnityManager';
import Chroma          from 'invrsion_chroma';
import 'invrsion_chroma/dist/invrsion_chroma.css';
import 'invrsion_chroma/src/theme.css';
import 'invrsion_chroma/src/global.css';
import 'invrsion_chroma/src/lato.css';
import VueWindowSize from 'vue-window-size';
import './utils/Promise';
import StaticConfig from './config.js';
const Config = appConfig;
window.Config = Config;

const studioAPI = new StudioAPI(
  Config.backendAddress,
  Config.RESTAddress,
  Config.organizationId
);
const unityManager = new UnityManager();

Vue.config.productionTip = false;

Vue.use(Chroma);
Vue.use(studioAPI);
Vue.use(unityManager);
Vue.use(ProjectDownloadManager);
Vue.use(DownloadManager);
Vue.use(VueWindowSize);

import Snotify, { SnotifyPosition } from 'vue-snotify'; // 1. Import Snotify
const opt = {
  toast: {
    position: SnotifyPosition.centerTop,
  },
  global: {
    maxOnScreen: 3,
  },
};

Vue.use(Snotify, opt);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount('#app');
