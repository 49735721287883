<template>
    <div class="market-data-table">
        <div class="market-data-table-header">
            <h1 v-if="totalProducts">
                {{ currentProducts }} / {{ totalProducts }} products
            </h1>
            <ch-pagination
                v-if="totalPages"
                v-model="currentPage"
                :max="totalPages"
            />
        </div>
        <ch-table
            ref="marketDataTable"
            :items="currentPageItems"
        >
            <template slot="header">
                <ch-th
                    v-for="(headerCell, headerIndex) in orderedHeader"
                    :key="headerIndex"
                    :id="headerCell.id"
                    :name="headerCell.label"
                    width="200px"
                />
            </template>
            <template slot-scope="{ row }">
                <ch-td
                    v-for="(header, index) in orderedHeader"
                    :key="index"
                    width="200px"
                >
                    {{ row[currentPageItemsKeys[index]] }}
                </ch-td>
            </template>
        </ch-table>
    </div>
</template>

<script>
  import { options } from "./options.js";

  export default {
    name: "MarketDataTable",
    props: {
      /*
      * Sets the data to display.
      */
      marketData: {
        type: Object,
        default: () => {
        }
      },
      /*
      * Sets number of entries displayed in every page.
      */
      entryPerPage: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        currentPage: 0
      };
    },
    computed: {
      totalProducts() {

        return this.marketData.body
          ? this.marketData.body.length
          : 0;

      },
      currentProducts() {

        if (!this.totalProducts) {
          return this.totalProducts;
        }

        const products = ( this.currentPage + 1 ) * this.entryPerPage;

        return products < this.totalProducts
          ? products
          : this.totalProducts;

      },
      totalPages() {

        return this.totalProducts
          ? Math.ceil(
            this.totalProducts / this.entryPerPage
          )
          : 0;

      },
      pages() {

        return this.totalProducts
          ? this.marketData.body.reduce(
            (
              accumulator,
              currentValue,
              index
            ) => {

              const subarrayIndex = Math.floor( index / this.entryPerPage );
              const page = accumulator[subarrayIndex] || ( accumulator[subarrayIndex] = [] );

              page.push( currentValue );

              return accumulator;

            },
            []
          )
          : [];

      },
      currentPageItems() {

        return this.pages[this.currentPage] || [];

      },
      currentPageItemsKeys() {

        return this.orderedHeader.map(
          header => header.key
        );

      },
      orderedHeader() {

        if (!this.totalProducts) {
          return [];
        }

        const requiredOrAnalytics = options.reduce(
          (
            accumulator,
            currentValue
          ) => {

            const option = this.marketData.header.find(
              header => header.key === currentValue.key
            );

            option && accumulator.push(
              option
            );

            return accumulator;

          },
          []
        );

        const records = this.marketData.header.filter(
          header => header.type === "record"
        );

        return [
          ... requiredOrAnalytics,
          ... records
        ];

      }
    }
  };
</script>

<style scoped>

.market-data-table-header {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.market-data-table-header > * {
    margin: 0 16px;
}

::v-deep .pagination > .position > .inputNumber {
    width: 56px;
}

</style>
