<template>
  <div class="planogram-performance">
    <div class="planogram-performance-header">
      <h3 ellipsis>Planogram performance</h3>
      <ch-toggle
        v-if="!isLegend"
        v-model="isBenchmarkPanelToggled"
        :disabled="isLoading"
        class="planogram-performance-toggle"
        type="primary"
        @input="toggleBenchmarkPanel"
      />
    </div>
    <div v-if="isLegend && shouldLegendRender" class="legend-container">
      <div class="legend-header">
        <h1>{{ benchmarkComparison }}</h1>
        <ch-button size="small narrow" type="overlay" @click="isLegend = false">
          Close
        </ch-button>
      </div>
      <div v-if="isProductDaysOfStock">
        <div
          v-for="(
            { color, legendLabel }, index
          ) in productDaysOfStockRangesAndColors"
          :key="index"
          class="legend-item"
        >
          <div class="legend-item-label">
            <svg class="benchmark-circle" height="12" width="12">
              <circle
                :fill="`rgb(${color.r}, ${color.g}, ${color.b})`"
                cx="6"
                cy="6"
                r="6"
              />
            </svg>
            <span>{{ legendLabel }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="({ color, legendLabel, legendContent }, index) in legend"
          :key="index"
          class="legend-item"
        >
          <div class="legend-item-label">
            <svg class="benchmark-circle" height="12" width="12">
              <circle
                :fill="`rgb(${color.r}, ${color.g}, ${color.b})`"
                cx="6"
                cy="6"
                r="6"
              />
            </svg>
            <span>{{ legendLabel }}</span>
          </div>
          <div class="legend-item-content">
            <span class="legend-item-left">{{
              !index ? legendLabels[0] : ''
            }}</span>
            <span>{{ legendContent }}</span>
            <span :class="!index ? 'legend-item-right' : ''">{{
              !index ? legendLabels[1] : ''
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading" class="loader-container">
      <ch-loader />
    </div>
    <div v-else class="planogram-performance-body">
      <div class="selection-box">
        <div class="selection-box-left">
          <p>View by</p>
          <ch-select
            v-model="currentCategorization"
            :items="categorizationOptions"
            class="categorization-options"
            placeholder="select a view"
            size="narrow"
            @input="handleComparisonSelects()"
          />
        </div>
        <div class="selection-box-right">
          <div class="selection-box-right-header">
            <span>Benchmark</span>
            <div v-tooltip.top="dataSetName" class="data-set-name">
              <span ellipsis>{{ dataSetName }}</span>
              <ch-icon
                class="settings-icon"
                icon="settings"
                size="16"
                @click="handleDataSetAction"
              />
            </div>
          </div>
          <ch-select
            v-model="benchmarkComparison"
            :disabled="!currentCategorization"
            :items="benchmarkComparisonLabels"
            placeholder="select a comparison"
            size="narrow fill"
            @input="handleComparisonSelects()"
          />
        </div>
      </div>
      <div v-if="shouldRenderComparison" class="benchmarks">
        <div
          v-for="{
            label,
            color,
            quota,
            leftValue,
            rightValue,
            isError,
            leftValueShouldBeNormalized,
            rightValueShouldBeNormalized,
            leftValueIsPercentage,
            rightValueIsPercentage,
          } in benchmarks"
          :key="generateBenchmarkKey(label)"
          class="benchmark-row"
        >
          <svg v-if="!isError" class="benchmark-circle" height="12" width="12">
            <circle
              :fill="`rgb(${color.r}, ${color.g}, ${color.b})`"
              cx="6"
              cy="6"
              r="6"
              @click="shouldLegendRender ? (isLegend = true) : null"
            />
          </svg>
          <span
            :class="`benchmark-label ${
              isError ? 'benchmark-label-data-incomplete' : ''
            }`"
            ellipsis
          >
            {{ label }}
          </span>
          <div v-if="isError" class="benchmark-values-wrapper">
            <span class="benchmark-data-incomplete">{{ isError }}</span>
          </div>
          <div v-else class="benchmark-values-wrapper">
            <span v-if="leftValue">
              {{
                leftValueShouldBeNormalized
                  ? normalizeKpiValue(leftValue)
                  : leftValue
              }}{{ leftValueIsPercentage ? '%' : '' }}
            </span>
            <span v-if="quota">{{ `(${quota}m)` }}</span>
            <span v-if="rightValue">
              /
              {{
                rightValueShouldBeNormalized
                  ? normalizeKpiValue(rightValue)
                  : rightValue
              }}{{ rightValueIsPercentage ? '%' : '' }}
            </span>
          </div>
          <performance-histogram
            v-if="!isError && leftValue && rightValue"
            :histogram-color="`rgb(${color.r}, ${color.g}, ${color.b})`"
            :histogram-id="getId()"
            :left-value="leftValue"
            :right-value="rightValue"
          />
        </div>
      </div>

      <div
        v-if="shouldRenderProductDaysOfStockWarning"
        class="no-product-days-of-stock"
      >
        <ch-icon icon="warning" size="32" />
        <p>Day of stock: this benchmark is available at product level only.</p>
        <p>Please set: View by > Product</p>
      </div>
      <div v-if="shouldRenderUpdateComparison" class="no-benchmarks">
        <ch-button
          size="narrow"
          type="primary"
          @click="toggleBenchmarkPanelAndUpdateBenchmarkComparison"
        >
          Update benchmark
        </ch-button>
      </div>
      <div class="planogram-performance-footer">
        <ch-button
          v-if="
            areCategorizationAndBenchmarkComparisonSetted &&
            shouldRenderComparison
          "
          size="narrow"
          type="secondary"
          @click="downloadPdf"
        >
          Download PDF
          <ch-icon icon="file_download" />
        </ch-button>
      </div>
    </div>
  </div>
</template>

<script>
import nanoid from 'nanoid';
import { mapGetters, mapState } from 'vuex';
import Repository from '../../services/Repository';

import PerformanceHistogram from './PerformanceHistogram.vue';
import { BenchmarkComparisonsLabels } from '../../../utils/KPI/enums';
import { KpiErrors } from '../../../utils/KPI/errors';
import {
  benchmarkComparisonsDefinitions,
  kpisWithAlreadyNormalizedResult,
  productBenchmarkComparisonsDefinitionsException,
  productBenchmarkComparisonsException,
} from '../../../utils/KPI/config';
import {
  Comparison,
  comparisonKeyToArg,
  comparisonLabels,
} from '../../../utils/KPI/Comparison';
import histogramRangesAndColors from './planogramPerformanceData';
import productDaysOfStockRangesAndColors from './productDaysOfStockData';
import {
  isComparisonWithQuota,
  isValueWithPercentage,
} from './planogramPerformanceConfig';

export default {
  name: 'PlanogramPerformance',
  components: {
    PerformanceHistogram,
  },
  props: {
    /*
     * Sets the current data set name
     */
    dataSetName: {
      type: String,
      default: 'Choose a dataset',
    },
    /*
     * Sets the current Repository
     */
    repository: {
      type: Repository,
      required: true,
    },
  },
  data() {
    const categorizationOptions = comparisonLabels(
      this.repository
    ).categorizationKeys;
    const benchmarkComparisonLabels = Object.values(
      productBenchmarkComparisonsException
    ).map(label => BenchmarkComparisonsLabels[label]);
    const isBenchmarkPanelToggled =
      this.$store.state.planogramPerformance.isHighlight;
    const currentCategorization =
      this.$store.state.planogramPerformance.selectedCategorization ||
      categorizationOptions[0];
    const benchmarkComparison =
      this.$store.state.planogramPerformance.selectedBenchmarkComparison ||
      benchmarkComparisonLabels[0];

    const viewByDropDownLabels = Object.keys(BenchmarkComparisonsLabels);
    const benchmarkComparisonDropDownLabels = Object.values(
      BenchmarkComparisonsLabels
    );

    return {
      isBenchmarkPanelToggled,
      currentCategorization,
      benchmarkComparison,
      benchmarkDefinitions: {
        ...benchmarkComparisonsDefinitions,
        ...productBenchmarkComparisonsDefinitionsException,
      },
      kpisWithAlreadyNormalizedResult,
      kpiErrors: Object.values(KpiErrors),
      histogramRangesAndColors,
      legend: [...histogramRangesAndColors].reverse(),
      productDaysOfStockRangesAndColors,
      isLegend: false,
      categorizationOptions,
      benchmarkComparisonLabels,
      viewByDropDownLabels,
      benchmarkComparisonDropDownLabels,
    };
  },
  mounted() {
    if (
      this.productsInPlanogramLength < 150 &&
      this.isBenchmarkPanelToggled &&
      (!this.isData || this.bucketsLength !== this.highlightedBucketsLength)
    ) {
      this.updateBenchmarkComparison();
    }
  },
  methods: {
    getId() {
      return `histo_${nanoid(15)}`;
    },
    highlightMarketDataBuckets() {
      if (!this.benchmarks.length) return [];
      return this.benchmarks
        .filter(item => !item.isError)
        .map(benchmark => {
          const { r, g, b } = benchmark.color;
          return {
            bucketIds: (benchmark.bucketsIds.matchingBuckets
              ? benchmark.bucketsIds.matchingBuckets
              : []
            )
              .map(bucket => bucket.bucketId)
              .flat(),
            color: {
              r: r / 255,
              g: g / 255,
              b: b / 255,
            },
          };
        });
    },
    toggleBenchmarkPanelAndUpdateBenchmarkComparison() {
      if (!this.isBenchmarkPanelToggled) {
        this.isBenchmarkPanelToggled = true;
      }
      this.updateBenchmarkComparison();
    },
    handleComparisonSelects() {
      if (!this.isData && !this.isBenchmarkPanelToggled) {
        this.isBenchmarkPanelToggled = true;
      }
      if (
        this.productsInPlanogramLength < 150 &&
        this.isBenchmarkPanelToggled &&
        !this.isDataCached()
      ) {
        this.updateBenchmarkComparison();
      }

      if (this.isBenchmarkPanelToggled && this.isDataCached()) {
        this.handleUnityHighlight(
          this.isBenchmarkPanelToggled ? this.highlightMarketDataBuckets() : []
        );
        this.$store.dispatch(
          'planogramPerformance/updateHighlightedBuckets',
          this.currentBuckets
        );
      }
    },
    generateBenchmarkKey(label) {
      return `${label.split(' ').join('-')}-${nanoid(15)}`;
    },
    isDataCached() {
      return !!(
        this.currentCategorization &&
        this.benchmarkComparisonKey &&
        this.planogramPerformance.data[this.currentCategorization] &&
        this.planogramPerformance.data[this.currentCategorization][
          this.benchmarkComparisonKey
        ]
      );
    },
    async updateBenchmarkComparison() {
      if (this.shouldRenderProductDaysOfStockWarning) {
        return this.handleUnityHighlight([]);
      }

      try {
        !this.isDataCached() && (await this.updatePlanogramPerformanceData());
        await this.handleUnityHighlight(
          this.isBenchmarkPanelToggled ? this.highlightMarketDataBuckets() : []
        );
      } catch (error) {
        console.error(error);
      }
    },
    async updatePlanogramPerformanceData() {
      try {
        this.$store.commit('planogramPerformance/SET_IS_LOADING', true);

        const comparison = this.kpiComparisonKeys
          ? await Comparison.computeKpiOnDemand(
              this.repository,
              comparisonKeyToArg(
                this.currentCategorization,
                comparisonLabels(this.repository)
              ),
              this.kpiComparisonKeys
            )
          : undefined;

        this.$store.commit('planogramPerformance/SET_IS_LOADING', false);

        if (comparison) {
          !this.hasMetaData &&
            (await this.$store.dispatch('planogramPerformance/updateMetaData', {
              repository: this.repository,
              planogram: this.$physicalPlanogram,
              storage: this.$storage,
              retrieveComparisonMetaData: Comparison.retrieveComparisonMetaData,
              planogramPropertyName: 'physicalPlanogram',
            }));

          this.benchmarkComparisonKey &&
            (await this.$store.dispatch('planogramPerformance/updateData', {
              categorizationKey: this.currentCategorization,
              benchmarkComparisonKey: this.benchmarkComparisonKey,
              comparison,
            }));

          this.bucketsLength !== this.highlightedBucketsLength &&
            (await this.$store.dispatch(
              'planogramPerformance/updateHighlightedBuckets',
              this.currentBuckets
            ));
        }
      } catch (error) {
        console.error({
          error,
        });
      }
    },
    async toggleBenchmarkPanel() {
      try {
        this.$store.commit('planogramPerformance/SET_IS_LOADING', true);

        await this.handleUnityHighlight(
          this.isBenchmarkPanelToggled ? this.highlightMarketDataBuckets() : []
        );
        this.$store.dispatch('planogramPerformance/handleHighlight', {
          isHighlight: this.isBenchmarkPanelToggled,
          selectedCategorization: this.currentCategorization,
          selectedBenchmarkComparison: this.benchmarkComparison,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit('planogramPerformance/SET_IS_LOADING', false);
      }
    },
    async handleUnityHighlight(data) {
      try {
        this.$store.commit('planogramPerformance/SET_IS_LOADING', true);

        await this.$unityManager.Dispatch('HighlightMarketDataBucket', {
          highlightMarketDataBuckets: data,
        });

        this.$store.dispatch(
          'planogramPerformance/updateHighlightedBuckets',
          this.currentBuckets
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit('planogramPerformance/SET_IS_LOADING', false);
      }
    },
    downloadPdf() {
      console.log({
        msg: 'TODO: download PDF',
      });
    },
    handleDataSetAction() {
      this.$emit('change-dataset');
    },
    getComparisonColor(leftValue, rightValue) {
      const delta = rightValue - leftValue;

      const comparisonColor = this.histogramRangesAndColors.find(
        rangeAndColor =>
          delta >= rangeAndColor.lowerLimit && delta < rangeAndColor.upperLimit
      );

      return comparisonColor
        ? comparisonColor.color
        : {
            r: 0,
            g: 0,
            b: 0,
          };
    },
    getProductDaysOfStockColor(value) {
      const legend = productDaysOfStockRangesAndColors.find(
        rangeAndColor =>
          value >= rangeAndColor.lowerLimit && value < rangeAndColor.upperLimit
      );

      return legend
        ? legend.color
        : {
            r: 0,
            g: 0,
            b: 0,
          };
    },
    getColor(
      isError,
      rightValue,
      leftValue,
      overlay,
      isComparison,
      comparisonKey
    ) {
      if (comparisonKey === 'productDaysOfStock') {
        return this.getProductDaysOfStockColor(leftValue);
      }

      if (!isComparison) return overlay;

      if (!isError) return this.getComparisonColor(leftValue, rightValue);

      return {
        r: 0,
        g: 0,
        b: 0,
      };
    },
    normalizeKpiValue(kpiValue) {
      return Math.round(kpiValue * 100);
    },
  },
  computed: {
    ...mapState({
      planogramPerformance: state => {
        return {
          data: state.planogramPerformance.data,
          metaData: state.planogramPerformance.metaData,
        };
      },
      isLoading: state => state.planogramPerformance.isLoading,
      isHighlight: state => state.planogramPerformance.isHighlight,
    }),
    ...mapGetters({
      isData: 'planogramPerformance/isData',
      highlightedBucketsLength: 'planogramPerformance/highlightedBucketsLength',
      hasMetaData: 'planogramPerformance/hasMetaData',
    }),
    shouldRenderComparison() {
      return (
        !!this.benchmarks.length && !this.shouldRenderProductDaysOfStockWarning
      );
    },
    shouldRenderProductDaysOfStockWarning() {
      return !(
        (this.currentCategorization === 'product' &&
          this.benchmarkComparison) ||
        (this.currentCategorization !== 'product' &&
          this.benchmarkComparison !== 'Product days of stock')
      );
    },
    shouldRenderUpdateComparison() {
      return (
        !this.benchmarks.length && !this.shouldRenderProductDaysOfStockWarning
      );
    },
    shouldProductDaysOfStockBeComputed() {
      return (
        this.currentCategorization === 'product' &&
        this.benchmarkComparison === 'Product days of stock'
      );
    },
    findDropdownOption() {
      const optionIndex = this.benchmarkComparisonDropDownLabels.indexOf(
        this.benchmarkComparison
      );

      return optionIndex !== -1 ? this.viewByDropDownLabels[optionIndex] : '';
    },
    kpiComparisonKeys() {
      const targetOption = this.findDropdownOption;

      return targetOption
        ? productBenchmarkComparisonsDefinitionsException[targetOption]
        : '';
    },
    benchmarkComparisonKey() {
      const targetOption = this.findDropdownOption;

      return targetOption
        ? productBenchmarkComparisonsException[targetOption]
        : '';
    },
    areCategorizationAndBenchmarkComparisonSetted() {
      return !!(this.currentCategorization && this.benchmarkComparison);
    },
    hasCategorizationOptions() {
      return !!this.categorizationOptions.length;
    },
    currentCategorizationOptions() {
      return (
        (this.currentCategorization &&
          this.hasCategorizationOptions &&
          this.categorizationOptions.includes(this.currentCategorization) &&
          this.isData &&
          this.planogramPerformance.data[this.currentCategorization] &&
          Object.keys(
            this.planogramPerformance.data[this.currentCategorization]
          )) ||
        []
      );
    },
    benchmarkComparisons() {
      return (
        this.currentCategorizationOptions.map(comparisonKey => {
          const [leftKpi, rightKpi] = this.benchmarkDefinitions[comparisonKey];

          const leftValueKey = leftKpi ? leftKpi : '';
          const rightValueKey = rightKpi ? rightKpi : '';

          const shouldRenderQuota =
            isComparisonWithQuota.includes(leftKpi) ||
            isComparisonWithQuota.includes(rightKpi);

          const benchmarks = this.planogramPerformance.data[
            this.currentCategorization
          ][comparisonKey].map(benchmark => {
            const leftValue = benchmark[leftValueKey];
            const rightValue = benchmark[rightValueKey];

            const isError =
              (this.kpiErrors.find(error => error.code === leftValue) ||
                this.kpiErrors.find(error => error.code === rightValue)) &&
              'data incomplete';

            if (isError) {
              return {
                ...benchmark,
                isError,
              };
            }

            const customProperties = {};

            if (leftValueKey) {
              customProperties.leftValueShouldBeNormalized =
                !kpisWithAlreadyNormalizedResult.includes(leftValueKey);
              customProperties.leftValueIsPercentage =
                isValueWithPercentage.includes(leftValueKey);
            }
            if (rightValueKey) {
              customProperties.rightValueShouldBeNormalized =
                !kpisWithAlreadyNormalizedResult.includes(rightValueKey);
              customProperties.rightValueIsPercentage =
                isValueWithPercentage.includes(rightValueKey);
            }

            return {
              ...benchmark,
              ...customProperties,
            };
          });

          return {
            label: BenchmarkComparisonsLabels[comparisonKey],
            comparisonKey,
            leftValueKey,
            rightValueKey,
            benchmarks,
            shouldRenderQuota,
          };
        }) || []
      );
    },
    benchmarks() {
      if (
        !this.currentCategorization ||
        !this.benchmarkComparisonLabels.length
      ) {
        return [];
      }

      const targetCategorization = this.benchmarkComparisons.find(
        categorization => categorization.label === this.benchmarkComparison
      );

      if (!targetCategorization) return [];

      const { benchmarks, leftValueKey, rightValueKey, shouldRenderQuota } =
        targetCategorization;

      return benchmarks.map(benchmark => {
        const leftValue = benchmark[leftValueKey];
        const rightValue = benchmark[rightValueKey];

        const {
          quota,
          label,
          bucketsIds,
          overlay,
          isError,
          leftValueShouldBeNormalized,
          rightValueShouldBeNormalized,
          leftValueIsPercentage,
          rightValueIsPercentage,
        } = benchmark;

        const isComparison = !isError && rightValue && leftValue;
        const comparisonKey = targetCategorization.comparisonKey;

        const color = this.getColor(
          isError,
          rightValue,
          leftValue,
          overlay,
          isComparison,
          comparisonKey
        );

        return {
          bucketsIds,
          color,
          label,
          quota: shouldRenderQuota ? quota : 0,
          leftValue,
          rightValue,
          isError,
          leftValueShouldBeNormalized,
          rightValueShouldBeNormalized,
          leftValueIsPercentage,
          rightValueIsPercentage,
        };
      });
    },
    currentBuckets() {
      return this.repository &&
        this.repository.planogram &&
        this.repository.planogram.fixtures &&
        this.repository.planogram.fixtures.length
        ? this.repository.planogram.fixtures[0].buckets
        : [];
    },
    bucketsLength() {
      return this.currentBuckets.length;
    },
    legendLabels() {
      if (
        !this.areCategorizationAndBenchmarkComparisonSetted &&
        !this.benchmarkComparisonLabels
      ) {
        return [];
      }

      return this.benchmarkComparison
        .split(' VS ')
        .map(
          label => label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
        );
    },
    isProductDaysOfStock() {
      return this.benchmarkComparison === 'Product days of stock';
    },
    shouldLegendRender() {
      return this.isProductDaysOfStock || this.legendLabels.length === 2;
    },
    productsInPlanogramLength() {
      return (
        (this.repository &&
          this.repository.productsInPlanogram &&
          this.repository.productsInPlanogram.length) ||
        0
      );
    },
  },
  beforeDestroy() {
    !this.isBenchmarkPanelToggled && this.handleUnityHighlight([]);
    this.isLegend = false;
  },
};
</script>

<style scoped>
.planogram-performance {
  width: 560px;
}

.planogram-performance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid gray;
}

.planogram-performance-toggle:before {
  content: 'Show';
  font-size: 16px;
  position: absolute;
  right: 40px;
}

.legend-container {
  padding: 16px 0;
}

.legend-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.legend-item {
  display: flex;
  padding: 8px 0;
}

.legend-item-label {
  width: 208px;
  display: flex;
  align-items: center;
}

.legend-item-content {
  width: 320px;
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.legend-item-left {
  width: 84px;
  font-size: 12px;
  color: var(--elevation-05);
}

.legend-item-right {
  width: 120px;
  font-size: 12px;
  margin-left: 12px;
  color: var(--elevation-05);
}

.loader-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 16px;
  border-bottom: 1px solid gray;
}

.selection-box-left > p {
  padding-bottom: 4px;
}

::v-deep
  .select.categorization-options.default
  > div.dropdown
  > div.option
  > span {
  text-transform: capitalize;
}

.selection-box-right {
  width: 320px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.selection-box-right-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}

.data-set-name {
  width: 100%;
  max-width: 160px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.settings-icon {
  margin-left: 8px;
  color: var(--primary);
}

.planogram-performance-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;
}

::v-deep .planogram-performance-footer > button > .icon {
  margin-left: 24px;
}

.no-product-days-of-stock {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-product-days-of-stock p {
  margin-top: 8px;
}

.no-product-days-of-stock ::v-deep .icon {
  margin-bottom: 8px;
}

.no-benchmarks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.benchmarks {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.benchmark-row {
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 2px 0;
}

.benchmark-circle {
  padding: 0 8px;
}
/* TODO: adjust this rule */
.benchmark-values-wrapper {
  width: 120px;
}

.benchmark-label {
  width: 192px;
  padding: 0px 8px;
}

.benchmark-label-data-incomplete {
  margin-left: 30px;
}

.benchmark-data-incomplete {
  display: flex;
  justify-content: center;
  width: 300px;
  color: var(--elevation-04);
}

::v-deep .performance-histogram {
  margin-left: 12px;
}

.planogram-performance-body {
  min-height: 300px;
}
</style>
