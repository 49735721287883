<template>
	<ch-content-dialog ref="dialog" class="propDetailsDialog" type="primary" title="Element detail" size="xl">
		<div v-if="prop" slot="main" class="dialogBody" y-spaced-2 fill>
			<prop-details :prop="prop"/>
			<prop-related-elements :prop="prop" :props="props" @propClick="open"/>
		</div>
	</ch-content-dialog>
</template>

<script>
import PropDetails         from '@/content/assets/components/PropDetails';
import PropRelatedElements from '@/content/assets/components/PropRelatedElements';

export default {
	name: 'PropDetailsDialog',
	components: {PropRelatedElements, PropDetails},
	props: {
		props: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			prop: null
		};
	},
	methods: {
		/**
		 * @param {Prop} prop
		 */
		open(prop) {
			this.$refs.dialog.open();
			this.prop = prop;
		}
	}
};
</script>

<style scoped>

.dialogBody {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	overflow-y: auto;
}

.details > div {
	overflow: hidden;
	flex: 1 1 auto;
}

</style>

<style>
.propDetailsDialog main {
	display: flex !important;
	flex-direction: column;
}
</style>