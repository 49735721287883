<template>
  <ch-application-layout>
    <content-navigation slot="navigation" @currentPath="updatePlanogramType"/>
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <planograms-catalog slot="main" :query="query" />
  </ch-application-layout>
</template>

<script>
import ContentNavigation from "../ContentNavigation";
import StaticConfig from "../../config.js";
import PlanogramsCatalog from "./PlanogramsCatalog";

export default {
  name: "PlanogramsPage",
  components: { PlanogramsCatalog, ContentNavigation },
  props: {
    type: {
	  type: String,
	  default: 'logical'
	},
    matching: String,
    limit: {
      type: Number,
      default: 50,
    },
    page: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      query: {
	    type: this.type,
        limit: this.limit,
        page: this.page,
        matching: this.matching,
        visible: this.visible
      },
    };
  },
  mounted() {
    document.title = "Planograms | ShelfZone Studio";
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
  },
  watch: {
    query: {
      handler() {
        this.$router.push({
          name: "planograms",
          query: {
		    type: this.query.type,
            matching: this.query.matching,
            limit: this.query.limit,
            page: this.query.page,
		    visible: this.query.visible
          },
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updatePlanogramType(type) {
	  this.query.type = type;
	}
  }
};
</script>

<style scoped>
</style>
