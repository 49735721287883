export const namespaced = true;

export const state = {
  data: {},
  metaData: {},
  isLoading: false,
  isHighlight: true,
  highlightedBuckets: [],
  selectedCategorization: '',
  selectedBenchmarkComparison: '',
};

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_META_DATA(state, metaData) {
    state.metaData = metaData;
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_IS_HIGHLIGHT_TOGGLED(state, isHighlight) {
    state.isHighlight = isHighlight;
  },
  SET_HIGHLIGHTED_BUCKETS(state, highlightedBuckets) {
    state.highlightedBuckets = highlightedBuckets;
  },
  SET_SELECTED_CATEGORIZATION(state, selectedCategorization) {
    state.selectedCategorization = selectedCategorization;
  },
  SET_SELECTED_BENCHMARK_COMPARISON(state, selectedBenchmarkComparison) {
    state.selectedBenchmarkComparison = selectedBenchmarkComparison;
  },
};

export const actions = {
  async updateMetaData(
    { commit },
    {
      repository,
      planogram,
      storage,
      retrieveComparisonMetaData,
      planogramPropertyName,
    }
  ) {
    commit('SET_IS_LOADING', true);
    const result = {
      matchCount: null,
      lastUpdateTime: null,
      metaData: null,
    };
    try {
      switch (planogramPropertyName) {
        case 'physicalPlanogram':
          await planogram
            .listPhysicalPlanograms(1, 20, null, true, storage.accountId)
            .then(
              planograms => (result.matchCount = planograms.totalMatchesCount)
            );
          break;
        case 'planogram':
        default:
          await planogram
            .listPlanograms(1, 20, null, true, storage.accountId)
            .then(
              planograms => (result.matchCount = planograms.totalMatchesCount)
            );
          break;
      }

      if (result.matchCount) {
        const chunkLength = 100; // this could be a variable
        const numberOfCycles = Math.ceil(result.matchCount / chunkLength);
        for (let i = 0; i < numberOfCycles; i++) {
          const planograms = await planogram.listPlanograms(
            chunkLength,
            i * 100,
            null,
            true,
            storage.accountId
          );
          const targetPlanogram = planograms.planogramList.find(
            planogram => planogram.id === repository[planogramPropertyName].id
          );

          if (targetPlanogram) {
            result.lastUpdateTime = targetPlanogram.lastUpdateTime;
          }
        }
      } else {
        result.lastUpdateTime = new Date();
      }

      result.metaData = retrieveComparisonMetaData(
        repository,
        result.lastUpdateTime
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      const { metaData } = result;

      if (metaData) {
        commit('SET_META_DATA', metaData);
      }
      commit('SET_IS_LOADING', false);
    }
  },
  handleHighlight(
    { commit },
    { isHighlight, selectedCategorization, selectedBenchmarkComparison }
  ) {
    commit('SET_IS_HIGHLIGHT_TOGGLED', isHighlight);
    commit('SET_SELECTED_CATEGORIZATION', selectedCategorization);
    commit('SET_SELECTED_BENCHMARK_COMPARISON', selectedBenchmarkComparison);
  },
  updateCategorization({ commit }, selectedCategorization) {
    commit('SET_SELECTED_CATEGORIZATION', selectedCategorization);
  },
  updateBenchmarkComparison({ commit }, selectedBenchmarkComparison) {
    commit('SET_SELECTED_BENCHMARK_COMPARISON', selectedBenchmarkComparison);
  },
  updateHighlightedBuckets({ commit }, highlightedBuckets) {
    commit('SET_HIGHLIGHTED_BUCKETS', highlightedBuckets);
  },
  resetState({ commit }, action) {
    switch (action) {
      case 'data':
        commit('SET_DATA', {});
        break;
      case 'metaData':
        commit('SET_META_DATA', {});
        break;
      case 'isLoading':
        commit('SET_IS_LOADING', false);
        break;
      case 'isHighlight':
        commit('SET_IS_HIGHLIGHT_TOGGLED', true);
        break;
      case 'highlightedBuckets':
        commit('SET_HIGHLIGHTED_BUCKETS', []);
        break;
      case 'selectedCategorization':
        commit('SET_SELECTED_CATEGORIZATION', '');
        break;
      case 'selectedBenchmarkComparison':
        commit('SET_SELECTED_BENCHMARK_COMPARISON', '');
        break;
      default:
        commit('SET_DATA', {});
        commit('SET_META_DATA', {});
        commit('SET_IS_LOADING', false);
        commit('SET_IS_HIGHLIGHT_TOGGLED', true);
        commit('SET_SELECTED_CATEGORIZATION', '');
        commit('SET_SELECTED_BENCHMARK_COMPARISON', '');
        break;
    }
  },
  updateData(
    { commit, state },
    { categorizationKey, benchmarkComparisonKey, comparison }
  ) {
    const { data } = state;

    data[categorizationKey] = {
      ...data[categorizationKey],
      [benchmarkComparisonKey]: comparison,
    };

    commit('SET_DATA', { ...data });
  },
};

export const getters = {
  isData: state => !!Object.keys(state.data).length,
  highlightedBucketsLength: state => state.highlightedBuckets.length,
  hasMetaData: state => !!Object.keys(state.metaData).length,
};
