<template>
    <div class="rightBar" fill y-spaced-2>
        <template v-if="selection && selection.selection.length === 1">
            <free-line-info v-if="selectionInfo.type === 'FreeLine'" :controller="controller"
                            :element="selectionInfo"/>
            <planogram-instance-info v-if="selectionInfo.type === 'PlanogramInstance'" :controller="controller"
                                      :element="selectionInfo"/>
            <physical-planogram-instance-info v-if="selectionInfo.type === 'PhysicalPlanogramInstance'" :controller="controller"
                                      :element="selectionInfo"/>
            <planogram-placeholder-instance-info v-else-if="selectionInfo.type ==='PlanogramPlaceholderInstance'"
                                                 :controller="controller" :element="selectionInfo"/>
            <starting-point-info v-if="selectionInfo.type === 'StartingPoint'" :controller="controller"
                                 :element="selectionInfo"/>
            <container-info v-if="selectionInfo.type === 'PlanogramsContainer'" :controller="controller"
                            :element="selectionInfo"/>
            <planogram-placeholder-reference-info v-else-if="selectionInfo.type === 'PlanogramPlaceholderReference'"
                                                  :controller="controller" :asset="selectionInfo"/>
            <planogram-reference-info v-else-if="selectionInfo.type === 'PlanogramReference'" :controller="controller"
                                      :asset="selectionInfo"/>
			<physical-planogram-reference-info v-else-if="selectionInfo.type === 'PhysicalPlanogramReference'" :controller="controller"
											   :asset="selectionInfo"/>
        </template>
        <template v-if="selection && selection.selection.length > 1">
            <multiple-selection-info :controller="controller" :elements="selectionInfo"/>
        </template>
    </div>
</template>

<script>
    import PlanogramInstanceInfo             from './PlanogramInstanceInfo';
    import ContainerInfo                     from './PlanogramContainerInfo';
    import PlanogramPlaceholderInstanceInfo  from './PlanogramPlaceholderInstanceInfo';
    import PlanogramPlaceholderReferenceInfo from './PlanogramPlaceholderReferenceInfo';
    import LayoutController                  from '../services/LayoutController';
    import SelectionController               from '../services/SelectionController';
    import FreeLineInfo                      from './FreeLineInfo';
    import StartingPointInfo                 from './StartingPointInfo';
    import PlanogramReferenceInfo            from './PlanogramReferenceInfo';
    import MultipleSelectionInfo             from './MultipleSelectionInfo';
	import PhysicalPlanogramReferenceInfo
											 from '@/spaceallocation/layouteditor/rightsidebar/PhysicalPlanogramReferenceInfo';
	import PhysicalPlanogramInstanceInfo
											 from '@/spaceallocation/layouteditor/rightsidebar/PhysicalPlanogramInstanceInfo';

    export default {
        name: 'RightLayoutSideBar',
        components: {
			PhysicalPlanogramInstanceInfo,
			PhysicalPlanogramReferenceInfo,
            MultipleSelectionInfo,
            PlanogramReferenceInfo,
            StartingPointInfo,
            FreeLineInfo, PlanogramInstanceInfo, PlanogramPlaceholderInstanceInfo, PlanogramPlaceholderReferenceInfo, ContainerInfo
        },
        props: {
            controller: LayoutController,
            selection: SelectionController
        },
        data() {
            return {};
        },
        computed: {
            selectionInfo() {
                if (this.selection && this.selection.selection.length === 1)
                {
                    return this.selection.selection[0];
                }
                return this.selection ? this.selection.selection : null;
            }
        }
    };
</script>

<style scoped>

    .rightBar {
        padding: var(--doubleMargin);
        box-sizing: border-box;
    }

</style>
