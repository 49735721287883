import Vue                       from 'vue';
import Router                    from 'vue-router';
import Login                     from './login/Login';
import ResetPassword             from './login/password/reset/ResetPassword';
import AccountCreation           from './accountcreation/AccountCreation';
import Main                      from './main/Main';
import Admin                     from './admin/Admin';
import Queue                     from './admin/Queue';
import Fields                    from './research/Fields';
import FieldCells                from './research/FieldCells';
import FieldInterviews           from './research/FieldInterviews';
import Analysis                  from './research/Analysis';
import Projects                  from './projectsmanagement/Projects';
import ProjectDetails            from './projectsmanagement/ProjectDetails';
import LayoutEditor              from './spaceallocation/layouteditor/LayoutEditor';
import PlanogramEditor           from '@/spaceallocation/planogrameditor/ui/PlanogramEditor';
import PhysicalPlanogramEditor   from './spaceallocation/physicalplanogrameditor/ui/PhysicalPlanogramEditor';
import SkuCatalog                from './skucatalog/SkuCatalog';
import Classification            from './admin/Classification';
import AccountDisabled           from './login/AccountDisabled';
import LicenseExpired            from './login/LicenseExpired';
import PasswordExpireSoon        from './login/PasswordExpireSoon';
import ChangePassword            from './login/password/change/ChangePassword';
import PasswordChanged           from './login/password/change/PasswordChanged';
import PasswordResetTokenInvalid from './login/password/reset/ResetTokenInvalid';
import PasswordResetRequested    from './login/password/reset/PasswordResetRequested';
import PageNotFound              from '@/404/PageNotFound';

import PlanogramsPage   from './content/planograms/PlanogramsPage';
import PlaceholdersPage from './content/placeholders/PlaceholdersPage';
import AssetsPage       from './content/assets/AssetsPage';

import Store           from './store/store';
import PasswordExpired from './login/PasswordExpired';
import Account         from '@/user/Account';
import Info            from '@/user/Info';
import WorkGroups      from '@/user/workgroups/WorkGroups';
import WorkGroup       from '@/user/workgroups/WorkGroup';
import AccountSwitch   from '@/main/AccountSwitch';

Vue.use(Router);

function getAnalysisPageProps(route) {
	return Object.assign({}, route.params, route.query);
}

const router = new Router(
	{
		mode: 'history',
		routes: [
			{
				path: '/login',
				name: 'login',
				component: Login,
				props: (route) => ({
					redirect: route.query.redirect,
					loginOnly: route.query.loginOnly
				})
			},
			{
				path: '/licenseExpired',
				name: 'LicenseExpired',
				component: LicenseExpired
			},
			{
				path: '/accountDisabled',
				name: 'AccountDisabled',
				component: AccountDisabled
			},
			{
				path: '/password/expiring',
				name: 'passwordExpireSoon',
				component: PasswordExpireSoon,
				props: route => ({
					expirationTime: route.query.expirationTime.toString(),
					redirect: route.query.redirect,
					identityKey: route.query.identityKey
				})
			},
			{
				path: '/password/expired',
				name: 'passwordExpired',
				component: PasswordExpired,
				props: route => ({
					expiredAt: route.query.expiredAt
				})
			},
			{
				path: '/password/change',
				name: 'changePassword',
				component: ChangePassword
			},
			{
				path: '/password/change/complete',
				name: 'passwordChanged',
				component: PasswordChanged
			},
			{
				path: '/password/reset',
				name: 'resetPassword',
				component: ResetPassword,
				props: route => ({resetToken: route.query.resetToken})
			},
			{
				path: '/password/reset/invalid',
				name: 'passwordResetTokenInvalid',
				component: PasswordResetTokenInvalid
			},
			{
				path: '/password/reset/sent',
				name: 'passwordResetRequested',
				component: PasswordResetRequested,
				props: route => ({emailAddress: route.query.emailAddress})
			},
			{
				path: '/register',
				name: 'register',
				component: AccountCreation,
				props: route => ({invitationId: route.query.invitationId})
			},
			{
				path: '/switch/:workGroupId?',
				name: 'accountSwitch',
				component: AccountSwitch,
				props: true,
				meta: {requiresAuth: true}
			},
			{
				path: '/', component: Main, meta: {requiresAuth: true}, children:
					[
						{
							path: 'admin',
							name: 'admin', component: Admin, meta: {isAdmin: true}
						},
						{
							path: 'admin/classification',
							name: 'classification', component: Classification, meta: {isAdmin: true}
						},
						{
							path: 'account',
							name: 'account',
							component: Account,
							children: [
								{
									path: 'info',
									name: 'accountInfo',
									component: Info,
									meta: {voice: 'accountInfo'}
								},
								{
									path: 'workgroups',
									name: 'workGroups',
									component: WorkGroups,
									meta: {voice: 'workGroups'}
								},
								{
									path: 'workgroups/:workGroupId',
									name: 'workgroup',
									component: WorkGroup,
									props: true,
									meta: {voice: 'workGroups'}
								}
							]
						},
						{
							path: 'queue',
							name: 'queue', component: Queue,
							meta: {isAdmin: true}
						},
						{
							path: 'fields',
							name: 'fields', component: Fields
						},
						{
							path: 'fields/:fieldId',
							name: 'field', redirect: '/fields/:fieldId/cells'
						},
						{
							path: 'fields/:fieldId/cells',
							name: 'cells', component: FieldCells, props: true
						},
						{
							path: 'fields/:fieldId/cells/:cellId/analysis',
							name: 'analysis', component: Analysis, props: getAnalysisPageProps
						},
						{
							path: 'fields/:fieldId/interviews',
							name: 'interviews', component: FieldInterviews, props: true
						},
						{
							path: 'projects',
							name: 'projects', component: Projects
						},
						{
							path: 'projects/:projectId/configurations/:configurationId',
							name: 'projectConfiguration', component: LayoutEditor, props: true
						},
						{
							path: 'configurations/:configurationId',
							name: 'configuration', component: LayoutEditor, props: true
						},
						{
							path: 'projects/:projectId',
							name: 'projectDetails', component: ProjectDetails, props: true
						},
						{
							path: 'planograms/:planogramId',
							name: 'planogram', component: PlanogramEditor, props: true
						},
						{
							path: 'physicalPlanograms/:physicalPlanogramId',
							name: 'physicalPlanogram', component: PhysicalPlanogramEditor, props: true
						},
						{
							path: 'sku',
							name: 'sku', component: SkuCatalog, props: route => route.query
						},
						{
							path: 'content/planograms',
							name: 'planograms', component: PlanogramsPage, props: route => ({
								type: route.query.type || 'logical',
								limit: Number.parseInt(route.query.limit) || 50,
								page: Number.parseInt(route.query.page) || 0,
								matching: route.query.matching,
								visible: route.query.visible === 'true' || true
							})
						},
						{
							path: 'content/placeholders',
							name: 'placeholders', component: PlaceholdersPage, props: route => route.query
						},
						{
							path: 'content/assets',
							name: 'assets', component: AssetsPage, props: route => ({
								limit: Number.parseInt(route.query.limit) || 50,
								page: Number.parseInt(route.query.page) || 0,
								deprecated: route.query.deprecated === 'true',
								type: route.query.type,
								matching: route.query.matching,
								view: route.query.view
							})
						},
						{
							path: 'pageNotFound',
							name: 'pageNotFound',
							component: PageNotFound
						},
						{
							path: '*',
							redirect: '/projects'
						}
					]
			},
			{
				path: '*',
				redirect: '/login'
			}
		]
	});

router.beforeEach((to, from, next) => {
	const authenticationRequired = to.matched.some(route => route.meta.requiresAuth);
	if (!authenticationRequired || Store.state.logged)
		if (!to.matched.some(route => route.meta.isAdmin) || window['studioApi'].storage.user.isAdmin)
			next();
		else
			next({name: 'projects', replace: true});
	else
		next({name: 'login', query: {redirect: to.fullPath}, replace: true});
});

router.onError((error) => {
	switch (error.response.status) {
		case 404:
			return router.replace({name: 'pageNotFound', replace: true});
		default:
			return router.replace(error);
	}
});

export default router;
