<template>
	<div y-spaced-2 class="accordion">
		<div class="header">
			<div class="title" @click="isExpanded = ! isExpanded">
				<ch-icon
					primary-text
					size="32"
					:icon="isExpanded ? 'arrow_drop_down' : 'arrow_right'"
				/>
				<h2 primary-text>{{ title }}</h2>
			</div>
			<slot name="titleAppend"></slot>
		</div>
		<div v-if="isExpanded" class="body">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Accordion',
	props: {
		title: String
	},
	data() {
		return {
			isExpanded: true
		};
	}
};
</script>

<style scoped>

.accordion {
	border-top: 1px solid var(--elevation-04);
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header > div:first-child {
	display: flex;
	align-items: center;
}

.title {
	cursor: pointer;
}

</style>