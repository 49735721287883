<template>
  <div class="productsLibrary">
    <div class="filters" y-spaced-2>
      <div class="actions" x-spaced-2>
        <ch-button
          @click="$refs.productFinder.open()"
          type="secondary"
          size="fill narrow"
          justified
          >Add products
          <ch-icon icon="fas fa-plus-circle" size="12" />
        </ch-button>
        <ch-button
          @click="openAddElementDialog"
          type="secondary"
          size="fill narrow"
          justified
          :disabled="cameraView !== 'Front'"
          >Add element
          <ch-icon icon="fas fa-plus-circle" size="12" />
        </ch-button>
      </div>
      <ch-button
        @click="$emit('market-data-clicked')"
        type="secondary"
        size="fill narrow"
        justified
      >
        <div class="marketDataBtnLabel">
          <label-tag label="BETA" size="small" />
          <span>Market data</span>
        </div>
        <ch-icon icon="far fa-chart-bar" />
      </ch-button>
      <div>
        <ch-search-bar v-model="searchValue" realTime size="fill narrow" />
      </div>
      <div>
        <div v-if="!searchValue">
          {{ products.length }} products ({{ usedProducts.size }} used)
        </div>
        <div v-else>
          {{ filteredProducts.length }} products found on
          {{ products.length }} total ({{ usedProducts.size }} used)
        </div>
      </div>
      <ch-radio-group type="primary" size="narrow fill" v-model="productFilter">
        <ch-radio-button :label="0">All</ch-radio-button>
        <ch-radio-button :label="1">Used</ch-radio-button>
        <ch-radio-button :label="2">Not used</ch-radio-button>
      </ch-radio-group>
    </div>
    <div class="products">
      <ch-grid-layout
        v-if="filteredProducts.length"
        :width="130"
        :height="160"
        :gap="16"
      >
        <div
          class="product"
          v-for="(product, index) in filteredProducts"
          :key="product.id + index"
          draggable="true"
          @mouseenter="onProductHovered(product.id)"
          @mouseleave="onProductLeave(product.id)"
          @dragstart="onDragStart(product, $event)"
          :id="product.id"
        >
          <div
            class="productImage"
            :used="usedProducts.has(product.id)"
            :title="product.info.name"
          >
            <ch-image
              v-if="product.id.startsWith('PRD')"
              :src="
                `${baseURL}/products/${product.id}/photos/thumbnail_front.png`
              "
              :id="product.id + '_img'"
            />
            <div
              v-else
              class="productNotInCatalog"
              :style="'background: ' + colorFromId(product.id)"
            ></div>
          </div>
          <div
            v-if="!product.images || Object.keys(product.images).length === 0"
            class="productLabel"
            ellipsis
          >
            {{ product.info.name.capitalize() }}
          </div>
          <div
            v-else
            class="productLabel productInCatalog"
            @click="showProductDetails(product)"
            ellipsis
          >
            {{ product.info.name.capitalize() }}
          </div>
        </div>
      </ch-grid-layout>
      <ch-empty-content
        v-else-if="products.length === 0"
        first-message="No products added to the planogram"
      />
      <ch-empty-content
        v-else
        first-message="No products matching the given query"
      />
    </div>
    <product-finder
      ref="productFinder"
      :disabledProducts="products"
      @addProducts="addProducts"
    />
    <add-element-dialog
      ref="elementDetails"
      :repository="repository"
      :selectionManager="selectionManager"
      :cameraView="cameraView"
    />
    <product-details ref="productDetails" />
    <ch-button
      class="downloadProducts"
      type="primary"
      fab
      :disabled="filteredProducts.length === 0"
      @click="downloadProducts"
    >
      <ch-icon icon="file_download" />
    </ch-button>
    <ch-loader v-if="loading" fullscreen />
  </div>
</template>

<script>
import ProductDetails from '../../../../skucatalog/ProductDetails';
import AddElementDialog from '../dialog/AddElementDialog';
import ProductFinder from '../../../../skucatalog/ProductFinder';
import SelectionManager from '../../managers/SelectionManager';
import { PlanogramService } from '../../services/PlanogramService';
import { ProductService } from '../../services/ProductService';
import { View } from '../../model/unit/View';
import Repository from '../../services/Repository';
import StaticConfig from '../../../../config.js';
import { CSVConverter } from '../../services/CSVConverter';
import { ColorService } from '../../../layouteditor/services/ColorService';

import LabelTag from '../../ui/LabelTag.vue';

export default {
  name: 'ProductsLibrary',
  components: {
    ProductFinder,
    AddElementDialog,
    ProductDetails,
    LabelTag,
  },
  props: {
    repository: Repository,
    selectionManager: SelectionManager,
    cameraView: String,
  },
  data() {
    return {
      loading: false,
      searchValue: '',
      productFilter: 0,
      transparentImage: (() => {
        const img = new Image();
        img.src =
          'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        return img;
      })(),
    };
  },
  computed: {
    /**
     * @return {string}
     */
    baseURL() {
      return Config.RESTAddress;
    },
    editor() {
      return this.$parent.$parent.$parent.$parent.$parent;
    },
    /**
     * @return {Product[]}
     */
    products() {
      return this.repository.planogram.productSet;
    },
    /**
     * @return {Set<string>}
     */
    usedProducts() {
      return new Set(
        this.repository.planogram.fixtures.flatMap(f =>
          f.buckets.map(b => b.productId)
        )
      );
    },
    /**
     * @return {Product[]}
     */
    filteredProducts() {
      let results = this.products;
      if (this.searchValue) {
        const s = this.searchValue.toLowerCase();
        results = results.filter(
          product =>
            product.info.name.toLowerCase().includes(s) ||
            product.ean.includes(s)
        );
      }
      if (this.productFilter === 1) {
        results = results.filter(p => this.usedProducts.has(p.id));
      } else if (this.productFilter === 2) {
        results = results.filter(p => !this.usedProducts.has(p.id));
      }
      return results.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
  },
  methods: {
    /**
     * @param {Product} product
     * @param {DragEvent} event
     */
    onDragStart(product, event) {
      event.dataTransfer.setDragImage(this.transparentImage, 0, 0);
      event.dataTransfer.setData('text/plain', product);
      const view = this.cameraView === 'Front' ? View.Front : View.Bottom;
      this.$unityManager.Dispatch('DragProduct', {
        productId: product.id,
        view,
      });
    },
    openAddElementDialog() {
      this.$refs.elementDetails.open();
    },
    /**
     * @param {Product} product
     */
    showProductDetails(product) {
      this.$productBank
        .findProductById(product.id)
        .then(r =>
          this.$refs.productDetails.open(
            r.product,
            this.repository.categorization
          )
        );
    },
    /**
     * @param {string} productId
     */
    onProductHovered(productId) {
      const matchedBuckets = PlanogramService.findBucketsWithProduct(
        this.repository.planogram,
        productId
      ).map(b => b.id);
      this.$unityManager.Dispatch('HighlightBuckets', {
        bucketIds: matchedBuckets,
        color: { r: 1, g: 1, b: 1 },
      });
    },
    /**
     * @param {string} productId
     */
    onProductLeave(productId) {
      this.$unityManager.Dispatch('HighlightBuckets', {
        bucketIds: [],
        color: { r: 1, g: 1, b: 1 },
      });
    },
    /**
     * @param {string[]} productIds
     */
    addProducts(productIds) {
      if (productIds.length === 0) return;
      this.$productBank.findProductsById(productIds, false).then(response => {
        response.foundProducts.forEach(product =>
          this.repository.addAdditionalProductInfo(product)
        );
        const products = response.foundProducts.map(
          ProductService.translateProductFromProductBank
        );
        this.$unityManager.Dispatch('AddProducts', { products });
      });
    },
    /**
     * @param {string} id
     * @return {string}
     */
    colorFromId(id) {
      return ColorService.colorFromString(id).toString();
    },
    downloadProducts() {
      const csv = CSVConverter.convertProducts(
        this.filteredProducts,
        this.repository.categorization,
        this.repository.additionalProductsInfo
      );
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.toString())
      );
      element.setAttribute(
        'download',
        `${this.repository.planogram.name.trim()} - Products.csv`
      );
      element.click();
    },
  },
};
</script>

<style scoped>
.productsLibrary {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.productsLibrary > * {
  padding: 0 var(--doubleMargin);
  flex: 0 0 auto;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: var(--doubleMargin);
}

.actions {
  display: flex;
  /* flex-wrap: no-wrap; */
}

.products {
  flex: 1 1 auto;
  padding-top: var(--doubleMargin);
  border-top: 1px solid var(--elevation-06);
  box-sizing: border-box;
  overflow: auto;
}

.product {
  display: flex;
  flex-direction: column;
  background-color: unset !important;
  padding: 0 !important;
  outline: none;
}

.productImage {
  flex: 0 0 auto;
  width: 130px;
  height: 130px;
  background-color: var(--elevation-04);
  border-radius: 4px;
  padding: var(--singleMargin);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage[used] {
  background-color: var(--elevation-05);
}

.productImage:hover {
  background-color: var(--primary);
}

.productNotInCatalog {
  width: 80px;
  height: 80px;
}

.productLabel {
  flex: 0 0 auto;
  font-size: 11px;
  margin-top: var(--singleMargin);
  text-transform: capitalize;
  color: var(--on-elevation-02);
}

.productInCatalog {
  cursor: pointer;
  text-decoration: underline;
}

.productInCatalog:hover {
  color: var(--primary);
}

.downloadProducts {
  bottom: 16px !important;
  right: 16px !important;
}

.marketDataBtnLabel {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 136px;
}
</style>
