<template>
    <accordion title="Positions info" class="voice">
        <ch-dl label-width="50%">
            <ch-dd label="Units">{{stockInfo.units}}</ch-dd>
            <ch-dd label="Unit dimensions (cm)">
				<size :width="stockInfo.unitSize.w" :height="stockInfo.unitSize.h" :depth="stockInfo.unitSize.d"/>
            </ch-dd>
            <ch-dd label="Position linear space">{{localLinear / 10}} cm</ch-dd>
            <ch-dd label="Product linear space">{{totalLinear / 10}} cm</ch-dd>
			<ch-dd label="Vertical facing">
				<ch-number-input type="primary" :value="bucket.facing.v" :min="1"
								 @input="v => changeFacing(bucket.facing.h, v, bucket.facing.d)"/>
			</ch-dd>
			<ch-dd label="Horizontal facing">
				<ch-number-input type="primary" :value="bucket.facing.h" :min="1"
								 @input="h => changeFacing(h, bucket.facing.v, bucket.facing.d)"/>
			</ch-dd>
			<ch-dd label="Depth facing">
				<ch-number-input type="primary" :value="bucket.facing.d" :min="1"
								 @input="d => changeFacing(bucket.facing.h, bucket.facing.v, d)"/>
			</ch-dd>
            <ch-dd label="Product spacing">
                <ch-number-input type="primary" :value="bucket.padding.w" @input="changePadding" :min="0"
                                 symbol="mm"/>
            </ch-dd>
            <ch-dd label="Product tilt">
                <ch-number-input type="primary" :value="bucket.tilt" @input="changeTilt" :min="0" :max="90"
                                 :step="5" symbol="°"/>
            </ch-dd>
            <ch-dd label="Vertical crush">
                <ch-number-input type="primary" :value="crushFactor.u * 100" :min="0" :max="99" symbol="%"
                                 @input="v => changeCrushFactor(crushFactor.f, v / 100, crushFactor.r)"/>
            </ch-dd>
            <ch-dd label="Horizontal crush">
                <ch-number-input type="primary" :value="crushFactor.f * 100" :min="0" :max="99" symbol="%"
                                 @input="h => changeCrushFactor(h / 100, crushFactor.u, crushFactor.r)"/>
            </ch-dd>
            <ch-dd label="Depth crush">
                <ch-number-input type="primary" :value="crushFactor.r * 100" :min="0" :max="99" symbol="%"
                                 @input="d => changeCrushFactor(crushFactor.f, crushFactor.u, d / 100)"/>
            </ch-dd>
        </ch-dl>
    </accordion>
</template>

<script>

    import CrushFactor from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/CrushFactor';
	import Padding     from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Padding';
	import Size        from '@/components/Size';
	import Facing      from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Facing';
	import StockInfo   from '@/spaceallocation/physicalplanogrameditor/model/StockInfo';
	import Accordion   from '@/components/Accordion';

	export default {
        name: 'StockInfo',
		components: {Accordion, Size},
		props: {
            stockInfo: StockInfo
        },
        data() {
            return {
                isExpanded: true,
            };
        },
        computed: {
        	/**
			 * @return {Bucket}
			 */
        	bucket() {
        		return this.stockInfo.bucket;
			},
            /**
             * @return {number}
             */
            localLinear() {
                return Math.round(this.stockInfo.localLinear * 10) / 10;
            },
            /**
             * @return {number}
             */
            totalLinear() {
                return Math.round(this.stockInfo.totalLinear * 10) / 10;
            },
            /**
             * @return {CrushFactor}
             */
            crushFactor() {
                const crushFactor = this.bucket.crushFactor;
                return new CrushFactor(
                    Math.round(crushFactor.f * 100) / 100,
                    Math.round(crushFactor.r * 100) / 100,
                    Math.round(crushFactor.u * 100) / 100
                )
            }
        },
        methods: {
			/**
			 * @param {number} h
			 * @param {number} v
			 * @param {number} d
			 */
			changeFacing(h, v, d) {
				this.bucket.preferredFacing = new Facing(h, v, d);
				this.$unityManager.planogramAPI.ReplaceElement(this.bucket);
			},
            /**
             * @param {number} value
             */
            changePadding(value) {
				this.bucket.padding = new Padding(value, 0, 0);
				this.$unityManager.planogramAPI.ReplaceElement(this.bucket);
            },
            /**
             * @param {number} value
             */
            changeTilt(value) {
				this.bucket.tilt = value;
				this.$unityManager.planogramAPI.ReplaceElement(this.bucket);
            },
            /**
             * @param {number} f
             * @param {number} r
             * @param {number} u
             */
            changeCrushFactor(f, r, u) {
				this.bucket.crushFactor = new CrushFactor(f, r, u);
				this.$unityManager.planogramAPI.ReplaceElement(this.bucket);
            }
        }
    };
</script>

<style scoped>

.voice {
	padding: var(--doubleMargin);
}

</style>