<template>
	<ch-button @click="isolateElements" size="fill" justified>Isolate
		<ch-icon icon="visibility"/>
	</ch-button>
</template>

<script>
export default {
	name: 'IsolateElementsButton',
	props: {
		elementsIds: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		isolateElements() {
			this.$unityManager.planogramAPI.IsolateElements(this.elementsIds);
		}
	}
};
</script>

<style scoped>

</style>