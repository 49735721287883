<template>
    <div class="bucketsInfo" fill>
        <div class="info" y-spaced-2>
            <h1 primary-text>Multiple selection</h1>
            <ch-dl label-width="50%">
                <ch-dd label="Products">{{info.products.length}}</ch-dd>
                <ch-dd label="Positions">{{info.positions.length}}</ch-dd>
                <ch-dd label="Units">{{info.units}}</ch-dd>
            </ch-dl>
        </div>
        <div class="multipleSelectionActions"  y-spaced-2>
            <replace-products-button :repository="repository" :productIds="info.positions.map(p => p.productId)"/>
            <group-selection-actions :repository="repository" :selectionManager="selectionManager"
                                     :productIds="info.products"/>
        </div>
    </div>
</template>

<script>
    import {BucketService}       from '../../../services/BucketService';
    import {FMath}               from '../../../../utils/FMath';
    import {PlanogramService}    from '../../../services/PlanogramService';
    import Repository            from '../../../services/Repository';
    import SelectionManager      from '../../../managers/SelectionManager';
    import GroupSelectionActions from '../../trade/GroupSelectionActions';
    import ReplaceProductsButton from '../product/ReplaceProductsButton';

    export default {
        name: 'MultipleBucketsInfo',
        components: {ReplaceProductsButton, GroupSelectionActions},
        props: {
            bucketsIds: Array,
            repository: Repository,
            selectionManager: SelectionManager
        },
        mounted(){
            window.getSelection().removeAllRanges()
        },
        computed: {
            /**
             * @return {string}
             */
            planogramId() { return this.repository.planogram.id; },
            /**
             * @return {{positions: Bucket[], units: number, products: string[]}}
             */
            info() {
                const buckets = PlanogramService.findBucketWithIds(this.repository.planogram, this.bucketsIds);
                return {
                    products: BucketService.getBucketsProducts(buckets),
                    positions: buckets,
                    units: buckets.map(BucketService.getBucketUnits).reduce(FMath.sum)
                };
            }
        }
    };
</script>

<style scoped>

    .bucketsInfo {
        display: flex;
        flex-direction: column;
    }

    .info {
        flex: 1 1 auto;
        border-bottom: 1px solid var(--elevation-04);
        padding: var(--doubleMargin);
    }

    .multipleSelectionActions {
        padding: var(--doubleMargin);
    }

</style>
