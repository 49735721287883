<template>
	<div y-spaced-1>
		<ch-search-bar v-model="matching" :hints-promise="hintsPromise" real-time size="fill" @hintClick="addPhysicalPlanogram">
			<div slot-scope="{hint}">
				<div elevation-01-text>{{ hint.name }}</div>
			</div>
		</ch-search-bar>
		<div class="planograms">
			<ch-list>
				<ch-list-item v-for="physicalPlanogram of value" :key="physicalPlanogram.id" >
					{{ physicalPlanogram.info.name }}
					<ch-icon slot="actions" icon="close" @click="removePhysicalPlanogram(physicalPlanogram)"/>
				</ch-list-item>
			</ch-list>
		</div>
	</div>
</template>

<script>
const _ = require('lodash');

export default {
	name: 'FindPhysicalPlanogramList',
	props: {
		ownerId: String,
		value: Array
	},
	data() {
		return {
			matching: null,
			debouncedFindPhysicalPlanograms: null,
			hintsPromise: null
		};
	},
	computed: {
		currentValue() {
			return this.value;
		}
	},
	watch: {
		matching() {
			this.debouncedFindPhysicalPlanograms();
		}
	},
	mounted() {
		this.debouncedFindPhysicalPlanograms = _.debounce(this.findPhysicalPlanograms, 500);
	},
	methods: {
		addPhysicalPlanogram(physicalPlanogram) {
			this.matching = null;
			this.$emit('input', [...this.value, physicalPlanogram]);
		},
		removePhysicalPlanogram(physicalPlanogram) {
			this.$emit('input', this.value.filter(m => m !== physicalPlanogram));
		},
		findPhysicalPlanograms() {
			if (!this.matching)
				this.hintsPromise = null;
			else
				this.hintsPromise =
					this.$physicalPlanogram.listPhysicalPlanograms(20, 0, this.matching, true, this.ownerId)
						.then(response => {
							const physicalPlanogramIds = this.value.map(p => p.id);
							return response.physicalPlanogramList.filter(p => !physicalPlanogramIds.includes(p.id))
								.slice(0, 5);
						});
		}
	}
};
</script>

<style scoped>

.planograms {
	overflow-y: auto;
}

</style>
