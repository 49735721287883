<template>
	<div class="fixtureInfo" v-if="optFixture.nonEmpty()">
		<div class="info">
			<div class="infoSection">
				<h1 primary-text>Element info</h1>
				<ch-dl label-width="50%" v-if="optProp.nonEmpty()">
					<!--				TODO Add open of the prop dialog-->
					<ch-dd label="Asset">{{ prop.name }}</ch-dd>
					<ch-dd label="Type">{{prop.archetypeType | formatArchetypeType}}</ch-dd>
					<ch-dd label="Dimensions (cm)">
						<size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/>
					</ch-dd>
				</ch-dl>
			</div>
			<div class="infoSection allocationSpaces" y-spaced-1 v-if="allocationSpacesSelect.length > 0">
				<h2 primary-text>Allocation spaces</h2>
				<div class="allocationSpaceRow">
					<ch-select :items="allocationSpacesSelect" v-model="currentAllocationSpaceId"
							   @itemMouseEnter="id => highlightElements([id])"
							   @itemMouseLeave="id => removeElementsHighlight([id])"
							   size="large narrow"/>
					<ch-button v-if="currentAllocationSpace.nonEmpty()" icon type="overlay"
							   @click="isolateElement(currentAllocationSpaceId)">
						<ch-icon icon="visibility"/>
					</ch-button>
				</div>
				<div class="allocationSpaceRow" v-if="alignmentIcons.length > 0">
					<span>Align</span>
					<div x-spaced-1>
						<ch-button icon v-for="alignmentIcon of alignmentIcons"
								   :key="alignmentIcon"
								   :type="getAlignmentIconType(alignmentIcon.alignment)"
								   @click="changeAllocationSpaceAlignment(alignmentIcon.alignment)">
							<ch-icon :icon="alignmentIcon.icon"/>
						</ch-button>
					</div>
				</div>
			</div>
			<transform class="infoSection" :element="fixture"/>
			<div class="infoSection childAssets" v-if="childrenFixtures.length > 0" y-spaced-2>
				<h2 primary-text>Child assets</h2>
				<ch-list size="narrow">
					<ch-list-item class="childListItem" v-for="child of childrenFixtures"
								  :key="child.elementId"
								  @mouseenter.native="highlightElements([child.elementId])"
								  @mouseleave.native="removeElementsHighlight([child.elementId])"
								  @click="selectElement(child.elementId)">
						{{ child.name }}
						<ch-icon slot="actions" icon="visibility" @click.stop="isolateElement(child.elementId)"/>
					</ch-list-item>
				</ch-list>
			</div>
			<div class="infoSection childAssets" v-if="productAllocationSpaces.length > 0" y-spaced-2>
				<h2 primary-text>Total products</h2>
				<ch-dl label-width="50%">
					<ch-dd label="Products">{{ stockInUnits.productsCount }}</ch-dd>
					<ch-dd label="Units">{{ stockInUnits.unitsCount }}</ch-dd>
				</ch-dl>
			</div>
		</div>
		<div class="actions" y-spaced-2>
			<combine-elements-button :fixtures="[fixture]"/>
			<isolate-elements-button :elements-ids="[fixtureId]"/>
			<remove-elements-button :elements-ids="[fixtureId]"/>
		</div>
	</div>
</template>

<script>

import {BucketService}    from '../../../services/BucketService';
import Repository                 from '../../../services/Repository';
import {PhysicalPlanogramService} from '../../../services/PhysicalPlanogramService';
import HorizontalProductAllocationSpace
								  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/HorizontalProductAllocationSpace';
import FreeProductAllocationSpace
							 from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/FreeProductAllocationSpace';
import HungProductAllocationSpace
						  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/HungProductAllocationSpace';
import {Alignment}        from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Alignment';
import Transform          from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/Transform';
import Fixture            from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Fixture';
import Bucket             from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Bucket';
import Size               from '@/components/Size';
import CombineElementsButton from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/CombineElementsButton';
import {formatArchetypeType} from '@/content/assets/Utils';
import RemoveElementsButton
							 from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/RemoveElementsButton';
import IsolateElementsButton
							 from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/IsolateElementsButton';
import { toOption } from "@/spaceallocation/utils/Option";

export default {
	name: 'FixtureInfo',
	components: {IsolateElementsButton, RemoveElementsButton, CombineElementsButton, Size, Transform},
	props: {
		fixtureId: String,
		repository: Repository,
		selectionManager: Object
	},
	data() {
		return {
			HorizontalProductAllocationSpace,
			Alignment,
			currentAllocationSpaceId: null
		};
	},
	computed: {
		/**
		 * @return {Option<Fixture>}
		 */
		optFixture() {
			return PhysicalPlanogramService.findFixtureById(this.repository.physicalPlanogram, this.fixtureId);
		},
		/**
		 * @return {Fixture}
		 */
		fixture() {
			return this.optFixture.get();
		},
		/**
		 * @return {Option<PropAsset>}
		 */
		optProp() {
			return this.optFixture.flatMap(f => PhysicalPlanogramService.findPropAssetById(this.repository.physicalPlanogram, f.fixtureCore.assetId));
		},
		/**
		 * @return {PropAsset}
		 */
		prop() {
			return this.optProp.get();
		},
		/**
		 * @return {IProductAllocationSpace[]}
		 */
		productAllocationSpaces() {
			const allocationSpacesTypes = [
				HorizontalProductAllocationSpace.staticType,
				FreeProductAllocationSpace.staticType,
				HungProductAllocationSpace.staticType
			];
			return this.optFixture.map(f => f.children.filter(child => allocationSpacesTypes.includes(child.type)))
				.getOrElse([]);
		},
		/**
		 * @return {{key: string, label: string}[]}
		 */
		allocationSpacesSelect() {
			return this.productAllocationSpaces
				.sort((a1, a2) => a1.position.x > a2.position.x ? 1 : a1.position.x < a2.position.x ? -1 : a1.position.y > a2.position.y ? 1 : -1)
				.map((allocationSpace, i) => ({key: allocationSpace.id, label: `${allocationSpace.combineId ? 'Combined ' : ''}Allocation Space ${i + 1}`}));
		},
		/**
		 * @return {Option<IProductAllocationSpace>}
		 */
		currentAllocationSpace() {
			return this.optFixture.flatMap(f => toOption(f.children.find(child => child.id === this.currentAllocationSpaceId)));
		},
		/**
		 * @return {{alignment: Alignment, icon: string}[]}
		 */
		alignmentIcons() {
			if (!this.currentAllocationSpace.some(a => a.type === HorizontalProductAllocationSpace.staticType)) return [];
			return [
				{alignment: Alignment.Left, icon: 'align_horizontal_left'},
				{alignment: Alignment.Justified, icon: 'horizontal_distribute'},
				{alignment: Alignment.Right, icon: 'align_horizontal_right'},
				{alignment: Alignment.Free, icon: 'align_vertical_bottom'}
			];
		},
		/**
		 * @return {{productsCount: number, unitsCount: number}}
		 */
		stockInUnits() {
			const buckets = this.productAllocationSpaces
				.flatMap(a => a.children)
				.filter(child => child.type === Bucket.staticType);
			return {
				productsCount: new Set(buckets.map(b => b.productId)).size,
				unitsCount: buckets.map(BucketService.getBucketUnits).reduce((s, u) => s + u, 0)
			};
		},
		/**
		 * @return {{elementId: string, name: string}[]}
		 */
		childrenFixtures() {
			return this.optFixture
				.map(fixture => fixture.children
					.flatMap(child => [child, ...child.children])
					.filter(child => child.type === Fixture.staticType)
					.map(fixture => ({
						elementId: fixture.id,
						name: PhysicalPlanogramService.findPropAssetById(this.repository.physicalPlanogram, fixture.fixtureCore.assetId).map(prop => prop.name).getOrElse('Asset')
					}))
				)
				.getOrElse([]);
		}
	},
	watch: {
		productAllocationSpaces: {
			handler() {
				if (!this.productAllocationSpaces.some(a => a.id === this.currentAllocationSpaceId)) {
					if (this.productAllocationSpaces.length > 0)
						this.currentAllocationSpaceId = this.productAllocationSpaces[0].id;
					else
						this.currentAllocationSpaceId = null;
				}
			},
			immediate: true
		}
	},
	filters: {
		/**
		 * @param {ArchetypeType} archetypeType
		 * @return {string}
		 */
		formatArchetypeType(archetypeType) { return formatArchetypeType(archetypeType); }
	},
	methods: {
		/**
		 * @param {Alignment} alignment
		 */
		getAlignmentIconType(alignment) {
			return this.currentAllocationSpace.some(a => a.alignment === alignment) ? 'primary' : 'default';
		},
		/**
		 * @param {Alignment} alignment
		 */
		changeAllocationSpaceAlignment(alignment) {
			this.currentAllocationSpace.get().alignment = alignment;
			this.$unityManager.planogramAPI.ChangeHorizontalProductAllocationSpaceAlignment(this.currentAllocationSpace.get().id, alignment);
		},
		/**
		 * @param {string[]} elementIds
		 */
		highlightElements(elementIds) {
			this.$unityManager.planogramAPI.HighlightElements(elementIds);
		},
		/**
		 * @param {string[]} elementIds
		 */
		removeElementsHighlight(elementIds) {
			this.$unityManager.planogramAPI.RemoveElementsHighlight(elementIds);
		},
		/**
		 * @param {string} elementId
		 */
		selectElement(elementId) {
			this.$unityManager.planogramAPI.SelectElements([elementId]);
		},
		/**
		 * @param {string} elementId
		 */
		isolateElement(elementId) {
			this.$unityManager.planogramAPI.IsolateElements([elementId]);
		},
		deleteFixture() {
			this.$unityManager.planogramAPI.DeleteElements([this.fixtureId]);
		}
	}

};
</script>

<style scoped>

.fixtureInfo {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-content: stretch;
	overflow: auto;
}

.info {
	flex: 1 1 auto;
	overflow-y: auto;
	border-bottom: 1px solid var(--elevation-04);
}

.infoSection {
	padding: var(--doubleMargin);
}

.infoSection:not(:last-child) {
	border-bottom: 1px solid var(--elevation-04);
}

.actions {
	flex: 0 0 auto;
	padding: var(--doubleMargin);
}

.allocationSpaceRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.childListItem {
	cursor: pointer;
}

</style>