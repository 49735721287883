<template>
	<div class="decorationInfo" v-if="optDecoration.nonEmpty()">
		<div class="info">
			<div class="infoSection">
				<h1 primary-text>Element info</h1>
				<ch-dl label-width="50%" v-if="optProp.nonEmpty()">
					<!--				TODO Add open of the prop dialog-->
					<ch-dd label="Asset">{{ prop.name }}</ch-dd>
					<ch-dd label="Type">Decoration</ch-dd>
					<ch-dd label="Dimensions (cm)">
						<size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/>
					</ch-dd>
				</ch-dl>
			</div>
			<transform class="infoSection" :element="decoration"/>
		</div>
		<div class="actions" y-spaced-2>
			<isolate-elements-button :elements-ids="[decorationId]"/>
			<remove-elements-button :elements-ids="[decorationId]"/>
		</div>
	</div>
</template>

<script>

import Repository                 from '../../../services/Repository';
import {PhysicalPlanogramService} from '../../../services/PhysicalPlanogramService';
import Transform                  from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/Transform';
import Size                       from '@/components/Size';
import RemoveElementsButton
								  from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/RemoveElementsButton';
import IsolateElementsButton
								  from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/IsolateElementsButton';

export default {
	name: 'DecorationInfo',
	components: {IsolateElementsButton, RemoveElementsButton, Size, Transform},
	props: {
		decorationId: String,
		repository: Repository,
		selectionManager: Object
	},
	computed: {
		/**
		 * @return {Option<StaticDecoration>}
		 */
		optDecoration() {
			return PhysicalPlanogramService.findStaticDecorationById(this.repository.physicalPlanogram, this.decorationId);
		},
		/**
		 * @return {StaticDecoration}
		 */
		decoration() {
			return this.optDecoration.get();
		},
		/**
		 * @return {Option<PropAsset>}
		 */
		optProp() {
			return this.optDecoration.flatMap(d => PhysicalPlanogramService.findPropAssetById(this.repository.physicalPlanogram, d.assetId));
		},
		/**
		 * @return {PropAsset}
		 */
		prop() {
			return this.optProp.get();
		}
	},
	methods: {
		/**
		 * @param {string} elementId
		 */
		isolateElement(elementId) {
			this.$unityManager.planogramAPI.IsolateElements(elementId);
		},
		deleteDecoration() {
			this.$unityManager.planogramAPI.DeleteElements([this.decorationId]);
		}
	}

};
</script>

<style scoped>

.decorationInfo {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-content: stretch;
	overflow: auto;
}

.info {
	flex: 1 1 auto;
	overflow-y: auto;
	border-bottom: 1px solid var(--elevation-04);
}

.infoSection {
	padding: var(--doubleMargin);
}

.infoSection:not(:last-child) {
	border-bottom: 1px solid var(--elevation-04);
}

.actions {
	flex: 0 0 auto;
	padding: var(--doubleMargin);
}

</style>