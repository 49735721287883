<template>
	<ch-button @click="removeElements" size="fill" type="error" justified>Remove
		<ch-icon icon="cancel"/>
	</ch-button>
</template>

<script>
export default {
	name: 'RemoveElementsButton',
	props: {
		elementsIds: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		removeElements() {
			this.$unityManager.planogramAPI.DeleteElements(this.elementsIds);
		}
	}
};
</script>

<style scoped>

</style>