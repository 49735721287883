<template>
	<ch-form-dialog ref="dialog" title="Copy Planogram" type="primary"
	                :close-on-submit="false" @reset="reset" @submit="submit">
		<p>Copy planogram from</p>
		<ch-radio v-model="targetType" label="WorkGroup">WorkGroup</ch-radio>
		<ch-select v-model="workGroupId" :disabled="targetType !== 'WorkGroup'" :items="workGroupsItems" size="fill"
		           placeholder="Search workgroup" searchable>
			<div slot-scope="{item}" x-spaced-1>
				<ch-icon icon="fas fa-user-friends"/>
				<span>{{ item.label }}</span></div>
		</ch-select>
		<ch-radio v-model="targetType" label="User" :disabled="isInOwnAccount">
			<identity-info :id="$storage.user.id" hide-you>
				<template slot="append">'s library</template>
			</identity-info>
		</ch-radio>
		<div class="planograms">
			<ch-field :validators="[nonEmpty]">
				<find-physical-planogram-list :ownerId="ownerId" v-model="physicalPlanograms"/>
			</ch-field>
		</div>
		<template slot="actions">
			<ch-button type="secondary" size="small" cancel>Cancel</ch-button>
			<ch-button type="primary" size="small" submit>Copy</ch-button>
		</template>
	</ch-form-dialog>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
import FindPhysicalPlanogramList from '@/spaceallocation/physicalplanogrameditor/ui/components/FindPhysicalPlanogramList';

export default {
	name: 'CopyPhysicalPlanogramFromDialog',
	components: {FindPhysicalPlanogramList, IdentityInfo},
	data() {
		return {
			workGroups: [],
			targetType: 'WorkGroup',
			workGroupId: null,
			physicalPlanograms: [],
			resolve: null,
			reject: null
		};
	},
	computed: {
		workGroupsItems() {
			return this.workGroups.map(w => {
				const isCurrent = w.id === this.$storage.workGroupId;
				return {
					key: w.id,
					label: w.name + (isCurrent ? ' (current)' : ''),
					disabled: isCurrent
				};
			});
		},
		isInOwnAccount() {
			return !this.$storage.workGroupId;
		},
		ownerId() {
			return this.targetType === 'User' ? this.$storage.user.id : this.workGroupId;
		}
	},
	watch: {
		targetType() {
			this.$refs.dialog.$refs.form.validate();
			this.physicalPlanograms = [];
		},
		workGroupId() {
			this.physicalPlanograms = [];
		}
	},
	methods: {
		nonEmpty(v) {
			if (v.length === 0)
				return 'No planograms selected';
			return null;
		},
		open() {
			this.$loading.await(this.$iam.listWorkGroups())
				.then(({workGroups}) => {
					this.workGroups = workGroups;
					this.$refs.dialog.open();
				});
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			})
		},
		reset() {
			this.workGroups = [];
			this.targetType = 'WorkGroup';
			this.workGroupId = null;
			this.physicalPlanograms = [];
			this.reject();
			this.resolve = this.reject = null;
		},
		submit() {
			const workGroupId = this.$storage.workGroupId;
			this.$loading.await(Promise.all(
				this.physicalPlanograms.map(physicalPlanogram => this.$physicalPlanogram.clonePhysicalPlanogram(physicalPlanogram.id, null, workGroupId))
			)).then(() => {
				this.resolve();
				this.$snotify.success('Planograms successfully copied!', {timeout: 5000, showProgressBar: false});
				this.$refs.dialog.close()
			});
		}
	}
};
</script>

<style scoped>

.planograms {
	height: 240px;
}

</style>
