<template>
    <ch-dialog ref="dialog" type="primary" title="Add planograms to configuration" size="fullscreen"
               @confirm="addAssets" @cancel="close">
        <ch-application-layout slot="main">
			<ch-accordion-menu slot="navigation" :active="activeSection">
				<ch-accordion-voice key="Planograms" label="Planograms" @click="activeSection = 'Planograms'">
					<ch-tree v-model="planogramPath" :nodes="planogramsNodes"/>
				</ch-accordion-voice>
				<ch-accordion-voice key="Placeholders"  label="Placeholders" @click="activeSection = 'Placeholders'"/>
			</ch-accordion-menu>
			<template slot="main">
				<planograms-catalog v-if="activeSection === 'Planograms'" selectable v-model="assetsToAdd" :disabled="usedAssets" :query="{
					limit: 50,
                    page: 0,
                    matching: null,
                    visible: true,
					type: planogramType
				}"/>
				<placeholders-catalog v-if="activeSection === 'Placeholders'" selectable v-model="assetsToAdd" :disabled="usedAssets"/>
			</template>
            <div class="sidebar" slot="sidebar" fill y-spaced-1>
                <h1 primary-text>Adding planograms</h1>
                <h4>{{assetsToAdd.length}} planograms will be added</h4>
                <ch-button type="secondary" size="small narrow" @click="assetsToAdd = []">Clear</ch-button>
                <ch-list class="assetsToAdd" removable size="narrow">
                    <ch-list-item v-for="asset of assetsToAdd" @remove="removeAssetToAdd(asset)">
                        <div x-spaced-1>
                            <ch-indicator :color="colorFromId(asset.id)"
                                          :striped="asset.type === 'PlanogramPlaceholder'"/>
                            <span>{{asset.name}}</span>
                        </div>
                    </ch-list-item>
                </ch-list>
                <div class="actions" x-spaced-2>
                    <ch-button type="secondary" size="small" @click="close">Close</ch-button>
                    <ch-button size="small" type="primary" @click="addAssets">Add</ch-button>
                </div>
            </div>
        </ch-application-layout>
    </ch-dialog>
</template>

<script>

    import PlaceholdersCatalog        from '../../../content/placeholders/PlaceholdersCatalog';
    import PlanogramsCatalog          from '../../../content/planograms/PlanogramsCatalog';
    import {ColorService}             from '../services/ColorService';
    import LayoutController           from '../services/LayoutController';
	import PlanogramReference
									  from '@/plugins/studioapi/api/spaceallocation/model/configuration/asset/PlanogramReference';
	import PlanogramPlaceholderReference
									  from '@/plugins/studioapi/api/spaceallocation/model/configuration/asset/PlanogramPlaceholderReference';
	import PhysicalPlanogramReference
									  from '@/plugins/studioapi/api/spaceallocation/model/configuration/asset/PhysicalPlanogramReference';

    export default {
        name: 'AddAssetsDialog',
        components: {PlanogramsCatalog, PlaceholdersCatalog},
        props: {
            controller: LayoutController
        },
        data() {
            return {
                activeSection: 'Planograms',
				planogramType: 'logical',
                assetsToAdd: []
            };
        },
        computed: {
            usedAssets() {
                return this.controller.configuration.library
                    .filter(a => a.type === PlanogramPlaceholderReference.staticType || a.type === PlanogramReference.staticType || a.type === PhysicalPlanogramReference.staticType)
                    .map(a => ({
						id: a.type === PlanogramReference.staticType ? a.planogramId :
							a.type === PhysicalPlanogramReference.staticType ? a.physicalPlanogramId :
							a.planogramPlaceholderId
					}));
            },
			planogramPath: {
				get() {
					return this.planogramsNodes.filter(n => n.id === this.planogramType);
				},
				set(path) {
					this.planogramType = path[0].id;
				}
			},
			planogramsNodes() {
				return [
					{
						id: 'physical',
						text: 'Physical'
					},
					{
						id: 'logical',
						text: 'Logical'
					}
				];
			}
        },
        methods: {
            open() {
                this.assetsToAdd = [];
                this.$refs.dialog.open();
            },
            close() {
                this.$refs.dialog.close();
            },
            removeAssetToAdd(asset) {
                this.assetsToAdd = this.assetsToAdd.filter(p => p.id !== asset.id);
            },
            colorFromId(id) {
                return ColorService.colorFromString(id).toString();
            },
            addAssets() {
                const planograms = this.assetsToAdd.filter(p => p.type === 'Planogram');
				const physicalPlanograms = this.assetsToAdd.filter(p => p.type === 'PhysicalPlanogram');
                const placeholders = this.assetsToAdd.filter(p => p.type === 'PlanogramPlaceholder');
                if (planograms.length > 0)
                    this.$emit('planogramsAdded', planograms);
				if(physicalPlanograms.length > 0)
					this.$emit('physicalPlanogramsAdded', physicalPlanograms);
                if (placeholders.length > 0)
                    this.$emit('placeholdersAdded', placeholders);
                this.close();
            }
        }
    };
</script>

<style scoped>

    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: var(--doubleMargin);
    }

    .assetsToAdd {
        flex: 1 1 auto;
    }

    .asset {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        background: white;
        color: black;
        border-radius: 4px;
        padding: 0 var(--singleMargin);
        font-weight: 600;
        margin-bottom: 4px;
        user-select: none;
        cursor: pointer;
    }

    .asset:hover {
        background-color: var(--primary);
        color: var(--on-primary);
    }

    .planogramListEntry span {
        margin-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
    }

</style>
