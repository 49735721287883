<template>
    <div>
        <ch-button @click="replaceProducts" type="primary" size="fill" justified>Replace product
            <ch-icon icon="sync"/>
        </ch-button>
        <replace-products-dialog ref="replaceProductsDialog" :repository="repository"/>
    </div>
</template>

<script>
    import {PhysicalPlanogramService} from '../../../services/PhysicalPlanogramService';
    import ReplaceProductsDialog      from '../../dialog/ReplaceProductsDialog';
    import Repository            from '../../../services/Repository';

    export default {
        name: 'ReplaceProductsButton',
        components: {ReplaceProductsDialog},
        props: {
            repository: Repository,
            productAssetIds: Array
        },
        methods: {
            replaceProducts() {
                const products = this.productAssetIds
                    .filter((id, i) => this.productAssetIds.indexOf(id) === i)
                    .map(id => PhysicalPlanogramService.findProductAssetById(this.repository.physicalPlanogram, id))
                    .filter(p => p.nonEmpty())
                    .map(p => p.get());
                this.$refs.replaceProductsDialog.open(this.repository.categorization, products, PhysicalPlanogramService.listProductAssets(this.repository.physicalPlanogram));
            }
        }
    };
</script>

<style scoped>

</style>