<template>
  <div>
    <h2 class="title" primary-text>
      <span>Product info</span>
      <ch-button
        v-if="product.productId"
        slot="titleAction"
        justified
        size="narrow"
        type="primary"
        @click="updateProduct"
        >Update
        <ch-icon icon="sync" />
      </ch-button>
    </h2>
    <ch-dl label-width="50%">
      <ch-dd label="Product">
        <div
          :class="product.productId ? 'productName' : null"
          @click="showProductDetails(product)"
        >
          {{ product.name.capitalize() }}
        </div>
      </ch-dd>
      <ch-dd label="EAN">{{ product.ean || 'Not available' }}</ch-dd>
    </ch-dl>
    <product-details ref="productDetails" />
    <ch-modal ref="modal" />
  </div>
</template>

<script>
import Repository from '../../../services/Repository';
import ProductDetails from '../../../../../skucatalog/ProductDetails';
import ProductAsset from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/product/ProductAsset';
import Size from '@/components/Size';
import { ProductService } from '@/spaceallocation/physicalplanogrameditor/services/ProductService';

export default {
  name: 'ProductInfo',
  components: {
    Size,
    ProductDetails,
  },
  props: {
    repository: Repository,
    product: ProductAsset | Object,
  },
  methods: {
    showProductDetails() {
      if (!this.product.productId) return;
      this.$productBank
        .findProductById(this.product.productId)
        .then(r =>
          this.$refs.productDetails.open(
            r.product,
            this.repository.categorization
          )
        );
    },
    updateProduct() {
      this.$refs.modal
        .open(
          'Update product',
          'Update the product could change its size and facing, planogram could then become invalid, please control the result.',
          'alert'
        )
        .then(() => {
          this.$productBank
            .findProductById(this.product.productId, false)
            .then(response => {
              const updatedProduct =
                ProductService.translateProductFromProductBank(
                  this.product.id,
                  response.product
                );
              this.$unityManager.planogramAPI.ReplaceAssets([updatedProduct]);
            })
            .catch(() =>
              this.$snotify.error(`Error updating product`, {
                timeout: 5000,
                showProgressBar: false,
              })
            );
        })
        .catch(() => console.log('Product updated failed'));
    },
  },
};
</script>

<style scoped>
.voice {
  padding: var(--doubleMargin);
}

.title {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.title > * {
  flex: 1 1 0;
}

.productName {
  text-decoration: underline;
  cursor: pointer;
}
</style>
