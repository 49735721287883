<template>
    <ch-dialog ref="dialog" type="primary" title="Replace products" size="fullscreen" important
               class="replaceDialog">
        <ch-application-layout slot="main" headerSize="small">
            <div slot="header" y-spaced-2 class="header">
                <ch-filters>
                    <div x-spaced-1>
<!--                        <ch-radio-group size="small narrow" v-model="productType">-->
<!--                            <ch-radio-button label="all">All</ch-radio-button>-->
<!--                            <ch-radio-button label="multiple">No updates</ch-radio-button>-->
<!--                        </ch-radio-group>-->
                        <span>{{products.length}} products</span>
                    </div>
                    <div></div>
                    <div>
                        <ch-search-bar v-model="query" size="narrow" real-time/>
                    </div>
                </ch-filters>
            </div>
            <div slot="main" class="products">
                <template v-for="product of filteredProducts">
                    <product-selection-table v-if="productsByEan" :key="product.ean"
                                             v-model="chosenProducts[product.ean]"
                                             :product="product"
                                             :available-products="productsByEan[product.ean]"
                                             @showProductDetails="showProductDetails"/>
                </template>
                <ch-empty-content v-if="products.length > 0 && filteredProducts.length === 0"
                                  first-message="No products match the query"/>
                <ch-empty-content v-if="products.length === 0" first-message="No products found in the planogram"/>
            </div>
        </ch-application-layout>
        <template slot="footer">
            <ch-button type="secondary" size="small" @click="close">Cancel</ch-button>
            <ch-button type="primary" size="small" @click="replaceProducts">Replace</ch-button>
            <product-details ref="productDetails"/>
        </template>
    </ch-dialog>
</template>

<script>
    import ProductDetails        from '../../../../skucatalog/ProductDetails';
    import ProductSelectionTable from '../components/ProductSelectionTable';
    import Repository         from '../../services/Repository';

    export default {
        name: 'ReplaceProductsDialog',
        components: {ProductSelectionTable, ProductDetails},
        props: {
            repository: Repository
        },
        data() {
            return {
                categorization: null,
                products: [],
                productsByEan: null,
                chosenProducts: null,
                productType: 'all',
                query: null
            };
        },
        computed: {
            filteredProducts() {
                let filteredProducts = this.query ? this.products.filter(p => p.ean.includes(this.query)) : this.products;
                switch (this.productType) {
                    case 'found':
                        filteredProducts = filteredProducts.filter(e => !this.isSelected(e));
                        break;
                    case 'notFound':
                        filteredProducts = filteredProducts.filter(this.isSelected);
                        break;
                    case 'multiple':
                        filteredProducts = filteredProducts.filter(p => this.existMultipleProductsWithSameEan(p.ean));
                        break;
                }
                return filteredProducts;
            },
        },
        methods: {
            /**
             * @param categorization
             * @param {ProductAsset[]} productsToReplace
             * @param {ProductAsset[]} allProducts
             */
            open(categorization, productsToReplace, allProducts) {
                this.$refs.dialog.open();
                this.products = productsToReplace;
                this.categorization = categorization;
                this.$loading.await(this.retrieveProducts(productsToReplace, allProducts));
            },
            /**
             * @param {ProductAsset[]} products
             * @param {ProductAsset[]} allProducts
             * @return {Promise<FindProductsByEanListResponse>}
             */
            retrieveProducts(products, allProducts) {
                return this.$productBank.findProductsByEanList(products.map(p => p.ean))
                    .then(response => {
                        this.chosenProducts = this.products.reduce((o, p) => {
                            o[p.ean] = p.productId;
                            return o;
                        }, {});
                        this.productsByEan = this.products.reduce((o, p) => {
                        	o[p.ean] = [];
                        	return o;
						}, {})
                        response.productsSummaries.forEach(productSummary => {
                        	productSummary.size = {
                        		width: productSummary.width,
								height: productSummary.height,
								depth: productSummary.depth,
							};
                            if (!(productSummary.ean in this.productsByEan))
                                this.productsByEan[productSummary.ean] = [];
                            this.productsByEan[productSummary.ean].push(productSummary);
                        });
                    });
            },
            isSelected(product) {
                return this.chosenProducts[product.ean] === product.id;
            },
            showProductDetails(productId) {
                this.$loading.await(
                    this.$productBank.findProductById(productId)
                        .then(response => this.$refs.productDetails.open(response.product, this.categorization))
                );
            },
            existMultipleProductsWithSameEan(ean) {
                const productsInCatalog = this.productsByEan[ean];
                return productsInCatalog ? productsInCatalog.length > 1 : false;
            },
            replaceProducts() {
            	this.products.forEach(product => product.productId = this.chosenProducts[product.ean]);
				this.$unityManager.planogramAPI.ReplaceAssets(this.products);
				this.close();
            },
            close() {
                this.$refs.dialog.close();
                this.categorization = null;
                this.planogram = null;
                this.chosenProducts = null;
                this.productsByEan = null;
            }
        }
    };
</script>

<style scoped>

    .products {
        overflow-y: auto;
        padding: 0 var(--quadrupleMargin);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .replaceDialog .header {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: center;
    }

</style>
