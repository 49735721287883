<template>
  <div class="bucketInfo" fill>
    <ch-tabs v-model="selectedTab">
      <template slot="voices">
        <ch-tab-voice key="info">Info</ch-tab-voice>
        <ch-tab-voice key="edit">Edit</ch-tab-voice>
      </template>
      <template slot="tabs">
        <ch-tab-content key="info" class="infoTab">
          <div class="bucketDescription" y-spaced-2>
            <product-info
              :product="product.get()"
              :repository="repository"
              class="productInfo"
            />
          </div>
          <product-detailed-info
            :product="product.get()"
            :repository="repository"
          />
          <div class="bucketDescription" y-spaced-2>
            <market-data-score-card
              :data-set-name="dataSetName"
              :economics="economics"
              :kpis="kpis"
              :records="records"
              @change-dataset="$emit('change-dataset')"
            />
          </div>
        </ch-tab-content>
        <ch-tab-content key="edit">
          <product-info :product="product.get()" :repository="repository" />
          <product-dimensions :product="product.get()" />
          <bucket-transform :element="bucket.get()" />
          <stock-info
            v-if="stockInfo.nonEmpty()"
            :stockInfo="stockInfo.get()"
          />
        </ch-tab-content>
      </template>
    </ch-tabs>
    <bucket-actions
      v-if="bucket.nonEmpty()"
      :bucket="bucket.get()"
      :cameraView="cameraView"
      :repository="repository"
      :selectionManager="selectionManager"
      @createGroup="$emit('createGroup')"
    />
  </div>
</template>

<script>
import { PhysicalPlanogramService } from '../../../services/PhysicalPlanogramService';
import BucketActions from './BucketActions';
import ProductInfo from '../product/ProductInfo';
import StockInfo from './StockInfo';
import Repository from '../../../services/Repository';
import SelectionManager from '../../../managers/SelectionManager';

import MarketDataScoreCard from './MarketDataScoreCard.vue';

import ProductDimensions from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/product/ProductDimensions';
import ProductDetailedInfo from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/product/ProductDetailedInfo';
import BucketTransform from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/bucket/BucketTransform';
import { Some } from '@/spaceallocation/utils/Option';
import {
  defaultMonthDays,
  defaultWeekDays,
  productsKpis,
} from '@/spaceallocation/utils/KPI/config';
import { kpiImportOptions } from '@/spaceallocation/utils/KPI/kpiImportOptions';
import { ProductService } from '@/spaceallocation/planogrameditor/services/ProductService';
import Mathematics from '@/spaceallocation/utils/KPI/helpers/mathematics';
import KPI from '../../../../utils/KPI/index';

const { productKpiCalc } = Mathematics;

export default {
  name: 'BucketInfo',
  components: {
    BucketTransform,
    ProductDetailedInfo,
    ProductDimensions,
    StockInfo,
    ProductInfo,
    BucketActions,
    MarketDataScoreCard,
  },
  props: {
    repository: Repository,
    selectionManager: SelectionManager,
    bucketId: String,
    cameraView: String,
  },
  data() {
    return {
      selectedTab: 'info',
    };
  },
  computed: {
    hasCurrentMarketDataPool() {
      return this.repository.currentMarketDataPool.nonEmpty();
    },
    dataSetName() {
      if (this.repository.currentMarketDataPool.nonEmpty()) {
        return this.repository.currentMarketDataPool.get().info.name;
      }

      return 'Choose a dataset';
    },
    currentMarketDataPoolId() {
      if (this.repository.currentMarketDataPool.nonEmpty()) {
        return this.repository.currentMarketDataPool.get().id;
      }

      return '';
    },
    dataSets() {
      return this.repository.listMarketDataPoolsSummary.map(summary => {
        return {
          id: summary.id,
          ...summary.info,
        };
      });
    },
    weekDays() {
      return defaultWeekDays;
    },
    monthDays() {
      return defaultMonthDays;
    },
    kpis() {
      const kpis = [];
      if (
        this.repository.currentMarketDataPool.nonEmpty() &&
        this.repository.currentMarketDataPool.get().importedData &&
        this.product.get()
      ) {
        const importedDataAndRepository = [
          this.repository.currentMarketDataPool.get().importedData,
          this.product.get(),
          this.repository,
        ];
        const additionalArgs = {
          [KPI.common.keys.unitShareInUnits]: [false],
          [KPI.common.keys.unitShareInPercent]: [true],
          [KPI.common.keys.dailyAverageSalesRate]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateFiveDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateSixDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.weeklyAverageSalesRateSevenDays]: [
            this.weekDays,
            this.monthDays,
          ],
          [KPI.common.keys.monthlyAverageSalesRate]: [
            this.weekDays,
            this.monthDays,
          ],
        };
        productsKpis.forEach(kpiKey => {
          const result = productKpiCalc(
            kpiKey,
            importedDataAndRepository,
            additionalArgs
          );
          result && kpis.push(result);
        });
      } else {
        kpis.push(
          {
            label: KPI.common.labels.categoryValueShare,
            value: null,
          },
          {
            label: KPI.common.labels.categoryUnitShare,
            value: null,
          },
          {
            label: KPI.common.labels.valueMargin,
            value: null,
          },
          {
            label: KPI.common.labels.categoryMarginShare,
            value: null,
          },
          {
            label: KPI.common.labels.percOfStoresSelling,
            value: null,
          },
          {
            label: KPI.common.labels.weightedDistribution,
            value: null,
          },
          {
            label: KPI.common.labels.volumeSalesPerPtWdDistribution,
            value: null,
          }
        );
      }
      return kpis;
    },
    economics() {
      if (this.repository.currentMarketDataPool.isEmpty()) return [];
      const productMarketData = this.repository.currentMarketDataPool
        .get()
        .importedData.body.find(row => row['ean'] === this.product.get().ean);
      if (!productMarketData) return [];
      const importOptions = kpiImportOptions
        .filter(item => item.taxonomy === 'economics')
        .map(option => option.key);
      const economicsFromKpi = this.kpis.filter(kpi =>
        importOptions.includes(kpi.key)
      );
      const economics = this.repository.currentMarketDataPool
        .get()
        .importedData.header.filter(
          column =>
            !economicsFromKpi.map(kpi => kpi.key).includes(column.key) &&
            column.taxonomy === 'economics'
        )
        .map(column => {
          return (
            productMarketData && {
              label: column.label,
              value: productMarketData[column.key],
              key: column.key,
            }
          );
        });
      return economics
        .map(economic => {
          const containsKpisLabels = this.kpis
            .map(kpi => kpi.key)
            .includes(economic.key);
          if (!containsKpisLabels) {
            return economic;
          }
        })
        .filter(economic => economic);
    },
    records() {
      if (this.repository.currentMarketDataPool.isEmpty()) {
        return [];
      }

      const productMarketData = this.repository.currentMarketDataPool
        .get()
        .importedData.body.find(row => row['ean'] === this.product.get().ean);

      if (!productMarketData) {
        return [];
      }

      const records = this.repository.currentMarketDataPool
        .get()
        .importedData.header.filter(column => column.type === 'record')
        .map(column => {
          return (
            productMarketData && {
              label: column.label,
              value: productMarketData[column.key],
            }
          );
        });

      return records;
    },
    /**
     * @return {Option<StockInfo>}
     */
    stockInfo() {
      return PhysicalPlanogramService.computeStockInfo(
        this.repository.physicalPlanogram,
        this.bucketId
      );
    },
    /**
     * @return {Option<Bucket>}
     */
    bucket() {
      return this.stockInfo.map(info => info.bucket);
    },
    /**
     * @return {Option<ProductAsset>}
     */
    product() {
      return this.stockInfo.map(info => info.product);
    },
  },
  methods: {
    setCurrentMarketDataPoolId(id) {
      this.$loading.await(
        this.$trade.findMarketDataPoolById(id).then(response => {
          this.repository.currentMarketDataPool = Some(response.marketDataPool);
        })
      );
    },
    updateProduct() {
      this.$refs.modal
        .open(
          'Update product',
          'Update the product could change its size and facing, planogram could then become invalid, please control the result.',
          'alert'
        )
        .then(() => {
          this.$productBank
            .findProductById(this.bucket.get().productId, false)
            .then(response => {
              this.repository.addAdditionalProductInfo(response.product);
              const product = ProductService.translateProductFromProductBank(
                response.product
              );
              this.$unityManager.Dispatch('AddProducts', {
                products: [product],
              });
            })
            .catch(() =>
              this.$snotify.error(`Error updating product`, {
                timeout: 5000,
                showProgressBar: false,
              })
            );
        })
        .catch(() => console.log('Product updated failed'));
    },
  },
};
</script>

<style scoped>
.bucketInfo {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: hidden;
}

.bucketInfo > *:first-child {
  flex: 1 1 auto;
  overflow-y: auto;
}

.bucketInfo > *:nth-child(2) {
  flex: 1 0 auto;
}

.infoTab {
  overflow-x: hidden;
}

.productInfo {
  border-bottom: 1px solid var(--elevation-04);
  padding-bottom: var(--doubleMargin);
}

.bucketDescription {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: var(--doubleMargin);
}

::v-deep.accordion {
  border: 0;
}

::v-deep.accordion > .body {
  padding: 16px;
}

::v-deep.accordion > .header > .title > .icon {
  margin-left: -8px;
}

::v-deep.accordion > .header > .title > h2 {
  font-size: 16px;
}

::v-deep.accordion > .header {
  padding-left: 16px;
}
</style>
