<template>
	<div class="multipleFixtureInfo">
		<div class="info">
			<div class="section">
				<h1 primary-text>Multiple selection</h1>
				<ch-dl label-width="50%">
<!--					TODO Add open prop dialog -->
					<ch-dd label="Asset">{{ propName }}</ch-dd>
					<ch-dd label="Type">{{ assetType }}</ch-dd>
					<ch-dd v-if="propSize" label="Dimensions (cm)">
						<size :width="propSize.w" :height="propSize.h" :depth="propSize.d"/>
					</ch-dd>
				</ch-dl>
			</div>
			<div class="section">
				<h1 primary-text>Products</h1>
				<ch-dl label-width="50%">
					<ch-dd label="Products">{{ productsCount }}</ch-dd>
					<ch-dd label="Units">{{ unitsCount }}</ch-dd>
				</ch-dl>
			</div>
		</div>
		<div class="actions" y-spaced-2>
			<combine-elements-button :fixtures="fixtures"/>
			<isolate-elements-button :elements-ids="fixtures.map(f => f.id)"/>
			<remove-elements-button :elementsIds="fixtures.map(f => f.id)"/>
		</div>
	</div>
</template>

<script>
import RemoveElementsButton       from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/RemoveElementsButton';
import CombineElementsButton      from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/CombineElementsButton';
import {PhysicalPlanogramService} from '../../../services/PhysicalPlanogramService';
import Bucket                     from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/element/Bucket';
import {BucketService}            from '@/spaceallocation/physicalplanogrameditor/services/BucketService';
import Repository                 from '@/spaceallocation/physicalplanogrameditor/services/Repository';
import Size                       from '@/components/Size';
import {formatArchetypeType}      from '@/content/assets/Utils';
import IsolateElementsButton
								  from '@/spaceallocation/physicalplanogrameditor/ui/rightsidebar/common/IsolateElementsButton';

export default {
	name: 'MultipleFixturesInfo',
	components: {IsolateElementsButton, Size, CombineElementsButton, RemoveElementsButton},
	props: {
		fixturesIds: {
			type: Array,
			props: () => []
		},
		repository: Repository
	},
	computed: {
		/**
		 * @return {Fixture[]}
		 */
		fixtures() {
			return PhysicalPlanogramService.getAllFixtures(this.repository.physicalPlanogram)
				.filter(fixture => this.fixturesIds.includes(fixture.id));
		},
		/**
		 * @return {PropAsset[]}
		 */
		props() {
			//TODO This does not work with archetypes
			const allProps = this.fixtures
				.map(fixture => PhysicalPlanogramService.findFixturePropAsset(this.repository.physicalPlanogram, fixture).get());
			return allProps.filter((prop, i) => allProps.findIndex(otherProp => otherProp.id === prop.id) === i);
		},
		/**
		 * @return {string}
		 */
		propName() {
			if (this.props.length === 1)
				return this.props[0].name;
			return 'Multiple';
		},
		/**
		 * @return {string}
		 */
		assetType() {
			const assetTypes = [...new Set(this.props.map(prop => prop.archetypeType))];
			if (assetTypes.length === 1)
				return formatArchetypeType(assetTypes[0]);
			return 'Multiple';
		},
		/**
		 * @return {Volume}
		 */
		propSize() {
			if (this.props.length === 1)
				return this.props[0].size;
			return null;
		},
		/**
		 * @return {Bucket[]}
		 */
		buckets() {
			return this.fixtures
				.flatMap(PhysicalPlanogramService.flatElement)
				.filter(element => element.type === Bucket.staticType);
		},
		/**
		 * @return {number}
		 */
		productsCount() {
			return new Set(this.buckets.map(bucket => bucket.productId)).size;
		},
		/**
		 * @return {number}
		 */
		unitsCount() {
			return this.buckets.map(BucketService.getBucketUnits).reduce((acc, v) => acc + v, 0);
		}
	}
};
</script>

<style scoped>

.multipleFixtureInfo {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-content: stretch;
	overflow: auto;
}

.info {
	flex: 1 1 auto;
	border-bottom: 1px solid var(--elevation-04);
}

.actions {
	padding: var(--doubleMargin);
}

.section {
	padding: var(--doubleMargin);
}

.section:not(:last-child) {
	border-bottom: 1px solid var(--elevation-04);
}

</style>