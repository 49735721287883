<template>
    <div class="actions" y-spaced-2>

        <div class="changeView">
            <ch-button @click="changeView" size="narrow" type="primary">Change view</ch-button>
            <img v-if="(viewRotation._1 === 'Front')" class="viewIcon" src="../../../../../assets/FrontFace.png"/>
            <img v-if="(viewRotation._1 === 'Left')" class="viewIcon" src="../../../../../assets/LeftFace.png"/>
            <img v-if="(viewRotation._1 === 'Right')" class="viewIcon" src="../../../../../assets/RightFace.png"/>
            <img v-if="(viewRotation._1 === 'Back')" class="viewIcon" src="../../../../../assets/BackFace.png"/>
            <img v-if="(viewRotation._1 === 'Top')" class="viewIcon" src="../../../../../assets/TopFace.png"/>
            <img v-if="(viewRotation._1 === 'Bottom')" class="viewIcon" src="../../../../../assets/BottomFace.png"/>
            <span>{{viewRotation._1}}</span>
        </div>
        <div class="changeRotation">
            <ch-button @click="rotate" size="narrow" type="primary">Rotate</ch-button>
            <img class="viewIcon" :style="`transform: rotate(${viewRotation._2}deg)`"
                 src="../../../../../assets/RotateFace.png"/>
            <span>{{viewRotation._2}}°</span>
        </div>

      <div class="changePrice">
        <ch-field class="priceField">
			<div class="priceCurrency" slot="label">
				<span>Price</span><ch-select size="small narrow" :items="[{key: 'EUR', label: '€'}, {key: 'USD', label: '$'}]" @input="setCurrencyToBucket" :value="currentCurrency"/>
			</div>
          <ch-editor-input :placeholder="' - - '" type="number" @input="setPriceToBucket" :value="currentPriceValue"></ch-editor-input>
        </ch-field>
        <ch-field :label ="'Price per quantity'" class="priceField" >
			<ch-editor-input :placeholder="' - - '" @input="setPricePerQuantity" :value="currentPricePerQuantityValue" ></ch-editor-input>
        </ch-field>
      </div>
        <replace-products-button :repository="repository" :productIds="[bucket.productId]"/>
        <group-selection-actions :productIds="[bucket.productId]" :repository="repository" :selectionManager="selectionManager"/>
        <ch-button @click="deleteBucket" size="fill" type="error" justified>Remove products
            <ch-icon icon="delete"/>
        </ch-button>
        <ch-modal ref="modal"/>
    </div>
</template>

<script>
    import {ProductService}      from '../../../services/ProductService';
    import {BucketService}       from '../../../services/BucketService';
    import Tuple                 from '../../../../utils/Tuple';
    import {View}                from '../../../model/unit/View';
    import Repository            from '../../../services/Repository';
    import GroupSelectionActions from '../../trade/GroupSelectionActions';
    import SelectionManager      from '../../../managers/SelectionManager';
    import ReplaceProductsDialog from '../../dialog/ReplaceProductsDialog';
    import {PlanogramService}    from '../../../services/PlanogramService';
    import ReplaceProductsButton from '../product/ReplaceProductsButton';

    export default {
        name: 'BucketActions',
        components: {ReplaceProductsButton, ReplaceProductsDialog, GroupSelectionActions},
        props: {
            bucket: Object,
            cameraView: String,
            repository: Repository,
            selectionManager: SelectionManager
        },
        /**
         * @return {{availableViews: View[]}}
         */
        data() {
            return {
                availableViews: [View.Front, View.Left, View.Back, View.Right, View.Top, View.Bottom]
            };
        },
        computed: {
            /**
             * @return {Tuple<View, number>}
             */
            viewRotation() {
                if (this.cameraView === 'Front') return new Tuple(this.bucket.view, this.bucket.rotation);
                return BucketService.convertViewToTop(this.bucket.view, this.bucket.rotation);
            },
            currentPrice(){
                const bucketProduct = PlanogramService.findProductWithId(this.repository.planogram, this.bucket.productId);
                return bucketProduct.map(prd => prd.price).filter(price => price !== null);
            },
            currentCurrency(){
                return this.currentPrice.map(price => price.currency).getOrElse("EUR");
            },
            currentPriceValue(){
                return this.currentPrice.map(price => Math.round(price.value * 100) / 100).get();
            },
            currentPricePerQuantity(){
                const bucketProduct = PlanogramService.findProductWithId(this.repository.planogram, this.bucket.productId);
                return bucketProduct.map(prd => prd.info.pricePerQuantity).filter(pricePerQuantity => pricePerQuantity !== null)
            },
            currentPricePerQuantityValue(){
                return this.currentPricePerQuantity.filter(pricePerQuantity => pricePerQuantity).get();
            }
        },
        methods: {
            /**
             * @param {View} view
             * @return {View}
             */
            nextView(view) {
                return this.availableViews[(this.availableViews.indexOf(view) + 1) % this.availableViews.length];
            },
            changeView() {
                this.applyViewRotationChange(this.nextView, r => r);
            },
            rotate() {
                this.applyViewRotationChange(v => v, r => (r + 90) % 360);
            },
            /**
             * @param {function(view: View): View} viewModifier
             * @param {function(rotation: number): number} rotationModifier
             */
            applyViewRotationChange(viewModifier, rotationModifier) {
                const nextState = this.cameraView === 'Front' ?
                    new Tuple(viewModifier(this.bucket.view), rotationModifier(this.bucket.rotation)) :
                    (() => {
                        const topView = BucketService.convertViewToTop(this.bucket.view, this.bucket.rotation);
                        return BucketService.convertViewToFront(viewModifier(topView._1), rotationModifier(topView._2));
                    })();
                this.$unityManager.Dispatch('ChangeBucketViewRotation', {
                    bucketId: this.bucket.id,
                    view: nextState._1,
                    rotation: nextState._2
                });
            },
            deleteBucket() {
                this.$unityManager.Dispatch('DeleteItems', {
                    itemIds: [this.bucket.id]
                });
            },
            setPriceToBucket(value) {
				this.$unityManager.Dispatch('ChangeBucketPrice', {
					productId: this.bucket.productId,
					price: {
						value: value,
						currency: this.currentCurrency
					}
				});
			},
            setCurrencyToBucket(value) {
				this.$unityManager.Dispatch('ChangeBucketPrice', {
					productId: this.bucket.productId,
					price: {
						value: this.currentPriceValue,
						currency: value
					}
				});
			},
            setPricePerQuantity(value){
                this.$unityManager.Dispatch('ChangeBucketPricePerQuantity', {
                  productId: this.bucket.productId,
                  pricePerQuantity: value
                })
            }
        }
    };
</script>

<style scoped>

    .actions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        padding: var(--doubleMargin);
    }
    .changePrice{
      display: flex;
      justify-content: space-between;
      align-items: normal;
    }
    .priceField{
      width: 130px;
    }
    .changeView, .changeRotation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .changeView > *:last-child, .changeRotation > *:last-child {
        width: 50px;
    }

    .viewIcon {
        width: auto;
        height: 32px;
    }

	.priceCurrency {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

</style>
