<template>
  <div class="planogram-editor-wrapper">
    <ch-application-layout v-if="repository" v-show="!enableMarketData" editor>
      <left-side-bar
        ref="leftSideBar"
        slot="navigation"
        :repository="repository"
        :selectionManager="selectionManager"
        @market-data-clicked="toggleMarketData()"
      />
      <div slot="body" fill>
        <action-bar
          :repository="repository"
          :saveEnabled="saveEnabled"
          @save="saveEnabled = false"
        />
        <div ref="planogramEditorFrame" class="editor" fill></div>
        <ch-modal ref="modal" />
        <login-dialog ref="loginDialog" />
        <!--				<create-product-group-dialog ref="createGroup" type="primary" size="fullscreen-->
        <!--				 :repository="repository"/>-->
        <ch-loader v-if="loading" fullscreen />
      </div>
      <right-side-bar
        ref="rightSideBar"
        slot="sidebar"
        :repository="repository"
        :selectionManager="selectionManager"
        @deleteGroup="deleteGroup"
        @editGroup="editGroup"
        @change-dataset="handleDataSetAction()"
      />
      <!--			@deleteGroup="deleteGroup"-->
      <!--			@editGroup="editGroup"-->
    </ch-application-layout>
    <market-data-layout
      v-if="repository"
      v-show="enableMarketData"
      :repository="repository"
      @go-back="toggleMarketData()"
      @market-data-pool-added="addMarketDataPool($event)"
    />
    <ch-content-dialog
      ref="marketDataScoreCardTable"
      size="fullscreen"
      type="primary"
    >
      <template #title>
        <div class="marketDataScoreCardTableLabel">
          <label-tag label="BETA" size="small" />
          <h2>Setup market data</h2>
        </div>
      </template>
      <market-data-pool-list-table
        :currentSelectMarketDataPoolId="currentSelectMarketDataPoolId"
        :dataSets="dataSets"
        @handleDataSetSelection="handleDataSetSelection($event)"
      />
      <div class="data-sets-info-footer">
        <ch-button size="small" type="secondary" @click="closeModal">
          Cancel
        </ch-button>
        <ch-button size="fit" type="primary" @click="changeDataSet">
          Use dataset
        </ch-button>
      </div>
    </ch-content-dialog>
  </div>
</template>

<script>
/* Imports */
import Vue from 'vue';
import LeftSideBar from './leftsidebar/LeftSideBar';
import RightSideBar from './rightsidebar/RightSideBar';
import ActionBar from './ActionBar';
import SelectionManager from '../managers/SelectionManager';
import Repository from '../services/Repository';
import LoginDialog from '@/login/LoginDialog';

import MarketDataLayout from './marketdata/MarketDataLayout.vue';
import MarketDataPoolSummary from '@/plugins/studioapi/api/trade/model/MarketDataPoolSummary';
import {
  retrieveSelectedMarketDataPool,
  storeSelectedMarketDataPool,
} from '@/spaceallocation/planogrameditor/services/MarketDataPoolStorageService';

import { PlanogramService } from '@/spaceallocation/planogrameditor/services/PlanogramService';
import { toOption } from '@/spaceallocation/utils/Option';
import PhysicalMarketDataPoolAsset from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/PhysicalMarketDataPoolAsset';
import { mapGetters } from 'vuex';
import LabelTag from '@/spaceallocation/physicalplanogrameditor/ui/LabelTag';
import MarketDataPoolListTable from '@/spaceallocation/planogrameditor/ui/MarketDataPoolListTable';

/**/

export default {
  name: 'PlanogramEditor',
  components: {
    LoginDialog,
    ActionBar,
    LeftSideBar,
    RightSideBar,
    MarketDataLayout,
    MarketDataPoolListTable,
    LabelTag,
  },
  props: {
    planogramId: String,
  },
  data() {
    return {
      repository: null,
      saveEnabled: false,
      fixtureView: 'Front',
      loading: false,
      selectionManager: new SelectionManager(this.$unityManager),
      enableMarketData: false,
      currentSelectMarketDataPoolId: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    Vue.$studio
      .findPhysicalPlanogram(to.params.physicalPlanogramId)
      .then(response => {
        const marketDataPoolsId = response.planogram.library
          .filter(
            libraryItem =>
              libraryItem.type === PhysicalMarketDataPoolAsset.staticType
          )
          .map(mdp => mdp.marketDataPoolId);
        const defaultMarketDataPoolId = retrieveSelectedMarketDataPool(
          response.planogram.id,
          marketDataPoolsId[0]
        );

        Promise.all([
          Vue.$trade.listMarketDataPoolsSummary(marketDataPoolsId),
          Vue.$trade.findMarketDataPoolById(defaultMarketDataPoolId),
        ]).then(
          ([listMarketDataPoolsSummary, findMarketDataPoolByIdResponse]) => {
            next(vm => {
              vm.repository = new Repository(
                vm.$unityManager,
                response.planogram,
                response.categorization,
                response.props,
                listMarketDataPoolsSummary.marketDataPoolsSummaries,
                toOption(findMarketDataPoolByIdResponse.marketDataPool),
                toOption({
                  daily: toOption(0),
                  weekly: toOption(0),
                  monthly: toOption(0),
                })
              );
              vm.loadPlanogram();
            });
          }
        );
      })
      .catch(err => {
        next(err);
      });
  },
  beforeRouteLeave(to, from, next) {
    const proceed = () => this.$unityManager.UnloadScene().then(() => next());
    if (this.saveEnabled) {
      if (to.name === 'login') {
        this.$refs.loginDialog.open().catch(proceed);
      } else {
        this.$refs.modal
          .open(
            'Unsaved changes',
            'Do you really want to leave? You have unsaved changes!',
            'primary'
          )
          .then(proceed, () => next(false));
      }
    } else {
      proceed();
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mouseup', this.onMouseUp);
    this.$unityManager.planogramAPI.SubscribeToPlanogramChanged(
      this.planogramChanged.bind(this)
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.addEventListener('mouseup', this.onMouseUp);
    this.$unityManager.UnsubscribeAllEvents();
    window.onbeforeunload = null;
  },
  computed: {
    ...mapGetters({
      isData: 'planogramPerformance/isData',
    }),
    currentMarketDataPoolId() {
      if (this.repository && this.repository.currentMarketDataPool.nonEmpty()) {
        return this.repository.currentMarketDataPool.get().id;
      }
      return '';
    },
    dataSets() {
      return this.repository
        ? this.repository.listMarketDataPoolsSummary.map(summary => {
            return {
              id: summary.id,
              ...summary.info,
            };
          })
        : [];
    },
  },
  methods: {
    editGroup(groupId) {
      PlanogramService.findGroupWithId(
        this.repository.planogram,
        groupId
      ).forEach(group => {
        this.$refs.createGroup.open(group).then(
          g => this.onGroupEdited(g),
          () => console.info('Group editing cancelled')
        );
      });
    },
    /**
     * @param {string} groupId
     */
    deleteGroup(groupId) {
      PlanogramService.findGroupWithId(
        this.repository.planogram,
        groupId
      ).forEach(group =>
        this.$refs.modal
          .open(
            'Delete group',
            `Are you sure to delete ${group.name}?`,
            'error',
            'Delete',
            'Cancel'
          )
          .then(() => {
            this.$trade
              .deleteGroup(this.repository.planogram.id, groupId)
              .then(() => {
                this.$unityManager.Dispatch('DeleteFilter', { id: groupId });
                this.repository.planogram.productsGroups =
                  this.repository.planogram.productsGroups.filter(
                    group => group.id !== groupId
                  );
                this.selectionManager.currentGroupId = null;
              })
              .catch(code =>
                this.$snotify.error(
                  `Error deleting group ${groupId}. Code: ${code}`,
                  {
                    timeout: 5000,
                    showProgressBar: false,
                  }
                )
              );
          })
          .catch(() => {})
      );
    },
    toggleMarketData() {
      this.enableMarketData = !this.enableMarketData;
    },
    handleDataSetAction() {
      if (this.currentMarketDataPoolId) {
        this.openModal();
      } else {
        this.toggleMarketData();
      }
    },
    addMarketDataPool(marketDataPool) {
      this.repository.addMarketDataPool(
        new MarketDataPoolSummary(marketDataPool.id, marketDataPool.info)
      );
      this.repository.setCurrentMarketDataPool(marketDataPool);
      storeSelectedMarketDataPool(
        this.repository.physicalPlanogram.id,
        marketDataPool.id
      );
      this.toggleMarketData();
    },
    handleDataSetSelection(id) {
      this.currentSelectMarketDataPoolId = id;
    },
    openModal() {
      this.currentSelectMarketDataPoolId = this.currentMarketDataPoolId;
      this.$refs.marketDataScoreCardTable.open();
    },
    closeModal() {
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    changeDataSet() {
      this.setCurrentMarketDataPoolId(this.currentSelectMarketDataPoolId);
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    setCurrentMarketDataPoolId(id) {
      this.$loading.await(
        this.$trade.findMarketDataPoolById(id).then(response => {
          this.repository.setCurrentMarketDataPool(response.marketDataPool);
          storeSelectedMarketDataPool(
            this.repository.physicalPlanogram.id,
            response.marketDataPool.id
          );
        })
      );
    },
    loadPlanogram() {
      this.loading = true;
      window.onbeforeunload = null;
      this.$nextTick(() => {
        this.$unityManager
          .LoadPhysicalPlanogramEditor(this.$refs.planogramEditorFrame)
          .then(() => {
            this.$unityManager.canvas.tabIndex = 1;
            window.onbeforeunload = () =>
              this.saveEnabled ? 'Are you sure?' : null;
            this.$unityManager.planogramAPI.LoadPlanogram(
              this.repository.physicalPlanogram
            );
          });
      });
    },
    /**
     * @param {{physicalPlanogram: PhysicalPlanogram}} event
     */
    planogramChanged(event) {
      //The loading of the planogram is raised before the actual change of it
      //So it's necessary to change the loading state when it has been changed in order to prevent unexpected behaviour
      if (!this.loading) {
        this.saveEnabled = true;
      } else {
        this.loading = false;
      }
    },
    onResize() {
      document.getElementById('#canvas').style.width =
        this.$refs.planogramEditorFrame.offsetWidth + 'px';
      document.getElementById('#canvas').style.height =
        this.$refs.planogramEditorFrame.offsetHeight + 'px';
    },
    onMouseUp() {
      this.$unityManager.planogramAPI.EndDrag();
    },
  },
};
</script>

<style scoped>
.planogram-editor-wrapper {
  height: 100%;
}

.editor {
  outline: none;
}

.marketDataScoreCardTableLabel {
  display: flex;
  align-items: center;
}

.marketDataScoreCardTableLabel > h2 {
  margin-left: 12px;
}

.marketDataPoolList {
  max-height: 732px;
  padding: 32px 32px 64px 32px;
}

.data-sets-info-footer {
  width: calc(100% - 32px);
  height: 40px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 32px;
}

::v-deep .data-sets-info-footer > button {
  margin-left: 24px;
}
</style>
