<template>
	<accordion title="Dimensions in planogram" class="voice">
		<ch-dl label-width="50%">
			<ch-dd label="Height">
				<ch-number-input type="primary" :value="product.size.h / 10" :min="0.1"
								 @input="h => resize(product.size.w, h * 10, product.size.d)"/>
			</ch-dd>
			<ch-dd label="Width">
				<ch-number-input type="primary" :value="product.size.w / 10" :min="0.1"
								 @input="w => resize(w * 10, product.size.h, product.size.d)"/>
			</ch-dd>
			<ch-dd label="Depth">
				<ch-number-input type="primary" :value="product.size.d / 10" :min="0.1"
								 @input="d => resize(product.size.w, product.size.h, d * 10)"/>
			</ch-dd>
		</ch-dl>
	</accordion>
</template>

<script>
import ProductDetails from '../../../../../skucatalog/ProductDetails';
import Volume         from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/common/Volume';
import ProductAsset
					  from '@/plugins/studioapi/api/spaceallocation/physicalplanogram/model/asset/product/ProductAsset';
import Size           from '@/components/Size';
import Accordion               from '@/components/Accordion';

export default {
	name: 'ProductDimensions',
	components: {Accordion, Size, ProductDetails},
	props: {
		product: ProductAsset | Object
	},
	methods: {
		/**
		 * @param {number} w
		 * @param {number} h
		 * @param {number} d
		 */
		resize(w, h, d) {
			if (w === this.product.size.w && h === this.product.size.h && d === this.product.size.d) return;
			this.product.size = new Volume(w, h, d);
			this.$unityManager.planogramAPI.ReplaceAssets([this.product]);
		}
	}
};
</script>

<style scoped>

.voice {
	padding: var(--doubleMargin);
}

</style>
