<template>
    <div y-spaced-2>
        <physical-planogram-reference-info :controller="controller" :asset="asset"/>
        <h2 primary-text>Dimensions (cm)</h2>
        <ch-dl label-width="45%">
            <ch-dd label="Planogram">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <span>{{Math.round(asset.volume.height * 100) / 1000}}</span>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <span>{{Math.round(asset.volume.width * 100) / 1000}}</span>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <span>{{Math.round(asset.volume.depth * 100) / 1000}}</span>
                </div>
            </ch-dd>
            <ch-dd label="In layout">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
					<span>{{Math.round(asset.volume.height * 100) / 1000}}</span>
					<ch-icon icon="fas fa-arrows-alt-h"/>
					<span>{{Math.round(asset.volume.width * 100) / 1000}}</span>
					<ch-icon icon="fas fa-expand-alt"/>
					<span>{{Math.round(asset.volume.depth * 100) / 1000}}</span>
                </div>
            </ch-dd>
        </ch-dl>
        <transform :controller="controller" :element="element" :disabled="!!container"/>
        <delete-selection-button text="Remove element"/>
    </div>
</template>

<script>

    import LayoutController               from '../services/LayoutController';
    import Transform                      from './Transform';
    import Materializer                   from './Materializer';
    import RGBColor                       from '../model/common/color/RGBColor';
    import DeleteSelectionButton          from './DeleteSelectionButton';
    import PlanogramReferenceInfo         from './PlanogramReferenceInfo';
	import PhysicalPlanogramReferenceInfo
										  from '@/spaceallocation/layouteditor/rightsidebar/PhysicalPlanogramReferenceInfo';

    export default {
        name: 'PhysicalPlanogramInstanceInfo',
        components: {
			PhysicalPlanogramReferenceInfo,
			PlanogramReferenceInfo, DeleteSelectionButton, Materializer, Transform},
        props: {
            controller: LayoutController,
            element: Object
        },
        computed: {
            asset() {
                return this.controller.findAsset(this.element.assetId);
            },
            /**
             * @returns {IElement[]}
             */
            path() {
                return this.controller.extractBranch(this.element.id);
            },
            container() {
                return this.path.find(e => e.type === 'PlanogramsContainer');
            },
            color() {
                const color = this.asset.color;
                return new RGBColor(color.r, color.g, color.b).toString();
            }
        }
    };
</script>

<style scoped>

    .planogramName {
        word-break: break-all;
    }

</style>