<template>
  <div
    class="select"
    ref="select"
    :class="type"
    :width="width"
    :height="height"
    :tabindex="tabIndex"
  >
    <div
      ref="selectedItem"
      class="selectedItem"
      :disabled="disabled"
      :dropdownVisible="dropdownVisible"
      @click="showItems"
      :dropdownDirection="dropdownDirection"
      :style="colorsStyles"
    >
      <ch-icon
        v-if="searchable && dropdownVisible"
        icon="search"
        class="searchIcon"
      />
      <div v-if="!searchable" class="coloredPlaceholder">
        {{ selectedItemLabel || placeholder }}
      </div>
      <input
        v-else
        ref="input"
        :value="inputText"
        :disabled="disabled"
        @input="filterOptions"
        class="coloredPlaceholder"
        :placeholder="dropdownVisible ? inputPlaceholder : placeholder"
        size="1"
      />
      <ch-icon
        v-if="showClearableIcon"
        class="icon"
        icon="close"
        @click.stop="onIconClicked"
      ></ch-icon>
      <ch-icon
        v-else
        class="icon"
        id="dropdownToggleIcon"
        :icon="dropdownVisible ? 'arrow_drop_up' : 'arrow_drop_down'"
        @click.stop="onIconClicked"
      ></ch-icon>
    </div>
    <div
      ref="dropdown"
      class="dropdown"
      v-if="dropdownVisible"
      :style="dropdownPosition"
      :direction="dropdownDirection"
      @keydown.up.stop.prevent="$emit('dropdown-up', $event)"
      @keydown.down.stop.prevent="$emit('dropdown-down', $event)"
      @keydown.esc.stop.prevent="$emit('dropdown-esc', $event)"
    >
      <template v-if="filteredItems.length > 0">
        <slot name="dropdownHeader" :list="filteredItems" />
        <ch-select-option
          v-for="(item, i) in filteredItems"
          :showCheckbox="multiple"
          ref="options"
          :tabindex="i"
          :item="item"
          :key="item.key"
          @click="selectItem(item)"
          :disabled="item.disabled"
          :selected="isItemSelected(item)"
          @keydown.space.native.prevent="$emit('item-spacedown', item)"
        >
          <slot :item="item"></slot>
        </ch-select-option>
      </template>
      <div
        class="emptyContent"
        v-if="filteredItems.length === 0"
        :style="colorsStyles"
      >
        {{ notFoundText }}
      </div>
    </div>
  </div>
</template>

<script>
import ChSelectOption from './ChSelectOption';

export default {
  name: 'ResearchChSelect',
  components: { ChSelectOption },
  props: {
    items: Array,
    type: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'primary', 'overlay'].includes(type);
      },
    },
    size: {
      default: 'large normal',
      validator(size) {
        const values = size.split(' ');
        return values.every(v =>
          ['fill', 'large', 'small', 'normal', 'narrow'].includes(v)
        );
      },
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    placeholderColor: String,
    inputPlaceholder: {
      type: String,
      default: 'Search...',
    },
    disabled: Boolean,
    clearable: Boolean,
    searchable: Boolean,
    multiple: Boolean,
    filterMethod: {
      type: Function,
      default: (item, query) => new RegExp(query, 'i').test(item.label),
    },
    value: {},
    notFoundText: {
      default: 'No match found',
      type: String,
    },
    notFoundTextColor: String,
    tabIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      dropdownVisible: false,
      query: null,
      inputText: '',
      dropdownPosition: null,
      dropdownDirection: null,
      focusPointer: null,
    };
  },
  computed: {
    colorsStyles() {
      const wrapIfCssVar = possibleVar =>
        String(possibleVar).startsWith('--')
          ? `var(${possibleVar})`
          : possibleVar;
      return {
        ...(((this.placeholderColor && !this.dropdownVisible) ||
          !this.searchable) && {
          '--placeholder-color': wrapIfCssVar(this.placeholderColor),
        }),
        ...(this.notFoundTextColor && {
          '--not-found-color': wrapIfCssVar(this.notFoundTextColor),
        }),
      };
    },
    itemsType() {
      if (this.items.length > 0) return typeof this.items[0];
      return 'undefined';
    },
    currentValue() {
      return this.value;
    },
    sizeTokens() {
      return this.size.split(' ');
    },
    width() {
      return (
        ['large', 'small', 'fill'].find(w => this.sizeTokens.includes(w)) ||
        'large'
      );
    },
    height() {
      return (
        ['normal', 'narrow'].find(h => this.sizeTokens.includes(h)) || 'normal'
      );
    },
    isEmpty() {
      return this.value === null || (this.multiple && this.value.length === 0);
    },
    showClearableIcon() {
      return this.clearable && !this.isEmpty && !this.disabled;
    },
    internalItems() {
      if (this.itemsType !== 'object') {
        return this.items.map(i => ({ key: i, label: i }));
      } else return this.items;
    },
    filteredItems() {
      if (this.query)
        return this.internalItems
          .filter(i => this.filterMethod(i, this.query))
          .sort((i1, i2) => (i1.label > i2.label ? 1 : -1));
      else return this.internalItems;
    },
    selectedItemLabel() {
      if (this.multiple && this.value) {
        const selectedItems = this.internalItems.filter(i =>
          this.value.includes(i.key)
        );
        if (selectedItems.length === this.internalItems.length) return 'All';
        else if (selectedItems.length > 1) return 'Multiple';
        else if (selectedItems.length === 1) return selectedItems[0].label;
      } else if (!this.multiple) {
        const selectedItem = this.internalItems.find(i => this.value === i.key);
        if (selectedItem) return selectedItem.label;
      }
      return null;
    },
  },
  watch: {
    focusPointer() {
      if (this.focusPointer) {
        [...this.$refs.options].forEach(el => {
          el.$el.blur();
          if (el.item.key === this.focusPointer) {
            el.$el.focus();
            this.focusPointer = el.item.key;
            if (!this.isInViewport(el.$el)) {
              el.$el.scrollIntoView();
            }
          }
        });
      }
    },
    dropdownVisible() {
      if (!this.dropdownVisible) {
        this.query = '';
        this.inputText = this.selectedItemLabel;
      } else {
        const {
          top,
          left,
          width,
          height,
        } = this.$refs.selectedItem.getBoundingClientRect();
        if (top < window.innerHeight / 2) {
          this.dropdownDirection = 'bottom';
          this.dropdownPosition = `top: ${top + height}px;`;
        } else {
          this.dropdownDirection = 'top';
          this.dropdownPosition = `bottom: ${window.innerHeight - top}px;`;
        }
        this.dropdownPosition += `left: ${left}px; width: ${width}px;`;
        let element = this.$el;
        while (element) {
          element.addEventListener('scroll', this.hideDropDownOnScroll);
          element = element.parentElement;
        }
      }
    },
    selectedItemLabel() {
      this.inputText = this.selectedItemLabel;
    },
  },
  created() {
    document.addEventListener('click', this.onClick, true);
    this.inputText = this.selectedItemLabel;
  },
  destroyed() {
    document.removeEventListener('click', this.onClick);
  },
  methods: {
    focusOption(currentPointer) {
      const shift = 1;
      const index = currentPointer
        ? this.filteredItems.findIndex(i => i.key === currentPointer)
        : 0;
      const getValue = (index, initIndex) =>
        !this.filteredItems.length
          ? null
          : !currentPointer
          ? this.filteredItems[initIndex].key
          : !!this.filteredItems[index]
          ? this.filteredItems[index].key
          : this.filteredItems[initIndex].key;
      const next = getValue(index + 1, 0);
      const prev = getValue(index - 1, this.filteredItems.length - 1);
      return { next, prev };
    },

    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    hideDropDownOnScroll() {
      this.dropdownVisible = false;
      let element = this.$el;
      while (element) {
        element.removeEventListener('scroll', this.hideDropDownOnScroll);
        element = element.parentElement;
      }
    },
    onClick(event) {
      if (this.dropdownVisible) {
        if (event.path[1] === document.getElementById('dropdownToggleIcon')) {
          this.dropdownVisible = false;
          event.stopPropagation();
        } else if (!event.path.includes(this.$refs.dropdown)) {
          if (
            event.path.includes(this.$refs.selectedItem) &&
            !this.searchable
          ) {
            this.dropdownVisible = false;
            event.stopPropagation();
          } else if (
            event.path.includes(this.$refs.selectedItem) &&
            this.searchable
          )
            event.stopPropagation();
          else this.dropdownVisible = false;
        }
      }
    },
    showItems(event) {
      if (!this.disabled && !this.dropdownVisible) {
        event.stopPropagation();
        this.dropdownVisible = true;
        if (this.searchable) this.inputText = null;
      }
    },
    isItemSelected(item) {
      if (this.multiple) return this.value.includes(item.key);
      else return this.value === item.key;
    },
    selectItem(item) {
      if (!this.isItemSelected(item)) {
        if (this.multiple)
          this.$emit('input', [item.key, ...(this.value || [])]);
        else this.$emit('input', item.key);
      } else if (this.multiple)
        this.$emit(
          'input',
          this.value.filter(k => k !== item.key)
        );
      if (!this.multiple) this.dropdownVisible = false;
    },
    onIconClicked() {
      if (this.showClearableIcon) this.resetSelect();
      else if (!this.disabled) this.dropdownVisible = !this.dropdownVisible;
    },
    resetSelect() {
      if (this.clearable) {
        this.$emit('input', this.multiple ? [] : null);
        this.query = '';
        this.inputText = this.selectedItemLabel;
        if (!this.multiple) this.dropdownVisible = false;
      }
    },
    filterOptions(event) {
      if (this.searchable) {
        this.inputText = this.query = event.target.value;
      }
    },
  },
};
</script>

<style scoped>
.select {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
}

.selectedItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--padding);
  border-radius: 4px;
  height: var(--height);
  box-sizing: border-box;
}

.selectedItem > *:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedItem[dropdownVisible][dropdownDirection='bottom'] {
  border-radius: 4px 4px 0 0;
}

.selectedItem[dropdownVisible][dropdownDirection='top'] {
  border-radius: 0 0 4px 4px;
}

.selectedItem > input {
  flex: 1 1 auto;
  width: auto;
  height: 100%;
  outline: none;
  background-color: transparent;
}

.selectedItem > .icon {
  flex: 0 0 auto;
}

.selectedItem[disabled] > .icon {
  cursor: default;
}

.dropdown {
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  position: fixed;
  max-height: var(--max-height);
  overflow-y: auto;
}

.dropdown > * {
  height: var(--height);
  padding: 0 var(--padding);
}

.dropdown > *:not([disabled]) {
  cursor: pointer;
}

.dropdown[direction='bottom'] {
  border-radius: 0 0 4px 4px;
  box-shadow: 0 7px 6px rgba(0, 0, 0, 0.66);
}

.dropdown[direction='top'] {
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -7px 6px rgba(0, 0, 0, 0.66);
}

.emptyContent {
  height: var(--height);
  padding: 0 var(--padding);
  display: flex;
  align-items: center;
}

/* SIZE */

.select[width='large'] {
  --width: 160px;
}

.select[width='small'] {
  --width: 80px;
}

.select[width='fill'] {
  --width: 100%;
}

.select[height='narrow'] {
  --height: 24px;
  --max-height: 240px;
  --padding: var(--halfMargin);
}

.select[height='normal'] {
  --height: 32px;
  --max-height: 320px;
  --padding: var(--singleMargin);
}

.select {
  width: var(--width);
  height: var(--height);
}

/* COLORS */

.default {
  --border: var(--default-border);
  --background-color: var(--default);
  --hover-background-color: var(--default-hover);
  --color: var(--on-default);
  --hover-color: var(--on-default-hover);
}

.overlay {
  --border: var(--overlay-border);
  --background-color: var(--overlay);
  --hover-background-color: var(--overlay-hover);
  --color: var(--on-overlay);
  --hover-color: var(--on-overlay-hover);
}

.selectedItem:not([dropdownVisible]) {
  background-color: var(--background-color);
  color: var(--color);
}

.selectedItem[dropdownVisible] {
  background-color: var(--elevation-06);
  color: var(--on-elevation-06);
}

.emptyContent {
  background-color: var(--elevation-06);
  color: var(--not-found-color, --on-elevation-06);
}

.selectedItem:not([dropdownVisible]) {
  border: var(--border);
}

.selectedItem[dropdownVisible] {
  border-bottom: 0;
}

.selectedItem:hover:not([disabled]):not([dropdownVisible]) {
  background-color: var(--hover-background-color);
  color: var(--hover-color);
}

.selectedItem[disabled],
.selectedItem[disabled] input,
.selectedItem[disabled] input::placeholder {
  background-color: var(--disabled);
  color: var(--on-disabled);
}

.searchIcon {
  padding-right: var(--singleMargin);
}
.coloredPlaceholder {
  color: var(--placeholder-color);
}
.coloredPlaceholder::placeholder {
  color: var(--placeholder-color, rgba(117, 117, 117));
}
</style>
